import { useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  matchPath,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import ContractModal from '../../components/Contracts/ContractModal';
import {
  MBox,
  MButton,
  MCustomLinkTab,
  MPageContainer,
  MPageHeader,
  MStack,
  MTabList,
  MTabs,
} from '../../components/Monetize';
import { OpportunityModal } from '../../components/Opportunities/OpportunityModal';
import { ROUTES } from '../../constants';
import { ACLCheck } from '../../services/acl/ACLCheck';
import { useDocumentHead } from '../../services/documentHead';
import { ContractListMain } from '../Sales/ContractListMain';
import OpportunityListMain from '../Sales/OpportunityListMain';
import QuoteListMain from './Quote/QuoteListMain';

const tabRoutes = [
  { title: 'Opportunities', route: ROUTES.SALES_OPPORTUNITIES },
  { title: 'Quotes', route: ROUTES.SALES_QUOTES },
  { title: 'Contracts', route: ROUTES.SALES_CONTRACTS },
];

export const SalesLanding = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setDocTitle } = useDocumentHead();
  const [routeActiveIndex, setRouteActiveIndex] = useState<number>(0);

  const goToCreateQuotes = () => {
    navigate(ROUTES.QUOTE_NEW);
  };

  useEffect(() => {
    setDocTitle('Dashboard');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const defaultIndex = tabRoutes.findIndex(({ route }) => {
      return matchPath(`${ROUTES.SALES_LANDING}/${route}`, pathname);
    });
    setRouteActiveIndex(defaultIndex === -1 ? 0 : defaultIndex);
  }, [pathname]);

  return (
    <MPageContainer enableAccountSearch data-testid="sales-landing">
      <MPageHeader title="Sales">
        <MBox>
          <MStack spacing={2} direction="row">
            <ACLCheck acls={[['sales', 'create']]}>
              <MButton
                variant="primary"
                onClick={goToCreateQuotes}
                data-testid="new-quote-btn"
              >
                New
              </MButton>
            </ACLCheck>
          </MStack>
        </MBox>
      </MPageHeader>
      <MBox w="100%" mt="-1">
        <MTabs mb="6" size="sm" index={routeActiveIndex}>
          <MTabList alignItems="flex-end">
            {tabRoutes.map((tabRoute, index) => (
              <MCustomLinkTab to={tabRoute.route} key={index}>
                {tabRoute.title}
              </MCustomLinkTab>
            ))}
          </MTabList>
        </MTabs>
      </MBox>
      <MBox width="100%" flex="1" minH="0">
        <Routes>
          <Route
            path="/"
            element={<Navigate to={ROUTES.SALES_QUOTES} replace />}
          />
          <Route
            path={ROUTES.SALES_OPPORTUNITIES}
            element={<OpportunityListMain />}
          >
            <Route
              path={ROUTES.OPPORTUNITY_EDIT}
              element={
                <OpportunityModal
                  isOpen
                  onClose={(skipNavigate) => {
                    !skipNavigate && navigate(ROUTES.SALES_OPPORTUNITIES);
                  }}
                />
              }
            />
          </Route>
          <Route path={ROUTES.SALES_QUOTES} element={<QuoteListMain />} />
          <Route
            path={`${ROUTES.SALES_CONTRACTS}/*`}
            element={<ContractListMain />}
          >
            <Route
              path={ROUTES.CONTRACT_EDIT}
              element={
                <ContractModal
                  isOpen
                  onClose={() => {
                    navigate(ROUTES.SALES_CONTRACTS);
                  }}
                />
              }
            />
          </Route>
        </Routes>
      </MBox>
    </MPageContainer>
  );
};
