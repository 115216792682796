import {
  ContactTypes,
  ContactWithTypes,
  IContactRespSchema,
  IQuoteRespSchema,
} from '../../../../../types';

// called with contacts structure received on quote obj from API
export const calcEsignContactCounts = (quote: IQuoteRespSchema) => {
  const { contacts } = quote;
  const { esign: esignContacts } = contacts;
  let externalEsignContactCount = 0; // limiting these counts to 0 or 1 should be enforced by the API rather than needing to check it here
  let internalEsignContactCount = 0;
  esignContacts.forEach((contact) => {
    if (contact.internal) {
      internalEsignContactCount += 1;
    } else {
      externalEsignContactCount += 1;
    }
  });
  const hasOneInternalEsignContact = internalEsignContactCount === 1;
  const hasOneExternalEsignContact = externalEsignContactCount === 1;
  return { hasOneInternalEsignContact, hasOneExternalEsignContact };
};

export const getStandardContactsValidity = (
  quote: IQuoteRespSchema | undefined | null,
): { isValid: boolean; errorMsgs: string[] } => {
  const errorMsgs: string[] = [];
  if (!quote?.contacts.primary?.id) {
    errorMsgs.push('A primary contact is required');
  }
  if (!quote?.contacts.billing?.id) {
    errorMsgs.push('A billing contact is required');
  }
  return {
    isValid: !errorMsgs.length,
    errorMsgs,
  };
};

export const getEsignContactValidity = (
  quote: IQuoteRespSchema | undefined | null,
): { isValid: boolean; errorMsgs: string[] } => {
  if (!quote) {
    return { isValid: false, errorMsgs: [] };
  }
  if (!quote.requiresEsign) {
    return { isValid: true, errorMsgs: [] };
  } else {
    const { hasOneInternalEsignContact, hasOneExternalEsignContact } =
      calcEsignContactCounts(quote);
    const errorMsgs: string[] = [];

    if (!hasOneInternalEsignContact) {
      errorMsgs.push(
        'To use eSign, one internal contact must be configured as a signer',
      );
    }
    if (!hasOneExternalEsignContact) {
      errorMsgs.push(
        'To use eSign, one external contact must be configured as a signer',
      );
    }

    return {
      isValid: hasOneExternalEsignContact && hasOneInternalEsignContact,
      errorMsgs,
    };
  }
};

// called from QuoteContactsDrawer with derived object structures
export function calculateValidity({
  externalContacts,
  internalContacts,
  requiresEsign,
}: {
  externalContacts: ContactWithTypes[];
  internalContacts: ContactWithTypes[];
  requiresEsign: boolean;
}) {
  const hasPrimarySelected = externalContacts.some(({ primary }) => primary);
  const hasBillingSelected = externalContacts.some(({ billing }) => billing);
  const numEsign = [...externalContacts, ...internalContacts].reduce(
    (acc, { esign }) => (esign ? acc + 1 : acc),
    0,
  );
  const hasEsignConfiguredCorrectly = requiresEsign ? numEsign === 2 : true;
  const everyContactHasSelection = [
    ...externalContacts,
    ...internalContacts,
  ].every(({ billing, esign, primary }) => billing || esign || primary);
  return {
    hasPrimarySelected,
    hasBillingSelected,
    hasEsignConfiguredCorrectly,
    everyContactHasSelection,
  };
}

export function updateContactsMap(
  contactsMap: Record<string, ContactWithTypes>,
  contact: IContactRespSchema,
  type: ContactTypes,
) {
  if (contactsMap[contact.id!]) {
    contactsMap[contact.id!][type] = true;
  } else {
    contactsMap[contact.id!] = {
      contact,
      primary: false,
      billing: false,
      esign: false,
      [type]: true,
    };
  }
}
