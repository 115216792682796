import { Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { addMonths } from 'date-fns/addMonths';

import { formatCurrency, toDateShort } from '@monetize/utils/core';
import { FC } from 'react';
import { PRODUCT_TYPE_DISPLAY_SHORT } from '../../constants/products';
import {
  IProduct,
  IRateReqSchemaUI,
  PriceDisplayEnum,
  ProductTypeEnum,
  RateBillingFrequencyEnum,
  RateUsageBillingFrequencyEnum,
} from '../../types';
import { getPricePerUnitWithRateFrequencyShortCode } from '../../utils/quotes';
import { MBox, MTable, MText } from '../Monetize';

interface RateSettingsPopoverTableProps {
  quoteOfferingName: string;
  products: Pick<IProduct, 'productType' | 'name' | 'id'>[];
  rate: IRateReqSchemaUI;
  priceDisplay: PriceDisplayEnum;
}

export const RateSettingsPopoverTable: FC<RateSettingsPopoverTableProps> = ({
  products,
  rate,
  priceDisplay,
  quoteOfferingName,
}: RateSettingsPopoverTableProps) => {
  const dateStr = `${toDateShort(new Date())} - ${toDateShort(
    addMonths(new Date(), 12),
  )}`;

  const isProductLevel = priceDisplay === PriceDisplayEnum.PRODUCT;
  if (!rate) {
    return null;
  }

  const prices = products.slice(0, 2).map((product) => {
    const productPrices =
      product.id && rate.products?.[product.id]?.prices
        ? rate.products?.[product.id]?.prices
        : [];
    const price = productPrices.length > 0 ? productPrices[0].amount : 0;
    const frequency = rate?.billingFrequency;

    return {
      name: product.name,
      quantity: 1,
      productTypeDesc: PRODUCT_TYPE_DISPLAY_SHORT[product.productType],
      ppu: getPricePerUnitWithRateFrequencyShortCode({
        priceStr: formatCurrency(price, { currency: rate.currency }),
        offeringRate: {
          ...rate,
          billingFrequency:
            rate.billingFrequency || RateBillingFrequencyEnum.MONTHLY,
          usageBillingFrequency:
            rate.usageBillingFrequency || RateUsageBillingFrequencyEnum.MONTHLY,
          //  'currency'
          prices: rate.prices || ([] as any[]),
        },
        productType: product.productType,
        isCustomPrice: false,
      }),
      amountStr:
        product.productType !== ProductTypeEnum.USAGE
          ? formatCurrency(price, { currency: rate.currency })
          : '',
      amount: product.productType !== ProductTypeEnum.USAGE ? price : 0,
      description: rate.description,
    };
  });
  const total = prices.reduce((prev, curr) => (+curr.amount || 0) + prev, 0);
  const totalStr = formatCurrency(total, { currency: rate.currency });

  return (
    <MTable mt={2} variant="preview">
      <Thead>
        <Tr>
          <Th>Offering</Th>
          <Th textAlign="center">Date</Th>
          <Th textAlign="center" minW="50px">
            Quantity
          </Th>
          <Th textAlign="center" minW="50px">
            PPU
          </Th>
          <Th textAlign="right" minW="50px">
            Amount
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr background="tBlue.hover">
          <Td maxW="120px">
            <MText isTruncated fontSize="9px" fontWeight="bold">
              {quoteOfferingName}
            </MText>
          </Td>
          <Td textAlign="center" fontSize="9px" minW="120px">
            {dateStr}
          </Td>
          <Td textAlign="center"></Td>
          <Td textAlign="center"></Td>
          <Td textAlign="right"></Td>
        </Tr>
        <Tr>
          <Td fontWeight="bold">{rate?.name}</Td>
          <Td textAlign="center">
            <MBox fontSize="9px">
              {dateStr}
              <MText fontSize="9px" lineHeight="1">
                12 Months
              </MText>
            </MBox>
          </Td>
          <Td textAlign="center"></Td>
          <Td textAlign="center"></Td>
          <Td textAlign="right">{isProductLevel ? '' : totalStr}</Td>
        </Tr>
        {prices.map(
          (
            { name, quantity, ppu, productTypeDesc, amountStr, description },
            index,
          ) => (
            <Tr key={index}>
              <Td maxW="120px" paddingLeft="10px !important">
                <MText fontSize="8px" lineHeight="0.8rem" noOfLines={1}>
                  <b>{name}</b> {productTypeDesc ? `(${productTypeDesc})` : ''}
                </MText>
                <MText fontSize="8px" lineHeight="0.8rem" noOfLines={1}>
                  {description}
                </MText>
              </Td>
              <Td textAlign="center"></Td>
              <Td textAlign="center">{quantity}</Td>
              <Td textAlign="center">{isProductLevel ? ppu : ''}</Td>
              <Td textAlign="right">{isProductLevel ? amountStr : ''}</Td>
            </Tr>
          ),
        )}
      </Tbody>
    </MTable>
  );
};
