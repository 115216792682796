import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useGetAccountById } from '../../../api/accountsService';
import { handleApiErrorToast } from '../../../api/axios';
import {
  useCreateEntity,
  useGetById,
  useUpdateEntity,
} from '../../../api/queryUtils';
import {
  useGetInvoiceSettings,
  useGetPaymentCollectAutoConfig,
} from '../../../api/settingsService';
import {
  MBox,
  MButton,
  MCenterModal,
  MStack,
} from '../../../components/Monetize';
import { getAccountBillGroupsRoute } from '../../../constants/routes';
import { useNetTerms } from '../../../hooks';
import { useDocumentHead } from '../../../services/documentHead';
import { logger } from '../../../services/logger';
import Sentry from '../../../services/sentry';
import {
  BillGroupFormSchema,
  IBillGroupReq,
  IBillGroupReqUI,
  IBillGroupResp,
  StrKeyObj,
} from '../../../types';
import { nullifyEmptyStrings } from '../../../utils/misc';
import { BillGroupForm } from './BillGroupForm';
import { BillGroupModalTitle } from './BillGroupModalTitle';
import {
  convertBillGroupReqUIToBillGroupReq,
  convertBillGroupRespToReqUI,
} from './utils';

interface BillGroupFormModalProps {
  isOpen: boolean;
  accountId?: string | null;
  billGroupId?: string;
  onClose: () => void;
  onNewBillGroup?: (billGroupId?: string) => void;
  isReadOnly?: boolean;
}

export const BillGroupFormModal = React.forwardRef(
  (
    {
      isOpen,
      billGroupId,
      accountId,
      onClose,
      onNewBillGroup,
      isReadOnly,
    }: BillGroupFormModalProps,
    ref,
  ) => {
    const { data: accountDetails } = useGetAccountById(accountId as string, {
      enabled: !!accountId,
    });

    const { isLoading, data: billGroup } = useGetById<IBillGroupResp>(
      'billGroups',
      billGroupId!,
      {
        enabled: !!billGroupId,
        meta: {
          showErrorToast: true,
          navigateTo: accountId && getAccountBillGroupsRoute(accountId),
        },
      },
    );

    const {
      activeNetTerms,
      defaultNetTerm,
      isLoading: netTermsLoading,
    } = useNetTerms(billGroup?.netTerms);

    const convertedBillGroup = convertBillGroupRespToReqUI(
      billGroup,
      defaultNetTerm,
    );

    const { data: paymentCollectAutoConfigData } =
      useGetPaymentCollectAutoConfig({
        enabled: !billGroupId,
      });

    const { data: invoiceSettingsData } = useGetInvoiceSettings({
      enabled: !billGroupId,
    });

    const methods = useForm<IBillGroupReqUI>({
      resolver: zodResolver(BillGroupFormSchema),
      mode: 'onChange',
      defaultValues: convertedBillGroup,
      values: {
        ...convertedBillGroup,
        collectInvoiceBalanceAutomatically:
          !billGroupId && paymentCollectAutoConfigData
            ? paymentCollectAutoConfigData.collectAutomatically
            : convertedBillGroup.collectInvoiceBalanceAutomatically,
        autoEmailInvoice:
          !billGroupId && invoiceSettingsData?.invoiceEmailingConfig.automatic
            ? invoiceSettingsData?.invoiceEmailingConfig.automatic
            : convertedBillGroup.autoEmailInvoice,
        invoiceCreation:
          !billGroupId && invoiceSettingsData?.invoiceAutomation.invoiceCreation
            ? invoiceSettingsData?.invoiceAutomation.invoiceCreation
            : convertedBillGroup.invoiceCreation,
        invoiceDelay:
          !billGroupId && invoiceSettingsData?.invoiceAutomation.invoiceDelay
            ? invoiceSettingsData?.invoiceAutomation.invoiceDelay
            : convertedBillGroup.invoiceDelay,
        firstInvoiceCreationStrategy: invoiceSettingsData?.invoiceAutomation
          .firstInvoiceCreationStrategy
          ? invoiceSettingsData?.invoiceAutomation.firstInvoiceCreationStrategy
          : convertedBillGroup.firstInvoiceCreationStrategy,
        legalEntityId:
          !billGroupId && accountDetails?.defaultLegalEntityId
            ? accountDetails?.defaultLegalEntityId
            : convertedBillGroup.legalEntityId,
        currency:
          !billGroupId && accountDetails?.defaultCurrency
            ? accountDetails.defaultCurrency
            : convertedBillGroup.currency,
      },
      resetOptions: {
        keepDirtyValues: true,
        keepErrors: true,
      },
    });

    const {
      handleSubmit,
      formState: { dirtyFields, isValid },
    } = methods;

    /** The built-in isDirty of form state is not working properly
     * for that reason using this little hack
     */
    const isDirty = Object.keys(dirtyFields).length > 0;

    const { mutateAsync: doCreateBillGroup } = useCreateEntity<
      IBillGroupResp,
      IBillGroupReq
    >('billGroups', {
      endpointArgs: { accountId },
    });

    const { mutateAsync: doUpdateBillGroup } = useUpdateEntity<
      IBillGroupResp,
      IBillGroupReq
    >('billGroups', {
      endpointArgs: { accountId },
    });

    const [saveLoading, setIsSaveLoading] = useState<boolean>(false);
    const { setDocTitle } = useDocumentHead();

    useEffect(() => {
      setDocTitle(
        billGroup
          ? `${isReadOnly ? 'View' : 'Edit'} Bill Group`
          : 'New Bill Group',
      );
    }, [billGroup, isReadOnly, setDocTitle]);

    const onSubmit = async (data: IBillGroupReqUI) => {
      const parsed = BillGroupFormSchema.safeParse(data);
      if (!parsed.success) {
        Sentry.captureException(parsed.error.issues, {
          tags: {
            type: 'BILL_GROUP_FORM',
          },
        });
      }

      const payload = parsed.success
        ? convertBillGroupReqUIToBillGroupReq(parsed.data as IBillGroupReqUI)
        : convertBillGroupReqUIToBillGroupReq(data);
      const finalPayloadData = nullifyEmptyStrings(payload);
      try {
        setIsSaveLoading(true);
        if (billGroupId) {
          await doUpdateBillGroup({
            id: billGroupId,
            payload: finalPayloadData,
          });
        } else {
          const newBillGroup = await doCreateBillGroup(finalPayloadData);
          onNewBillGroup && onNewBillGroup(newBillGroup.id);
        }
        onClose();
      } catch (error) {
        handleApiErrorToast(error);
      } finally {
        setIsSaveLoading(false);
      }
    };

    const onError = (errs: any, event: any) => {
      logger.warn(`ERRORS:`, errs);
    };

    return (
      <MCenterModal
        isOpen={isOpen}
        onClose={onClose}
        size="2xl"
        renderModalTitleActions={() => (
          <BillGroupModalTitle
            isLoading={isLoading}
            isReadOnly={isReadOnly}
            accountId={accountId}
            accountName={accountDetails?.accountName}
            billGroup={billGroup}
          />
        )}
        modalHeaderProps={{ fontSize: 'lg', display: 'flex' }}
        renderFooter={
          isLoading || isReadOnly
            ? null
            : () => (
                <MStack
                  spacing={4}
                  direction="row"
                  align="center"
                  justify="right"
                  flex={1}
                >
                  <MButton onClick={onClose} variant="cancel" minW="auto">
                    Cancel
                  </MButton>
                  <MButton
                    data-testid="bill-group-form-submit"
                    form="bill-group-form"
                    variant="primary"
                    isLoading={isLoading || saveLoading}
                    onClick={handleSubmit(onSubmit, onError)}
                    isDisabled={saveLoading || !isValid || !isDirty}
                    type="submit"
                    minW="auto"
                  >
                    Save
                  </MButton>
                </MStack>
              )
        }
        modalContentProps={
          { 'data-testid': 'billgroup-modal-form' } as StrKeyObj
        }
      >
        <MBox>
          <FormProvider {...methods}>
            <BillGroupForm
              activeNetTerms={activeNetTerms}
              billGroupId={billGroupId}
              accountId={accountId!}
              isSaveLoading={saveLoading}
              loading={isLoading || netTermsLoading}
              isReadOnly={isReadOnly}
              errorStatus={billGroup?.errorStatus}
              handleSave={handleSubmit(onSubmit, onError)}
              accountDetails={accountDetails}
            />
          </FormProvider>
        </MBox>
      </MCenterModal>
    );
  },
);
