import { parseISO } from 'date-fns/parseISO';
import isString from 'lodash/isString';
import { z } from 'zod';
import { AddressRequiredSchema, AddressSchema } from './addressTypes';
import { Maybe } from './miscTypes';
import {
  IPaymentMethodContactSchema,
  PaymentMethodContactSchema,
} from './paymentMethodTypes';

export enum RecordShareEntity {
  QUOTE = 'QUOTE',
}

export enum RecordShareEngagementAction {
  VIEWED_RECORD = 'VIEWED_RECORD',
  UPDATE_QUOTE_CONTACTS = 'UPDATE_QUOTE_CONTACTS',
  UPDATE_QUOTE_PO_NUMBER = 'UPDATE_QUOTE_PO_NUMBER',
  ADDED_PAYMENT_METHOD = 'ADDED_PAYMENT_METHOD',
  SIGNED_QUOTE = 'SIGNED_QUOTE',
}

export const RecordShareEngagementActionZ = z.nativeEnum(
  RecordShareEngagementAction,
);

export const RecordShareEngagementActionDisplay: Record<
  RecordShareEngagementAction,
  string
> = {
  VIEWED_RECORD: 'Viewed',
  UPDATE_QUOTE_CONTACTS: 'Updated Contacts',
  UPDATE_QUOTE_PO_NUMBER: 'Updated PO Number',
  ADDED_PAYMENT_METHOD: 'Added Payment Method',
  SIGNED_QUOTE: 'Signed Quote',
};

export const RecordShareEngagementActionEnglishDisplay: Record<
  RecordShareEngagementAction,
  string
> = {
  VIEWED_RECORD: 'Record was viewed',
  UPDATE_QUOTE_CONTACTS: 'Contacts were updated',
  UPDATE_QUOTE_PO_NUMBER: 'PO number was updated',
  ADDED_PAYMENT_METHOD: 'Payment Method was added',
  SIGNED_QUOTE: 'Quote was signed',
};

export const RecordShareEngagementSchema = z.object({
  id: z.string(),
  action: RecordShareEngagementActionZ,
  shareId: z.string(),
  recordId: z.string(),
  email: z.string().nullish(),
  userAgent: z.string().nullish(),
  ipAddress: z.string().nullish(),
  timestamp: z
    .union([z.string(), z.date()])
    .transform((val) => (isString(val) ? parseISO(val) : val)),
});
export type RecordShareEngagement = z.infer<typeof RecordShareEngagementSchema>;

export interface RecordShareBaseParams {
  token: string;
  tenantId: string;
  recordId: string;
}

export const QuoteShareContactSchema = z.object({
  id: z.string().nullish(),
  fullName: z.string(),
  email: z.string(),
  phone: z.string().nullish(),
  title: z.string().nullish(),
  address: AddressSchema,
});
export type QuoteShareContact = z.infer<typeof QuoteShareContactSchema>;

export const QuoteShareContactRequiredAddressSchema =
  QuoteShareContactSchema.omit({
    address: true,
  }).extend({
    address: AddressRequiredSchema,
  });
export type QuoteShareContactRequiredAddress = z.infer<
  typeof QuoteShareContactRequiredAddressSchema
>;

const RequiredContacts = z.object({
  requireAddress: z.literal(true),
  billingContact: QuoteShareContactRequiredAddressSchema,
  shippingContact: QuoteShareContactRequiredAddressSchema,
  useBillingForShippingContact: z.boolean(),
});

const OptionalContacts = z.object({
  requireAddress: z.literal(false),
  billingContact: QuoteShareContactSchema,
  shippingContact: QuoteShareContactSchema,
  useBillingForShippingContact: z.boolean(),
});

const PaymentMethodRecordShareRequestSchema = z.object({
  amount: z.number(),
  paymentMethodName: z.string(),
  description: z.string().optional(),
  billingDetails: PaymentMethodContactSchema,
});
export type PaymentMethodRecordShareRequest = z.infer<
  typeof PaymentMethodRecordShareRequestSchema
>;

export const QuoteShareFormSchema = z.object({
  config: z.object({
    requireAddress: z.boolean().optional().default(false),
    requirePaymentMethod: z.boolean().optional().default(false),
    allowPoNumber: z.boolean().optional().default(false),
  }),
  contacts: z
    .discriminatedUnion('requireAddress', [RequiredContacts, OptionalContacts])
    .transform((val) => {
      if (val.useBillingForShippingContact) {
        val.shippingContact = {
          ...val.billingContact,
          address: {
            ...val.billingContact.address,
          },
        };
      }
      return val;
    }),
  payment: z.object({
    requirePaymentMethod: z.boolean(),
    paymentGatewayId: z.string().nullish(),
    paymentMethodType: z.enum(['new', 'existing']),
    paymentMethod: PaymentMethodRecordShareRequestSchema.nullish(),
    paymentMethodId: z.string().nullish(),
  }),
  purchaseOrderNumber: z.string().nullish(),
});

export type QuoteShareForm = z.infer<typeof QuoteShareFormSchema>;

export interface QuoteShareContactRequest {
  billingContact: QuoteShareContact | QuoteShareContactRequiredAddress;
  shippingContact: QuoteShareContact | QuoteShareContactRequiredAddress;
}

export interface QuoteSharePoNumberRequest {
  purchaseOrderNumber?: Maybe<string>;
}

export interface QuoteSharePaymentMethodRequest {
  paymentGatewayId: string;
  paymentMethod: {
    amount: number;
    paymentMethodName: string;
    description?: string;
    billingDetails: IPaymentMethodContactSchema;
    paymentToken?: string;
  };
}
