import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import * as z from 'zod';
import { getRequiredMessage } from '../utils/messages';
import { CustomFieldRecordSchema } from './customFieldsTypes';
import { BaseResponseSchema } from './miscTypes';
import {
  ProductStatusEnumZ,
  ProductTypeEnumZ,
  RecognitionMethodEnumZ,
} from './productTypes';
import { UsageTypeResSchema, UsageTypeStatusEnumZ } from './usageTypes';

export enum PriceStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export const PriceStatusEnumz = z.nativeEnum(PriceStatusEnum);

export enum PriceModelEnum {
  VOLUME = 'VOLUME',
  TIERED = 'TIERED',
  FLAT = 'FLAT',
  CUSTOM = 'CUSTOM',
  PERCENT_OF_TOTAL = 'PERCENT_OF_TOTAL',
}

export const PRICE_MODEL_ENUM_DISPLAY: {
  [key in PriceModelEnum]: string;
} = {
  VOLUME: 'Volume',
  TIERED: 'Tiered',
  FLAT: 'Flat',
  CUSTOM: 'Custom',
  PERCENT_OF_TOTAL: 'Percent Of Total',
};

export const PriceModelEnumZ = z.nativeEnum(PriceModelEnum, {
  errorMap: () => {
    return { message: 'Select a pricing model.' };
  },
});

function refineTierBounds(val: string | number, valueIfEmptyOrNull: boolean) {
  if (isNil(val) || val === '') {
    return valueIfEmptyOrNull;
  }
  return isNumber(val)
    ? val >= 0
    : !isNaN(parseInt(val, 10)) && parseInt(val, 10) >= 0;
}

// used in POST /api/offerings/{offeringId}/rates
export const PriceReqSchema = z.object({
  productId: z.string().nullish(),
  from: z
    .union([z.string(), z.number()])
    .refine((val) => refineTierBounds(val, false), {
      message: 'From must be a valid number',
    })
    .transform((val) => Number(val)),
  to: z
    .union([z.string(), z.number()])
    .refine((val) => refineTierBounds(val, true), {
      message: 'To must be a valid number',
    })
    .nullable()
    .optional()
    .transform((val) => {
      if (isNumber(val)) {
        return val;
      } else if (isString(val) && !!val) {
        return Number(val);
      }
      return null;
    }),
  amount: z
    .union([z.string(), z.number()])
    .nullish()
    .refine((val) => val !== null && val !== undefined && val !== '', {
      message: getRequiredMessage('Amount'),
    })
    .transform((val) => Number(val)),
  priceModel: PriceModelEnumZ,
  description: z.string().nullish(),
});
export type IPriceReqSchema = z.infer<typeof PriceReqSchema>;

export const PricesReqSchema = z
  .object({})
  .catchall(z.object({ prices: PriceReqSchema.array() }));
export type IPricesReqSchema = z.infer<typeof PricesReqSchema>;

// returned as part of rate from GET /api/rates/{$rateId}
export const PriceResSchema = BaseResponseSchema.extend({
  from: z.number(),
  to: z.number().nullable(),
  amount: z.number(),
  description: z.string(),
  priceModel: PriceModelEnumZ,
  product: z.object({
    id: z.string(),
    customId: z.string(),
    financeId: z.string().nullable(),
    name: z.string(),
    description: z.string(),
    status: ProductStatusEnumZ,
    productType: ProductTypeEnumZ,
    usageType: BaseResponseSchema.extend({
      name: z.string(),
      pluralName: z.string(),
      description: z.string(),
      unitName: z.string(),
      decimalPlaces: z.string(),
      status: UsageTypeStatusEnumZ,
      locked: z.boolean(),
    }).nullable(),
  }),
});
export type IPriceResSchema = z.infer<typeof PriceResSchema>;

export const PriceUnderProductSchemaUI = z.object({
  customId: z.string(),
  description: z.string(),
  financeId: z.string(),
  id: z.string(),
  name: z.string(),
  prices: z.array(PriceResSchema),
  productType: ProductTypeEnumZ,
  status: ProductStatusEnumZ,
  usageType: UsageTypeResSchema,
  recognitionMethod: RecognitionMethodEnumZ,
  locked: z.boolean(),
  customFields: CustomFieldRecordSchema,
  taxItemCode: z.string().nullish(),
  taxExempt: z.boolean().default(false),
});

export type IPriceUnderProductSchemaUI = z.infer<
  typeof PriceUnderProductSchemaUI
>;
