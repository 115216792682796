import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { handleApiErrorToast } from '../../../api/axios';
import {
  doRecreateQuote,
  useChangeQuoteOwner,
  useGetContractById,
} from '../../../api/cpqService';
import { INITIAL_MANUAL_RENEWAL_VALUES } from '../../../constants/quotes';
import { getQuoteEditRoute } from '../../../constants/routes';
import { useProductOfferings, useQuoteSettings } from '../../../hooks';
import { useBackNavigate } from '../../../hooks/useBackNavigate';
import { useIsTenantEsignConfigured } from '../../../hooks/useIsTenantEsignConfigured';
import { QuoteDataTypes } from '../../../hooks/useQuote';
import useQuotePrices from '../../../hooks/useQuotePrices';
import { useConfirmModal } from '../../../services/confirmModal';
import { logger } from '../../../services/logger';
import {
  INewQuoteTypeReqSchema,
  IQuoteRequestSchema,
  IQuoteRespSchema,
  IQuoteReviewReq,
  NewQuoteTypeEnum,
  NewQuoteTypeReqSchema,
  QuoteAmendmentVersionEnum,
  QuoteStatusEnum,
  QuoteTypeEnum,
} from '../../../types';
import { debounce } from '../../../utils/debounce';
import { getQuoteRequestFromQuoteResponse } from '../../../utils/quotes';
import { useQuoteContacts } from './components/contacts/useQuoteContacts';
import { useOpportunityLinkData } from './OpportunityLink';
import {
  QuoteContext,
  type QuoteContextTypes,
  type useQuoteState,
} from './quoteContext';
import { QuoteModalDataTypes } from './QuoteReviewModal';
import { updateQuoteQueryCache } from './quoteUtils';

interface QuoteContextProviderProps {
  initialQuote?: IQuoteRespSchema | null;
  isReadOnly: boolean;
  isReadOnlyQuote: boolean;
  children?: React.ReactNode;
  isInternalView: boolean;
  quoteData: QuoteDataTypes;
  quoteFormMethods: UseFormReturn<IQuoteRequestSchema>;
  reviewQuoteModalData: QuoteModalDataTypes;
  productOfferingsData: ReturnType<typeof useProductOfferings>;
  changeOwnerData: ReturnType<typeof useChangeQuoteOwner>;
  quoteStateData: ReturnType<typeof useQuoteState>;
  isLoading: boolean;
  onSubmit: (val: IQuoteRequestSchema) => Promise<IQuoteRespSchema | null>;
  handleReview: (extraData?: IQuoteReviewReq) => void;
  setIsInternalView: (val: boolean) => void;
}

export const QuoteContextProvider: FC<QuoteContextProviderProps> = ({
  isReadOnly,
  isReadOnlyQuote,
  children,
  isInternalView,
  quoteData,
  quoteFormMethods,
  reviewQuoteModalData,
  productOfferingsData,
  changeOwnerData,
  quoteStateData,
  isLoading,
  onSubmit,
  handleReview,
  setIsInternalView,
}) => {
  const [reviewQuoteDisplayWidth, setReviewQuoteDisplayWidth] = useState(400);
  const [actionRunning, setActionRunning] = useState<boolean>(false);
  const [isQuoteSaving, setIsQuoteSaving] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const isMounted = useRef(true);
  const { data: contract, isLoading: isContractLoading } = useGetContractById(
    quoteData.quote?.contractId!,
    {
      enabled: !!quoteData.quote?.contractId,
    },
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const { quotePrices } = useQuotePrices(quoteData.quote?.id!);
  const { isError, quote, transitionQuoteStatus, fetchQuote } = quoteData;

  const { mutate: doChangeQuoteOwner, isPending: loadingChangeOwner } =
    changeOwnerData;

  const loadData = () => quote?.id && fetchQuote(quote.id);
  // useOpportunityLinkData hook for handling opportunityLink components states
  const opportunityLinkData = useOpportunityLinkData({ quote, loadData });
  const isTenantEsignConfigured = useIsTenantEsignConfigured();
  const quoteSettingsData = useQuoteSettings();
  const isEsignEnabled = isTenantEsignConfigured && !!quote?.requiresEsign;

  const params = useParams();
  const { navigate, navigateBack } = useBackNavigate();
  const { showConfirmModal, setModalLoading, closeModal } = useConfirmModal();

  const id = params.quoteId || '';

  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = quoteFormMethods;

  //useForm hook for handling form states of manual renewal
  const manualRenewalFormMethods = useForm<INewQuoteTypeReqSchema>({
    mode: 'onChange',
    resolver: zodResolver(NewQuoteTypeReqSchema),
    defaultValues: INITIAL_MANUAL_RENEWAL_VALUES,
  });

  const showPrimaryContactRequiredBg = !!(
    quote?.quoteOfferings &&
    quote.quoteOfferings.length > 0 &&
    !quote.contacts.primary
  );

  const onError = (error: any, e: any) => {
    // Make sure we see this log when it fails
    logger.error(error);
  };

  useEffect(() => {
    (async () => {
      if (quote?.revenueMetrics.previousArr) {
        manualRenewalFormMethods.reset({
          type: NewQuoteTypeEnum.MANUAL_RENEWAL,
          previousArr: quote?.revenueMetrics.previousArr,
        });
      }
    })();
  }, [quote]);

  /**
   * Use for any quote updates that are made outside the context of the standard quote form (e.x. quote documents)
   * This ensures the form gets reset with the latest quote data do that subsequent updates
   * do not overwrite the previously saved data.
   */
  const handleUpdateQuoteWithFormReset = async (
    quoteRequest: IQuoteRequestSchema,
  ) => {
    const quoteResponse = await onSubmit(quoteRequest);
    quoteResponse && reset(getQuoteRequestFromQuoteResponse(quoteResponse));
  };

  const handleSubmitButton = debounce(
    () => isDirty && handleSubmit(onSubmit, onError)(),
    300,
  );
  const handleSubmitButtonWithoutDirtyCheck = debounce(
    () => handleSubmit(onSubmit, onError)(),
    300,
  );

  const handleChangeOwner = async (ownerId: string) => {
    await doChangeQuoteOwner({ quoteId: quote?.id!, ownerId });
  };

  const handleEdit = async () => {
    const isQuoteSentWithEsign =
      quote?.status === QuoteStatusEnum.SENT && isEsignEnabled;

    const quoteHasApprovals =
      [QuoteStatusEnum.APPROVED, QuoteStatusEnum.SENT].includes(
        quote?.status as QuoteStatusEnum,
      ) && (quote?.approvals || []).length > 0;

    if (quoteHasApprovals || isQuoteSentWithEsign) {
      const onYes = async () => {
        try {
          setModalLoading(true);
          const quoteResponse = await transitionQuoteStatus(id, {
            newState: QuoteStatusEnum.DRAFT,
          });

          if (quoteResponse) {
            updateQuoteQueryCache(queryClient, quoteResponse);
          }
          setIsInternalView(true);
        } catch (ex) {
          handleApiErrorToast(ex);
        } finally {
          closeModal();
          setModalLoading(false);
        }
      };

      const title = isQuoteSentWithEsign
        ? 'Cancel the eSign process?'
        : `Are you sure you want to edit this quote?`;

      const description = isQuoteSentWithEsign
        ? 'Editing this Quote will cancel the eSign process.  Any signatures received to date will be voided.'
        : `If you edit this quote, you will lose any approvals this quote has been given`;

      showConfirmModal({
        title,
        description,
        onYes,
        yesBtnProps: {
          variant: 'delete' as any,
        },
        noBtnProps: {
          variant: 'cancel' as any,
        },
      });
    } else {
      try {
        const quoteResponse = await transitionQuoteStatus(id, {
          newState: QuoteStatusEnum.DRAFT,
        });
        setIsInternalView(true);
        if (quoteResponse) {
          updateQuoteQueryCache(queryClient, quoteResponse);
        }
      } catch (ex) {
        handleApiErrorToast(ex);
      }
    }
  };

  const handleSendQuote = useCallback(async () => {
    try {
      if (!quote?.id) {
        return;
      }
      setActionRunning(true);
      await transitionQuoteStatus(quote.id, { newState: QuoteStatusEnum.SENT });
    } catch (error) {
      handleApiErrorToast(error);
    } finally {
      setActionRunning(false);
    }
  }, [quote?.id, transitionQuoteStatus]);

  const handleRecreateQuote = useCallback(async () => {
    try {
      if (!quote?.id) {
        return;
      }
      setActionRunning(true);
      const newQuote = await doRecreateQuote(quote.id);
      navigate({
        pathname: getQuoteEditRoute(newQuote.id),
      });
    } catch (error) {
      handleApiErrorToast(error);
    } finally {
      setActionRunning(false);
    }
  }, [navigate, quote?.id]);

  const handleBackButtonPress = () => navigate(-1);

  const quoteContacts = useQuoteContacts({
    initialQuoteContacts: quote?.contacts,
    quoteId: quote?.id,
    quote,
    isReadOnly,
    isReadOnlyQuote,
    accountId: quote?.accountId,
    evaluateQuoteRules: quoteData.evaluateQuoteRules,
  });

  const providerValue: QuoteContextTypes = {
    isLoading,
    isError,
    methods: quoteFormMethods,
    manualRenewalFormMethods,
    opportunityLinkData,
    isTenantEsignConfigured,
    quoteData,
    productOfferingsData,
    quoteStateData,
    reviewQuoteModalData,
    showPrimaryContactRequiredBg,
    quoteSettingsData,
    quotePrices,
    isEsignEnabled,
    handleUpdateQuoteWithFormReset,
    handleReview,
    handleSubmitButton,
    handleSubmitButtonWithoutDirtyCheck,
    handleBackButton: navigateBack,
    handleChangeOwner,
    isReadOnly,
    isReadOnlyQuote,
    isInternalView,
    setIsInternalView,
    handleEdit,
    actionRunning,
    handleSendQuote,
    handleRecreateQuote,
    useAmendmentV2:
      quote?.amendmentVersion === QuoteAmendmentVersionEnum.v2 &&
      quote?.type === QuoteTypeEnum.AMENDMENT,
    loadData,
    reviewQuoteDisplayWidth,
    setReviewQuoteDisplayWidth,
    handleBackButtonPress,
    quoteContacts,
    isQuoteSaving:
      isQuoteSaving || loadingChangeOwner || quoteContacts.isLoading,
    setIsQuoteSaving,
    contract,
  };

  return (
    <QuoteContext.Provider value={providerValue}>
      <FormProvider {...quoteFormMethods}>{children}</FormProvider>
    </QuoteContext.Provider>
  );
};
