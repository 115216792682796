import {
  ContractStatusEnum,
  NetTermsEnum,
  ProductTypeEnum,
  QuoteStartDateSourceEnum,
  RateBillingFrequencyEnum,
} from '../types';
import {
  CollaborationAccessEnum,
  EffectiveDateType,
  IQuoteOfferingReqSchema,
  IQuoteRequestSchema,
  NewQuoteTypeEnum,
  QuoteCancelationReasonEnum,
  QuoteStatusEnum,
  QuoteTypeEnum,
} from '../types/quoteTypes';
import { objectToSelectOptions } from '../utils/misc';

// Statuses of quotes that we can still approve/rejects/retract
export const QUOTE_APPROVE_ACTIONABLE_STATUSES = new Set([
  QuoteStatusEnum.REVIEW,
  QuoteStatusEnum.APPROVED,
  QuoteStatusEnum.DENIED,
  QuoteStatusEnum.SENT,
  QuoteStatusEnum.ACCEPTED,
]);

export const QUOTE_EDIT_ACTIONABLE_STATUSES = new Set([
  QuoteStatusEnum.REVIEW,
  QuoteStatusEnum.APPROVED,
  QuoteStatusEnum.DENIED,
  QuoteStatusEnum.SENT,
]);

export const QUOTE_READONLY_STATUSES = new Set([
  QuoteStatusEnum.SENT,
  QuoteStatusEnum.ACCEPTED,
  QuoteStatusEnum.PROCESSED,
  QuoteStatusEnum.CANCELED,
  QuoteStatusEnum.EXPIRED,
  QuoteStatusEnum.ARCHIVED,
]);

export const QUOTE_PRIMARY_BILLING_CONTACT_EDITABLE_STATUSES = new Set([
  QuoteStatusEnum.DRAFT,
  QuoteStatusEnum.REVIEW,
  QuoteStatusEnum.APPROVED,
  QuoteStatusEnum.ACCEPTED,
]);

export const QUOTE_ESIGN_CONTACT_EDITABLE_STATUSES = new Set([
  QuoteStatusEnum.DRAFT,
  QuoteStatusEnum.REVIEW,
  QuoteStatusEnum.APPROVED,
]);

export const QUOTE_COPYABLE_STATUSES = new Set([
  QuoteStatusEnum.EXPIRED,
  QuoteStatusEnum.SENT,
  QuoteStatusEnum.DENIED,
  QuoteStatusEnum.APPROVED,
  QuoteStatusEnum.DRAFT,
  QuoteStatusEnum.REVIEW,
  QuoteStatusEnum.ARCHIVED,
]);

export const QUOTE_ARCHIVE_ACTION_STATUSES = new Set([
  QuoteStatusEnum.CANCELED,
]);

export const QUOTE_SUBMITTED_STATUSES = new Set([
  QuoteStatusEnum.REVIEW,
  QuoteStatusEnum.SENT,
  QuoteStatusEnum.ACCEPTED,
  QuoteStatusEnum.CANCELED,
  QuoteStatusEnum.PROCESSED,
  QuoteStatusEnum.DENIED,
  QuoteStatusEnum.APPROVED,
  QuoteStatusEnum.EXPIRED,
  QuoteStatusEnum.ARCHIVED,
]);

export const QUOTE_SENT_STATUSES = new Set([
  QuoteStatusEnum.SENT,
  QuoteStatusEnum.ACCEPTED,
  QuoteStatusEnum.PROCESSED,
]);

export const QUOTE_ACCEPTED_STATUSES = new Set([
  QuoteStatusEnum.ACCEPTED,
  QuoteStatusEnum.PROCESSED,
]);

export const QUOTE_APPROVED_STATUSES = new Set([
  QuoteStatusEnum.SENT,
  QuoteStatusEnum.ACCEPTED,
  QuoteStatusEnum.PROCESSED,
  QuoteStatusEnum.APPROVED,
]);

export const QUOTE_MANUAL_RENEWABLE_STATUSES = new Set([
  QuoteStatusEnum.PROCESSED,
  QuoteStatusEnum.CANCELED,
  QuoteStatusEnum.EXPIRED,
]);

export const QUOTE_STATUES_TO_ALLOW_EXTEND_EXPIRATION_DATE = new Set([
  QuoteStatusEnum.REVIEW,
  QuoteStatusEnum.SENT,
  QuoteStatusEnum.APPROVED,
]);

export const QUOTE_NOT_ACTIONABLE_STATUSES = new Set([
  QuoteStatusEnum.CANCELED,
  QuoteStatusEnum.ARCHIVED,
  QuoteStatusEnum.PROCESSED,
  QuoteStatusEnum.EXPIRED,
]);

export const QUOTE_DISABLED_STATUSES = new Set([
  QuoteStatusEnum.CANCELED,
  QuoteStatusEnum.ARCHIVED,
  QuoteStatusEnum.EXPIRED,
]);

export const QUOTE_AFTER_SENT_STATUSES = new Set([
  QuoteStatusEnum.SENT,
  QuoteStatusEnum.ACCEPTED,
  QuoteStatusEnum.CANCELED,
  QuoteStatusEnum.PROCESSED,
  QuoteStatusEnum.DENIED,
  QuoteStatusEnum.EXPIRED,
  QuoteStatusEnum.ARCHIVED,
]);

export const CAN_SHARE_QUOTE_INTERNALLY_STATUSES = new Set([
  QuoteStatusEnum.DRAFT,
  QuoteStatusEnum.REVIEW,
  QuoteStatusEnum.APPROVED,
]);

export const CAN_SHARE_QUOTE_EXTERNALLY_STATUSES = new Set([
  QuoteStatusEnum.APPROVED,
  QuoteStatusEnum.SENT,
  QuoteStatusEnum.ACCEPTED,
]);

export const SHARE_QUOTE_EXTERNALLY_DRAFT_STATUSES = new Set([
  QuoteStatusEnum.DRAFT,
  QuoteStatusEnum.REVIEW,
]);

export const QUANTITY_EDITABLE_PRODUCT_TYPES = new Set([
  ProductTypeEnum.ONETIME,
  ProductTypeEnum.ONETIME_PREPAID_CREDIT,
]);

export const QuoteTypeEnumDisplay: {
  [key in QuoteTypeEnum]: string;
} = {
  NEW: 'New',
  AMENDMENT: 'Amendment',
  RENEWAL: 'Renewal',
};

export const QUOTE_TOTAL_EDIT_STYLE = {
  [QuoteTypeEnum.NEW]: {
    borderColor: undefined,
    backgroundColor: 'tGray.support',
    color: 'tPurple.dark',
  },
  [QuoteTypeEnum.AMENDMENT]: {
    borderColor: undefined,
    backgroundColor: 'tBlue.hover',
    color: 'tPurple.safety',
  },
  [QuoteTypeEnum.RENEWAL]: {
    borderColor: undefined,
    backgroundColor: 'tBlue.hover',
    color: 'tPurple.safety',
  },
};

export const QuoteStatusEnumDisplay: {
  [key in QuoteStatusEnum]: string;
} = {
  DRAFT: 'Draft',
  REVIEW: 'Review',
  APPROVED: 'Approved',
  DENIED: 'Denied',
  SENT: 'Sent',
  ACCEPTED: 'Accepted',
  PROCESSED: 'Processed',
  CANCELED: 'Canceled',
  EXPIRED: 'Expired',
  ARCHIVED: 'Archived',
};

export const QuoteStatusTagStyle: {
  [key in QuoteStatusEnum]: {
    color: string;
    bgColor?: string;
    px?: number;
    borderRadius?: number;
    fontStyle?: string;
  };
} = {
  DRAFT: {
    color: 'tGray.darkGrayPurple',
    bgColor: 'transparent',
  },
  REVIEW: {
    color: 'tGray.darkGrayPurple',
  },
  APPROVED: {
    color: 'tGray.darkGrayPurple',
  },
  DENIED: {
    color: 'tRed.base',
  },
  SENT: {
    color: 'tGray.darkGrayPurple',
  },
  ACCEPTED: {
    color: 'tGreen.mDarkShade',
  },
  PROCESSED: {
    color: 'tGreen.mDarkShade',
    bgColor: 'tGreen.light',
    px: 2,
    borderRadius: 4,
  },
  CANCELED: {
    color: 'tGray.darkGrayPurple',
  },
  EXPIRED: {
    color: 'tGray.darkGrayPurple',
    fontStyle: 'italic',
  },
  ARCHIVED: {
    color: 'tGray.darkGrayPurple',
  },
};

export const DISCOUNT_OPTIONS = [
  { title: '+Custom', value: 'CUSTOM' },
  { title: 'Monthly Sale', value: 'MONTHLY_SALE' },
  { title: 'First Customer', value: 'FIRST_CUSTOMER' },
];

export const DEFAULT_QUOTE_COLORS = {
  color: 'tGray.darkGrayPurple',
  bgColor: 'tWhite.base',
  borderColor: 'tGray.lightPurple',
};

// Edit/New Quote Variables

export const QUOTE_CUSTOM_CONTRACT_LENGTHS = [12, 24, 36];
export const QUOTE_CONTRACT_LENGTHS = [1, 2, 3, 4, 5];

export const QUOTE_GRID_TEMPLATE_COLUMNS_WITH_ADDITIONAL_INFO =
  '4fr 1.5fr repeat(5, 1fr) 0.05fr';

export const QUOTE_GRID_TEMPLATE_COLUMNS = '4fr 1.5fr repeat(4, 1fr) 0.05fr';

export const QUOTE_ITEM_GRID = 'repeat(7, 1fr)';

export const NEW_QUOTE_ITEM_GRID = 'repeat(6, 1fr)';

export const HEADER_ROW_CELLS_WITH_ADDITIONAL_INFO = [
  'Offering',
  '', // do now show the header
  'Quantity',
  'Discount',
  'Additional Info',
  'Price Per Unit',
  'Amount',
  '',
];

export const HEADER_ROW_CELLS = [
  'Offering',
  '', // do now show the header
  'Quantity',
  'Discount',
  'Price Per Unit',
  'Amount',
  '',
];

export const quoteOfferingInitial: IQuoteOfferingReqSchema = {
  offeringId: '',
  rateId: '',
  subscriptionId: '',
  discountIds: [],
  items: [],
};

export const INITIAL_FORM_VALUES: IQuoteRequestSchema = {
  description: '',
  currency: 'USD',
  netTerms: NetTermsEnum.NET_0,
  autoRenewContract: false,
  requiresEsign: false,
  collaborationAccess: CollaborationAccessEnum.NONE,
  legalEntityId: '',
  customFields: {},
};

export const INITIAL_CONTACT_VALUES = {
  address: {
    city: '',
    country: '',
    description: '',
    id: '',
    line1: '',
    line2: '',
    postalCode: '',
    state: '',
  },
  customId: '',
  email: '',
  fullName: '',
  id: '',
  phone: '',
  status: '',
  type: 'QUOTE',
};

export const RATE_BILLING_FREQUENCY_DISPLAY: {
  [key in RateBillingFrequencyEnum]: string;
} = {
  DAILY: 'Daily',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  SEMIANNUALLY: 'Semi-annually',
  ANNUALLY: 'Annually',
  CUSTOM: 'Custom',
  ONETIME: 'Onetime',
};

export const PAPER_PAGE_MAX_WIDTH = 1220;

export const INITIAL_MANUAL_RENEWAL_VALUES = {
  type: NewQuoteTypeEnum.MANUAL_RENEWAL,
  previousArr: '',
};

export const QUITE_TYPE_SELECT_OPTIONS =
  objectToSelectOptions(QuoteTypeEnumDisplay);

export const NEW_CONTRACT_SLUG = '*/newContract/*';

export const CONTRACT_STATUS_AMENDMENT_ALLOWED = new Set([
  ContractStatusEnum.ACTIVE,
  ContractStatusEnum.PENDING,
]);

export const FREQUENCY_ITEMS = [
  { title: 'Monthly (1)', value: 1 },
  { title: 'Quarterly (3)', value: 3 },
  { title: 'Semi-Annually (6)', value: 6 },
  { title: 'Annually (12)', value: 12 },
];

export const FREQUENCY_ITEMS_WITHOUT_NUMBERINGS = [
  { title: 'Monthly', value: 1 },
  { title: 'Quarterly', value: 3 },
  { title: 'Semi-Annually', value: 6 },
  { title: 'Annually', value: 12 },
];

export const DISPLAY_FREQUENCY_ALLOWED_PRODUCT_TYPE = new Set([
  ProductTypeEnum.ADVANCE,
  ProductTypeEnum.MIN_COMMIT,
]);

export const QUOTE_AFFINITY = 'QUOTE_AFFINITY';
export const QUOTE_AFFINITY_MARCH_2024 = 'QUOTE_AFFINITY_MARCH_2024';
export const QUOTE_MATERIALIZE = 'QUOTE_MATERIALIZE';
export const QUOTE_MIDDESK = 'QUOTE_MIDDESK';
export const QUOTE_PLOTLINE = 'QUOTE_PLOTLINE';
export const QUOTE_DANFOSS_EU = 'QUOTE_DANFOSS_EU';
export const QUOTE_DANFOSS_US = 'QUOTE_DANFOSS_US';
export const QUOTE_LENDER = 'QUOTE_LENDER';
export const QUOTE_SENTRY = 'QUOTE_SENTRY';
export const QUOTE_TEMPLATE_ITEMS: {
  title: string;
  value: string;
  /**
   * Indicates if this template should be available for everyone
   */
  isDefault?: true;
}[] = [
  { title: 'Default', value: 'QUOTE_DEFAULT_1', isDefault: true },
  {
    title: 'Default - Services Order Form',
    value: 'QUOTE_DEFAULT_2',
    isDefault: true,
  },
  {
    title: 'Default - Excludes VAT',
    value: 'QUOTE_DEFAULT_3_EXCL_VAT',
    isDefault: true,
  },
  {
    title: 'Default - V4',
    value: 'QUOTE_DEFAULT_4',
  },
  {
    title: 'Affinity',
    value: QUOTE_AFFINITY,
  },
  {
    title: 'Affinity - March 2024',
    value: QUOTE_AFFINITY_MARCH_2024,
  },
  {
    title: 'Materialize',
    value: QUOTE_MATERIALIZE,
  },
  {
    title: 'Middesk',
    value: QUOTE_MIDDESK,
  },
  {
    title: 'Plotline',
    value: QUOTE_PLOTLINE,
  },
  {
    title: 'Danfoss EU',
    value: QUOTE_DANFOSS_EU,
  },
  {
    title: 'Danfoss US',
    value: QUOTE_DANFOSS_US,
  },
  {
    title: 'Lender',
    value: QUOTE_LENDER,
  },
  {
    title: 'Sentry',
    value: QUOTE_SENTRY,
  },
  { title: 'Default Test V2', value: 'QUOTE_DEFAULT_1_TEST_V2' },
  {
    title: 'Default - Services Order Form Test V2',
    value: 'QUOTE_DEFAULT_2_TEST_V2',
  },
  {
    title: 'Default - Excludes VAT Test V2',
    value: 'QUOTE_DEFAULT_3_EXCL_VAT_TEST_V2',
  },
  {
    title: 'Affinity Test V2',
    value: 'QUOTE_AFFINITY_TEST_V2',
  },
  {
    title: 'Affinity - March 2024 Test V2',
    value: 'QUOTE_AFFINITY_MARCH_2024_TEST_V2',
  },
  {
    title: 'Materialize Test V2',
    value: 'QUOTE_MATERIALIZE_TEST_V2',
  },
  {
    title: 'Middesk Test V2',
    value: 'QUOTE_MIDDESK_TEST_V2',
  },
];

export const SCHEDULED_OFFERING_OPTIONS: {
  title: string;
  value: EffectiveDateType;
}[] = [
  { title: '+ 1 Month', value: 1 },
  { title: '+ 3 Month', value: 3 },
  { title: '+ 6 Month', value: 6 },
  { title: '+ 1 Year', value: 12 },
  { title: 'Custom', value: 'CUSTOM' },
];

export const QUOTE_START_DATE_SOURCE_OPTIONS = [
  {
    title: 'Editable until Drafted',
    value: QuoteStartDateSourceEnum.ORIGINAL_CONTRACT_START,
  },
  {
    title: `Set by Signature Date`,
    value: QuoteStartDateSourceEnum.AT_TIME_OF_SIGNING,
  },
  {
    title: 'Editable after Signature',
    value: QuoteStartDateSourceEnum.MANUAL,
  },
];

export const DELATED_BILLING_QUOTE_START_SOURCE = new Set([
  QuoteStartDateSourceEnum.AT_TIME_OF_SIGNING,
  QuoteStartDateSourceEnum.MANUAL,
]);

export const QUOTE_PROGRESS_CANCEL_MESSAGE = {
  [QuoteCancelationReasonEnum.BY_USER]: 'Cancelled by User',
  [QuoteCancelationReasonEnum.CUSTOMER_DECLINED]: 'Cancelled by Customer',
  [QuoteCancelationReasonEnum.DUPLICATE]: 'Cancelled due to duplication',
  [QuoteCancelationReasonEnum.EXPIRED]: 'Expired due to Inactivity',
};

export const NewQuoteTypeEnumDisplay: {
  [key in NewQuoteTypeEnum]: string;
} = {
  NET_NEW: 'Net New',
  MANUAL_RENEWAL: 'Manual Renewal',
  EARLY_RENEWAL: 'Early Renewal',
};
