import { AmountUnitTypeEnum } from '../types';

export const AMOUNT_UNIT_DISPLAY_TEXT: {
  [key in AmountUnitTypeEnum]: string;
} = {
  FLAT: 'Flat',
  PERCENTAGE: 'Percent',
};

export const AMOUNT_UNIT_DISPLAY_SYMBOL: {
  [key in AmountUnitTypeEnum]: string;
} = {
  FLAT: '$',
  PERCENTAGE: '%',
};

export const AMOUNT_UNIT_OPTIONS: { value: string; title: string }[] = [
  // https://monetizenow.atlassian.net/browse/BP-8370 Remove flat discount
  // { value: AmountUnitTypeEnum.FLAT, title: 'Flat' },
  { value: AmountUnitTypeEnum.PERCENTAGE, title: 'Percentage' },
];
