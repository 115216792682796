import { Link } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { useGetUsersListByTenantId } from '../../api/usersService';
import { MBox, MButton, MText } from '../../components/Monetize';
import MPageContainer from '../../components/Monetize/MPageContainer';
import { useDashboardTab } from '../../hooks';
import { useAuth } from '../../services/auth0';
import {
  DEFAULT_PAGER,
  IUser,
  TDataTablePager,
  UserRoleEnum,
} from '../../types';

const pager: TDataTablePager = {
  ...DEFAULT_PAGER,
  rows: 3,
};

export const UnauthorizedPage = () => {
  const navigate = useNavigate();
  const { tenantId } = useAuth();
  const { getDashboardTabRouteByRole } = useDashboardTab();

  const goDashboard = () => {
    navigate(getDashboardTabRouteByRole());
  };

  const { data: tenantAdmins } = useGetUsersListByTenantId<IUser>({
    tenantId,
    config: pager,
    filters: {
      role: { in: [UserRoleEnum.TENANT_ADMIN] },
    },
    axiosConfig: { customXTenantId: tenantId },
  });

  const adminNames = tenantAdmins?.content.map(({ name }) => name);
  const adminNamesList = adminNames?.join(', ');

  return (
    <MPageContainer>
      <MBox
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        flex={1}
      >
        <MText fontSize="2xl" fontWeight="bold">
          Unauthorized Access
        </MText>
        <MText fontSize="large" mt={6} mb={3} textAlign="center">
          You can’t access this page because you don’t have permission.
          <br />
          If this was a mistake, please contact{' '}
          {adminNamesList ? adminNamesList : 'the tenant administrator'} or our{' '}
          <Link href="mailto:support@monetizenow.io" target="_blank">
            support team.
          </Link>
        </MText>

        <MButton onClick={goDashboard} variant="secondary" my="4">
          Return to Dashboard
        </MButton>
      </MBox>
    </MPageContainer>
  );
};
