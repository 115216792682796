import { useEffect, useState } from 'react';
import { useGetRates } from '../../../../../api/productCatalogService';
import {
  MButton,
  MCenterModal,
  MFlex,
  MSearchInput,
  MSpinner,
  MStack,
  MText,
} from '../../../../../components/Monetize';
import {
  DEFAULT_PAGER,
  RateTypeEnum,
  TDataTablePager,
} from '../../../../../types';
import { CustomRateItemsAccordion } from './CustomRateItemsAccordion';

interface CustomRateModalProps {
  parentRateId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const CustomRateModal = ({
  parentRateId,
  isOpen,
  onClose,
}: CustomRateModalProps) => {
  const [pager, setPager] = useState<TDataTablePager>(DEFAULT_PAGER);
  const [query, setQuery] = useState<string>('');
  const [queryKey, setQueryKey] = useState('accountName');

  const rateFilterOptions = {
    rateType: RateTypeEnum.ACCOUNT,
    parentRateId,
    [queryKey]: {
      contains: query.trim(),
    },
  };

  const {
    data: customRates,
    isLoading,
    isFetching,
  } = useGetRates(pager, rateFilterOptions, {
    enabled: !!parentRateId && isOpen,
    placeholderData: (previousData, previousQuery) => previousData,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    return () => {
      setQuery('');
      setPager(DEFAULT_PAGER);
    };
  }, []);

  useEffect(() => {
    if (!query) {
      setQueryKey('accountName');
    }
    if (query.trim().startsWith('acct_')) {
      setQueryKey('accountId');
    }
  }, [query]);

  const hasCustomRates = (customRates?.totalPages ?? 0) > 0;
  const shouldRenderFooter = (customRates?.totalPages ?? 0) > 1;

  if (!hasCustomRates && query.trim() === '') {
    return (
      <MCenterModal
        isOpen={isOpen}
        onClose={onClose}
        modalTitle="Custom Rates"
        modalHeaderProps={{
          mr: 10,
        }}
        size="lg"
      >
        <MFlex justifyContent="center" alignItems="center">
          {isLoading ? (
            <MSpinner />
          ) : (
            <MText>There are no custom rates for this rate.</MText>
          )}
        </MFlex>
      </MCenterModal>
    );
  }
  const renderFooter = () =>
    shouldRenderFooter ? (
      <MStack
        direction="row"
        align="center"
        justify="center"
        flex={1}
        spacing={2}
      >
        <MButton
          isLoading={isFetching}
          onClick={() =>
            setPager((currentPager) => ({
              ...currentPager,
              rows: currentPager.rows + 20,
            }))
          }
          variant="tertiary"
          minW="auto"
        >
          Load More
        </MButton>
      </MStack>
    ) : (
      <></>
    );
  return (
    <MCenterModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Custom Rates"
      modalHeaderProps={{ mr: 10 }}
      size="lg"
      renderFooter={renderFooter}
      modalFooterProps={
        shouldRenderFooter ? { border: 'none' } : { border: 'none', pb: 2 }
      }
    >
      <MFlex direction="column">
        <MSearchInput
          placeholder="Search Accounts"
          data-testid="custom-rate-search-input"
          value={query}
          onChange={setQuery}
          count={customRates?.content.length}
          loading={query.trim() !== '' && (isLoading || isFetching)}
        />
        {hasCustomRates && (
          <CustomRateItemsAccordion customRates={customRates?.content ?? []} />
        )}
        {query.trim() !== '' && !customRates?.content.length && (
          <MFlex justifyContent="center" alignItems="center" mt={4}>
            <MText fontSize="sm" fontWeight="normal">
              No results found
            </MText>
          </MFlex>
        )}
      </MFlex>
    </MCenterModal>
  );
};
