import { formatCurrency } from '@monetize/utils/core';
import { UseQueryOptions } from '@tanstack/react-query';
import { useGetCurrencySettings } from '../api/settingsService';
import { ALL_CURRENCIES } from '../constants/currencies';
import { ApiListResponse, ICurrencyResp } from '../types';

/**
 * Simple wrapper for useCurrency to include the currencies and the default
 * Ensues that components do not need to worry about the default currency
 */
export const useCurrencies = (
  options?: Partial<
    UseQueryOptions<
      ApiListResponse<ICurrencyResp>,
      unknown,
      ApiListResponse<ICurrencyResp>
    >
  >,
) => {
  const { data, isLoading, isFetching, isPending } = useGetCurrencySettings(
    {},
    {
      refetchOnWindowFocus: false,
      placeholderData: (previousData, previousQuery) => previousData,
      retry: false,
      staleTime: 1000 * 60 * 60, // 1 hour,
      select: (response) => {
        return (
          response?.content.map(
            (currency): ICurrencyResp & { description: string } => ({
              ...currency,
              description: ALL_CURRENCIES[currency.code].name,
            }),
          ) || []
        );
      },
    },
  );
  const currencies = data || [];

  const defaultCurrency =
    currencies?.find((item) => item.defaultCurrency)?.code || 'USD';

  const currencyPlaceholder = formatCurrency('0', {
    currency: defaultCurrency as string,
  });

  return {
    isLoading,
    isPending,
    isFetching,
    currencies,
    defaultCurrency,
    currencyPlaceholder,
  };
};
