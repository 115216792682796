import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MdCreditCard } from 'react-icons/md';
import {
  MAccordion,
  MBox,
  MCustomSelect,
  MFormField,
  MInput,
  MRadio,
  MText,
} from '../../components/Monetize';
import { PaymentMethodItemRenderer } from '../../components/PaymentMethods/PaymentMethodItemRenderer';
import { logger } from '../../services/logger';
import Sentry from '../../services/sentry';
import { Maybe, PaymentMethodSummaryResponse } from '../../types';
import { QuoteShareForm } from '../../types/recordShareTypes';
import { getGroupedAndSortedPaymentMethodOptions } from '../../utils/payment.utils';
import { QuoteShareAccordion } from './QuoteShareAccordion';

interface QuoteSharePaymentMethodProps {
  existingPaymentMethods?: Maybe<PaymentMethodSummaryResponse[]>;
  onInit: (data: {
    stripe?: Maybe<Stripe>;
    elements?: Maybe<StripeElements>;
  }) => void;
}

export const QuoteSharePaymentMethod = ({
  existingPaymentMethods,
  onInit,
}: QuoteSharePaymentMethodProps) => {
  const elements = useElements();
  const stripe = useStripe();
  const [stripeState, setStripeState] = useState<'LOADING' | 'ERROR' | 'READY'>(
    'LOADING',
  );

  const hasPaymentMethods =
    existingPaymentMethods && existingPaymentMethods.length > 0;

  const paymentMethodItems = useMemo(
    () => getGroupedAndSortedPaymentMethodOptions(existingPaymentMethods || []),
    [existingPaymentMethods],
  );

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<QuoteShareForm>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onInit({ stripe, elements }), [stripe, elements]);

  const allowPoNumber = watch('config.allowPoNumber');
  const paymentMethodType = watch('payment.paymentMethodType');

  if (stripeState === 'ERROR') {
    return null;
  }

  return (
    <MAccordion
      w="100%"
      mt={4}
      mb={2}
      allowToggle
      variant="outline"
      defaultIndex={[0]}
    >
      <QuoteShareAccordion icon={MdCreditCard} title="Payment Information">
        {hasPaymentMethods && (
          <>
            {' '}
            <MBox>
              <MRadio
                onChange={(value) =>
                  setValue('payment.paymentMethodType', 'existing')
                }
                isChecked={paymentMethodType === 'existing'}
              >
                <MText fontSize="sm" ml={2} fontWeight="bold">
                  Existing Payment Method
                </MText>
              </MRadio>
              {paymentMethodType === 'existing' && (
                <MBox display={{ md: 'flex' }} gap={{ md: 4 }} mt={4} ml={7}>
                  <MFormField
                    error={errors?.payment?.paymentMethodId}
                    label="Payment Method"
                    isRequired
                  >
                    <Controller
                      name="payment.paymentMethodId"
                      control={control}
                      render={({ field }) => (
                        <MCustomSelect
                          items={paymentMethodItems}
                          itemTitle="name"
                          itemValue="id"
                          renderItemContent={PaymentMethodItemRenderer}
                          {...field}
                        />
                      )}
                    />
                  </MFormField>
                  {allowPoNumber && (
                    <MFormField
                      mt={{ base: 4, md: 0 }}
                      error={errors?.purchaseOrderNumber}
                      label="PO Number"
                    >
                      <Controller
                        name="purchaseOrderNumber"
                        control={control}
                        defaultValue=""
                        render={({ field: { value, ...rest } }) => (
                          <MInput {...rest} value={value || ''} />
                        )}
                      />
                    </MFormField>
                  )}
                </MBox>
              )}
            </MBox>
            <MRadio
              onChange={(value) => setValue('payment.paymentMethodType', 'new')}
              isChecked={paymentMethodType === 'new'}
              my={4}
            >
              <MText fontSize="sm" ml={2} fontWeight="bold">
                New Payment Method
              </MText>
            </MRadio>
          </>
        )}

        {(!hasPaymentMethods || paymentMethodType === 'new') && (
          <MBox ml={hasPaymentMethods ? 7 : undefined}>
            <PaymentElement
              onLoaderStart={() => setStripeState('LOADING')}
              onLoadError={(ev) => {
                setStripeState('ERROR');
                logger.error('onLoadError', ev);
                Sentry.captureException(ev.error, {
                  tags: {
                    type: 'QUOTE_SHARE',
                    action: 'LOAD_PAYMENT_FORM',
                  },
                });
              }}
              onReady={(ev) => setStripeState('READY')}
              options={{
                fields: {
                  billingDetails: 'never',
                },
              }}
            />

            {stripeState === 'READY' && (
              <MBox display={{ md: 'flex' }} gap={{ md: 4 }} mt={4}>
                <MFormField
                  error={errors?.payment?.paymentMethod?.paymentMethodName}
                  label="Payment Method Name"
                  isRequired
                >
                  <Controller
                    name="payment.paymentMethod.paymentMethodName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <MInput {...field} />}
                  />
                </MFormField>

                {allowPoNumber && (
                  <MFormField
                    mt={{ base: 4, md: 0 }}
                    error={errors?.purchaseOrderNumber}
                    label="PO Number"
                  >
                    <Controller
                      name="purchaseOrderNumber"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, ...rest } }) => (
                        <MInput {...rest} value={value || ''} />
                      )}
                    />
                  </MFormField>
                )}
              </MBox>
            )}
          </MBox>
        )}
      </QuoteShareAccordion>
    </MAccordion>
  );
};
