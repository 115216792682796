import { ChangeEvent, useEffect, useState } from 'react';
import { useGetListData } from '../../api/queryUtils';
import { MCustomSelect, MFlex } from '../../components/Monetize';
import { MainSearchInputV2 } from '../../components/Monetize/MCustomSelect/components/MainSearchInputV2';
import { Highlighter } from '../../components/Monetize/MPageSearchInput/Highlighter';
import { DEFAULT_PAGER, IBillGroupResp } from '../../types';
import { arrayToObject } from '../../utils/misc';

const billGroupSelectRenderItem = ({
  item,
  query,
}: {
  item: IBillGroupResp;
  query: string;
}) => {
  const { name, id } = item as IBillGroupResp;
  return (
    <MFlex
      direction="column"
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
    >
      <Highlighter
        title={name}
        color="tPurple.dark"
        fontSize="sm"
        fontWeight="medium"
        textToHighlight={name}
        searchWords={[query]}
      />
      <Highlighter
        title={id}
        color="tGray.darkPurple"
        fontSize="xs"
        textToHighlight={id}
        searchWords={[query]}
      />
    </MFlex>
  );
};

interface AccountDetailsBillGroupFilterProps {
  accountId: string;
  billGroupId?: string;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
}
export const AccountDetailsBillGroupFilter = ({
  accountId,
  billGroupId,
  onChange,
}: AccountDetailsBillGroupFilterProps) => {
  const [billGroupQuery, setBillGroupQuery] = useState('');

  const { data: billGroupsData, isLoading: billGroupsLoading } = useGetListData<
    IBillGroupResp,
    { content: IBillGroupResp[]; contentById: Record<string, IBillGroupResp> }
  >(
    'billGroups',
    { config: { ...DEFAULT_PAGER, rows: 100 } },
    {
      endpointArgs: { accountId },
      select: (data) => {
        return {
          content: data.content || [],
          contentById: arrayToObject(data.content || [], 'id'),
        };
      },
    },
  );

  const [billGroups, setBillGroups] = useState(billGroupsData?.content || []);

  useEffect(() => {
    if (billGroupsData?.content) {
      setBillGroups(
        billGroupsData.content.filter(
          ({ name, id }) =>
            (name &&
              name.toLowerCase().includes(billGroupQuery.toLowerCase())) ||
            (id && id.toLowerCase().includes(billGroupQuery.toLowerCase())),
        ),
      );
    } else {
      setBillGroups(billGroupsData?.content || []);
    }
  }, [billGroupsData, billGroupQuery]);

  return (
    <MCustomSelect
      value={billGroupId}
      isLazy
      clearable
      useMainInputAsSearchInput
      MainInputComponent={MainSearchInputV2}
      placeholder={
        billGroupId
          ? billGroupsData?.contentById[billGroupId]?.name
          : 'All Bill Groups'
      }
      itemTitle="name"
      itemValue="id"
      items={billGroups}
      loading={billGroupsLoading}
      useHighlightedItemList
      showItemDivider
      useExternalQuery
      externalQuery={billGroupQuery}
      onChangeQuery={setBillGroupQuery}
      skipFilter={true}
      renderItemContent={billGroupSelectRenderItem}
      onChange={onChange}
      isTruncatedInputTitle
    />
  );
};
