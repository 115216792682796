import { zodResolver } from '@hookform/resolvers/zod';
import { formatCurrency } from '@monetize/utils/core';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { doCreateCreditNoteForInvoice } from '../../api/accountsService';
import { handleApiErrorToast } from '../../api/axios';
import {
  invoiceServiceQueryKeys,
  queryKeysInvoice,
} from '../../api/invoiceService';
import { CustomFieldDataForm } from '../../components/CustomFields/CustomFieldDataForm';
import {
  MButton,
  MCenterModal,
  MFormField,
  MGrid,
  MGridItem,
  MStack,
  MText,
} from '../../components/Monetize';
import { logger } from '../../services/logger';
import {
  CustomFieldEntityEnum,
  ICustomFieldRecordSchema,
  IInvoiceRespSchema,
  InvoiceSummaryResp,
} from '../../types';
import {
  CreditNoteRefundReasonEnum,
  CreditNoteReqSchema,
  CreditNoteTypeEnum,
  ICreditNoteReqSchema,
} from '../../types/creditNoteTypes';
import { nullifyEmptyStrings } from '../../utils/misc';

type CreditNoteCreateModalProps = {
  isOpen: boolean;
  invoice: IInvoiceRespSchema | InvoiceSummaryResp;
  onClose: (reload: boolean) => void;
};

export const CreditNoteCreateModal = ({
  isOpen,
  invoice,
  onClose,
}: CreditNoteCreateModalProps) => {
  const initialCreditAmount = Math.min(
    invoice.amountWithoutTax || 0,
    invoice.amountDue || 0,
  );
  const [customFields, setCustomFields] = useState<ICustomFieldRecordSchema>(
    {},
  );
  const [loading, setLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ICreditNoteReqSchema>({
    resolver: zodResolver(CreditNoteReqSchema),
    mode: 'onChange',
    defaultValues: {
      amount: initialCreditAmount,
      type: CreditNoteTypeEnum.ADJUSTMENT,
      refundable: false,
      refundReason: CreditNoteRefundReasonEnum.ADJUSTMENT,
    },
  });

  const refundReason = watch('refundReason');

  useEffect(() => {
    if (refundReason !== CreditNoteRefundReasonEnum.OTHER) {
      setValue('otherReason', '');
    }
  }, [refundReason]);

  // If invoice data is stale and credits were applied in the background, ensure form update
  useEffect(() => {
    setValue('amount', initialCreditAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCreditAmount]);

  const onSubmit = async (formData: ICreditNoteReqSchema) => {
    setLoading(true);

    if (customFields) {
      formData.customFields = customFields;
    }

    try {
      await doCreateCreditNoteForInvoice(
        invoice.id,
        nullifyEmptyStrings(formData),
      );
      queryClient.invalidateQueries({
        queryKey: queryKeysInvoice.byId.queryKey(invoice.id),
      });
      queryClient.invalidateQueries({
        queryKey: invoiceServiceQueryKeys.htmlTemplate(invoice.id),
      });
      handleClose(true);
    } catch (err) {
      handleApiErrorToast(err);
    } finally {
      setLoading(false);
    }
  };

  const onError = (err: any) => logger.error(err);

  function handleClose(reload = false) {
    reset();
    onClose && onClose(reload);
  }

  return (
    <MCenterModal
      size="sm"
      isOpen={isOpen}
      onClose={handleClose}
      modalTitle="Create a Credit Note"
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton variant="cancel" onClick={() => onClose(false)} minW="auto">
            Cancel
          </MButton>
          <MButton
            form="credit-note-form"
            variant="primary"
            isLoading={loading}
            type="submit"
            minW="auto"
          >
            Save
          </MButton>
        </MStack>
      )}
    >
      <MGrid
        alignItems="center"
        templateColumns="repeat(12, 1fr)"
        gap={4}
        mt="-.8rem"
      >
        <MGridItem colSpan={12}>
          <form
            id="credit-note-form"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <MStack>
              <MFormField
                error={errors.amount}
                label="Amount Remaining"
                isRequired
              >
                <Controller
                  name="amount"
                  control={control}
                  render={() => (
                    <MText mt="-.3rem">
                      {formatCurrency(initialCreditAmount, {
                        currency: invoice.currency,
                      })}
                    </MText>
                  )}
                />
              </MFormField>
              <MGridItem colSpan={12}>
                <CustomFieldDataForm
                  entity={CustomFieldEntityEnum.CREDIT_NOTE}
                  value={customFields}
                  setValue={(val) => {
                    setCustomFields(val);
                  }}
                  fieldColSpan={6}
                  mode="modal"
                />
              </MGridItem>
            </MStack>
          </form>
        </MGridItem>
      </MGrid>
    </MCenterModal>
  );
};
