import { usePrintQuoteToHtml } from '../../../api/cpqService';
import { HtmlIFrameContentRenderer } from '../../../components/Monetize/HtmlIFrameContentRenderer';
import { IQuoteRespSchema, IQuoteTemplateConfigSchema } from '../../../types';

interface ReviewQuoteHtmlProps {
  quote: IQuoteRespSchema;
  displayConfig: IQuoteTemplateConfigSchema;
}

export const ReviewQuoteHtml = ({
  quote,
  displayConfig,
}: ReviewQuoteHtmlProps) => {
  const { data, isLoading, isRefetching, error, isPending } =
    usePrintQuoteToHtml({
      quoteId: quote.id,
      displayConfig,
      lastModifiedTimestamps: [
        quote.modifyDate,
        ...quote.quoteOfferings.map(({ modifyDate }) => modifyDate),
      ],
    });

  if (error) {
    // TODO: add error state
    return null;
  }

  return (
    <HtmlIFrameContentRenderer
      id="quote-content"
      html={data}
      isLoading={isLoading}
      isReFetching={isPending || isRefetching}
    ></HtmlIFrameContentRenderer>
  );
};
