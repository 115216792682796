import {
  AccountTabTypes,
  AddressSourceEnum,
  BillGroupTabTypes,
  Maybe,
  NetTermsEnum,
} from '../types';

// Auth
export const AUTH_REGISTER = '/auth/register';
export const AUTH_FORGOT_PASSWORD = '/auth/forgot-password';
export const AUTH_LOGIN = '/auth/login';
export const AUTH_CALLBACK = '/auth/callback';
export const AUTH_LOGOUT = '/auth/logout';
export const AUTH_REFRESH_TOKEN = '/auth/refresh-token';

// After auth, onboarding routes
export const ONBOARD_CREATE_USER = '/onboard/create-user';
export const ONBOARD_CREATE_TENANT = '/onboard/create-tenant';
export const ONBOARD_VERIFY_EMAIL = '/onboard/verify-email';
export const ONBOARD_SWITCH_TENANT = '/onboard/switch-tenant';
export const ONBOARD_SELECT_TENANT = '/onboard/select-tenant';

export const getSwitchTenantRoute = (
  tenantId: string,
  redirectUrl?: string,
) => {
  return `${ONBOARD_SWITCH_TENANT}?tenantId=${tenantId}&redirectUrl=${encodeURIComponent(
    redirectUrl || '',
  )}`;
};

// Dashboard
export const DASHBOARD = '/dashboard';
export const DASHBOARD_FINANCE = `${DASHBOARD}/finance`;
export const DASHBOARD_SALES = `${DASHBOARD}/sales`;
export const DASHBOARD_APPROVALS = `${DASHBOARD}/approvals`;
export const DASHBOARD_BILLING = `${DASHBOARD}/billing`;
export const DASHBOARD_CONTRACTS_EDIT_NESTED = `contracts/:contractId/edit`;
export const DASHBOARD_CONTRACTS_EDIT = `${DASHBOARD_SALES}/${DASHBOARD_CONTRACTS_EDIT_NESTED}`;
export const getDashboardContractsEdit = (contractId: string) => {
  return DASHBOARD_CONTRACTS_EDIT.replace(':contractId', contractId);
};
export const DASHBOARD_OPPORTUNITIES_EDIT_NESTED = `opportunities/:opportunityId/edit`;
export const DASHBOARD_OPPORTUNITIES_EDIT = `${DASHBOARD_SALES}/${DASHBOARD_OPPORTUNITIES_EDIT_NESTED}`;
export const getDashboardOpportunitiesEdit = (opportunityId: string) => {
  return DASHBOARD_OPPORTUNITIES_EDIT.replace(':opportunityId', opportunityId);
};

export const ROOT = '/';

// Accounts
export const ACCOUNT_LIST = '/accounts';
export const ACCOUNT_DETAIL = '/accounts/:accountId';
export const ACCOUNT_EDIT_MODAL = '/edit';
export const ACCOUNT_VIEW_MODAL = '/view';
export const getAccountEditModalRoute = (accountId: string) =>
  `/accounts/${accountId}/edit`;
export const getAccountViewModalRoute = (accountId: string) =>
  `/accounts/${accountId}/view`;
export const getAccountDetailRoute = (accountId: string) =>
  `/accounts/${accountId}/overview`;
export const ACCOUNT_OVERVIEW = 'overview';
export const ACCOUNT_CONTACTS = 'contacts';
export const getAccountContactsRoute = (accountId: string) =>
  `/accounts/${accountId}/contacts`;
export const ACCOUNT_CREDITS = 'credits';
export const ACCOUNT_PAYMENT_METHODS = 'payment-methods';
// This route is shown at the root of the application to avoid re-renders
export const ACCOUNT_PAYMENT_METHODS_CALLBACK =
  '/accounts/:accountId/payment-methods/:paymentGatewayId/callback/stripe';
export const ACCOUNT_BILL_GROUPS = 'bill-groups';
export const ACCOUNT_SUBSCRIPTIONS = 'subscriptions';
export const ACCOUNT_USAGE = 'usage';
export const ACCOUNT_PAYMENTS = 'payments';
export const ACCOUNT_INVOICES = 'invoices';
export const ACCOUNT_QUOTES = 'quotes';
export const ACCOUNT_CONTRACTS = 'contracts';
export const getAccountContractsRoute = (accountId: string) =>
  `/accounts/${accountId}/contracts`;
export const ACCOUNT_CONTRACTS_EDIT = '/contracts/:contractId/edit';
export const getAccountContractEditRoute = (
  accountId: string,
  contractId: string,
) => `/accounts/${accountId}/contracts/${contractId}/edit`;

// contacts
export const ACCOUNT_CONTACTS_CREATE = '/contacts/create';
export const ACCOUNT_CONTACTS_EDIT = '/contacts/:contactId/edit';
export const ACCOUNT_CONTACTS_VIEW = '/contacts/:contactId/view';
export const getAccountContactCreate = (accountId: string) =>
  `/accounts/${accountId}/contacts/create`;
export const getAccountContactEdit = (accountId: string, contactId: string) =>
  `/accounts/${accountId}/contacts/${contactId}/edit`;
export const getAccountContactView = (accountId: string, contactId: string) =>
  `/accounts/${accountId}/contacts/${contactId}/view`;

// credits
export const ACCOUNT_CREDITS_CREATE = '/credits/create';
export const ACCOUNT_CREDITS_EDIT = '/credits/:creditId/edit';

export const getAccountCreditsRoute = (accountId: string) =>
  `/accounts/${accountId}/credits`;
export const getAccountCreditCreate = (accountId: string) =>
  `/accounts/${accountId}/credits/create`;
export const getAccountCreditEdit = (accountId: string, creditId: string) =>
  `/accounts/${accountId}/credits/${creditId}/edit`;

// credit & creditNote pdf
export const ACCOUNT_CREDITS_VIEW =
  '/accounts/:accountId/credit/:creditId/view';
export const getAccountCreditsViewRoute = (
  accountId: string,
  creditId: string,
) => `/accounts/${accountId}/credit/${creditId}/view`;

export const ACCOUNT_CREDIT_NOTE_VIEW =
  '/accounts/:accountId/credit-note/:creditNoteId/view';
export const getAccountCreditNoteViewRoute = (
  accountId: string,
  creditNoteId: string,
) => `/accounts/${accountId}/credit-note/${creditNoteId}/view`;

// subscriptions
export const SUBSCRIPTION_NEW = '/accounts/:accountId/subscription/new';
export const SUBSCRIPTION_EDIT =
  '/accounts/:accountId/subscriptions/:subscriptionId/edit';
export const getSubscriptionNewRoute = (accountId: string) =>
  `/accounts/${accountId}/subscription/new`;
export const getSubscriptionEditRoute = (
  accountId: string,
  subscriptionId: string,
) => `/accounts/${accountId}/subscriptions/${subscriptionId}/edit`;
export const getAccountSubscriptionsRoute = (accountId: string) =>
  `/accounts/${accountId}/subscriptions`;
export const SUBSCRIPTION_OVERVIEW =
  '/accounts/:accountId/subscription/:subscriptionId/overview';
export const getSubscriptionOverviewRoute = (
  accountId: string,
  subscriptionId: string,
) => `/accounts/${accountId}/subscription/${subscriptionId}/overview`;
export const SUBSCRIPTION_PRODUCT_USAGE =
  '/accounts/:accountId/subscription/:subscriptionId/usage/:productId';
export const getSubscriptionUsageRoute = (
  accountId: string,
  subscriptionId: string,
  productId: string,
) => `/accounts/${accountId}/subscription/${subscriptionId}/usage/${productId}`;

// usages
export const USAGE_EVENTS =
  '/accounts/:accountId/usage/:subscriptionId/:productId/events';
export const getUsageEventsPageRoute = (
  accountId: string,
  subscriptionId: string,
  usageProductId: string,
) => `/accounts/${accountId}/usage/${subscriptionId}/${usageProductId}/events`;
export const getAccountUsageRoute = (accountId: string) =>
  `/accounts/${accountId}/usage`;

// payment-methods
export const getAccountPaymentMethodStripeCallbackRoute = (
  accountId: string,
  gatewayId: string,
) => `/accounts/${accountId}/payment-methods/${gatewayId}/callback/stripe`;
export const getAccountPaymentMethodRoute = (accountId: string) =>
  `/accounts/${accountId}/payment-methods`;
export const getAccountPaymentMethodCreate = (accountId: string) =>
  `/accounts/${accountId}/payment-methods/create`;
export const getAccountPaymentMethodView = (
  accountId: string,
  paymentMethodId: string,
) => `/accounts/${accountId}/payment-methods/${paymentMethodId}/view`;
export const ACCOUNT_PAYMENT_METHODS_CREATE = '/payment-methods/create';
export const ACCOUNT_PAYMENT_METHODS_VIEW =
  '/payment-methods/:paymentMethodId/view';

export const getAccountPaymentsRoute = (accountId: string) =>
  `/accounts/${accountId}/payments`;
export const getAccountPaymentView = (accountId: string, paymentId: string) =>
  `/accounts/${accountId}/payments/${paymentId}/view`;
export const PAYMENTS_VIEW = `/accounts/:accountId/payments/:paymentId/view`;
export const REFUND_VIEW = `/accounts/:accountId/refunds/:refundId/*`;
export const getRefundView = ({
  accountId,
  refundId,
}: {
  accountId: string;
  refundId: string;
}) => `/accounts/${accountId}/refunds/${refundId}/view`;

export const ACCOUNT_PAYMENT_VIEW = '/payments/:paymentId/view';

// invoices
export const INVOICE_DETAIL = '/invoices/:invoiceId';
export const INVOICE_DETAIL_CONTRACTS_EDIT = `/invoices/:invoiceId/contracts/:contractId/edit`;
export const getInvoiceDetailContractsEdit = (contractId: string) => {
  return `contracts/${contractId}/edit`;
};
export const getInvoiceDetailRoute = (invoiceId: string) =>
  `/invoices/${invoiceId}`;
export const getAccountInvoicesRoute = (accountId: string) =>
  `/accounts/${accountId}/invoices`;
// Payment Gateways
export const PAYMENT_GATEWAY_LIST = '/payment-gateways';
export const PAYMENT_GATEWAY_STRIPE_SUCCESS =
  '/payment-gateways/stripe/success/';

// one-time invoices
export const ONE_TIME_INVOICE_CREATE =
  '/accounts/:accountId/one-time-invoice/new';
export const getOneTimeInvoiceCreateRoute = (
  accountId: string,
  {
    billGroupId,
    currency,
    legalEntityId,
    addressSource,
    billingContactId,
    shippingContactId,
    billingAddressId,
    shippingAddressId,
    netTerms,
    purchaseOrderNumber,
  }: {
    billGroupId: string;
    currency?: string;
    legalEntityId?: string;
    addressSource: AddressSourceEnum;
    billingContactId: string;
    shippingContactId: string;
    billingAddressId?: string;
    shippingAddressId?: string;
    netTerms?: NetTermsEnum;
    purchaseOrderNumber?: Maybe<string>;
  },
) => {
  const searchParams = new URLSearchParams({
    billGroupId,
    currency: currency || 'USD',
    addressSource: addressSource,
    billingContactId: billingContactId,
    shippingContactId: shippingContactId,
    netTerms: netTerms || NetTermsEnum.NET_30,
  });
  if (legalEntityId) {
    searchParams.append('legalEntityId', legalEntityId);
  }
  if (billingAddressId) {
    searchParams.append('billingAddressId', billingAddressId);
  }
  if (shippingAddressId) {
    searchParams.append('shippingAddressId', shippingAddressId);
  }
  if (purchaseOrderNumber) {
    searchParams.append('purchaseOrderNumber', purchaseOrderNumber);
  }
  return `/accounts/${accountId}/one-time-invoice/new?${searchParams.toString()}`;
};

// Quotes
/** Not actual route, but used to determine if user is on a quote page */
export const QUOTE_BASE_PATH = '/quotes/';

export const QUOTE_NEW = '/quotes/new';
export const QUOTE_NEW_GUIDED = '/quotes/new/guided';
export const ACCOUNT_QUOTE_NEW = '/quotes/new/accounts/:accountId';
/** Will redirect to view or edit based on state of quote */
export const QUOTE_BASE = '/quotes/:quoteId';
export const QUOTE_EDIT = '/quotes/:quoteId/edit';
export const QUOTE_REVIEW = '/quotes/:quoteId/review';
export const QUOTE_CONTRACT = '/quotes/:quoteId/review/:contractId';
export const getAccountQuotesRoute = (accountId: string) =>
  `/accounts/${accountId}/quotes`;
/** The base route redirects to view or edit based on state of quote */
export const getQuoteBaseRoute = (quoteId: string) => `/quotes/${quoteId}`;
export const getQuoteEditRoute = (quoteId: string) => `/quotes/${quoteId}/edit`;
export const getQuoteReviewRoute = (quoteId: string) =>
  `/quotes/${quoteId}/review`;
export const getAccountQuoteNewRoute = (accountId: string) =>
  `/quotes/new/accounts/${accountId}`;
export const getQuoteContractEditRoute = (
  quoteId: string,
  contractId: string,
) => `/quotes/${quoteId}/review/${contractId}`;

export const ACCOUNT_BILL_GROUP_NEW = 'bill-groups/new';
export const getBillGroupNewRoute = (accountId: string) =>
  `/accounts/${accountId}/bill-groups/new`;
// used for nested account overview route
export const getAccountOverviewBillGroupNewRoute = (accountId: string) =>
  `/accounts/${accountId}/overview/bill-groups/new`;

export const getAccountBillGroupsRoute = (accountId: string) =>
  `/accounts/${accountId}/bill-groups`;

export const getAccountDetailsRouteWithTabName = (
  accountId: string,
  tabName: AccountTabTypes = 'overview',
) => `/accounts/${accountId}/${tabName}`;

export const ACCOUNT_BILL_GROUP_EDIT = 'bill-groups/:billGroupId/edit';
// used for nested account overview route
export const getAccountOverviewBillGroupEditRoute = (
  accountId: string,
  billGroupId: string,
) => `/accounts/${accountId}/overview/bill-groups/${billGroupId}/edit`;

export const ACCOUNT_BILL_GROUP_VIEW = 'bill-groups/:billGroupId/view';
// used for nested account overview route
export const getAccountOverviewBillGroupViewRoute = (
  accountId: string,
  billGroupId: string,
) => `/accounts/${accountId}/overview/bill-groups/${billGroupId}/view`;

// Bill Group Page
export const BILL_GROUP_PAGE = '/bill-groups/:billGroupId';
export const BILL_GROUP_DETAILS = 'details';
export const BILL_GROUP_BILLING_SCHEDULE = 'billing-schedule';
export const BILL_GROUP_CONTRACTS = 'contracts';
export const BILL_GROUP_INVOICES = 'invoices';
export const BILL_GROUP_SUBSCRIPTIONS = 'subscriptions';
export const BILL_GROUP_USAGE = 'usage';
export const BILL_GROUP_PAYMENTS = 'payments';
export const PAYMENT_VIEW = ':paymentId/view';
export const BILL_GROUP_CREDITS = 'credits';
export const CREDITS_CREATE = 'create';
export const CREDITS_EDIT = ':creditId/edit';
export const CREDITS_VIEW = ':creditId/view';
export const BILL_GROUP_QUOTES = 'quotes';

export const getBillGroupPageRoute = (
  billGroupId: string,
  tabName: BillGroupTabTypes = 'details',
) => `/bill-groups/${billGroupId}/${tabName}`;

export const getBillGroupCreditsCreateRoute = (billGroupId: string) =>
  `/bill-groups/${billGroupId}/credits/create`;

export const getBillGroupCreditsEditRoute = (
  billGroupId: string,
  creditId: string,
) => `/bill-groups/${billGroupId}/credits/${creditId}/edit`;

export const getBillGroupCreditsViewRoute = (
  billGroupId: string,
  creditId: string,
) => `/bill-groups/${billGroupId}/credits/${creditId}/view`;

export const getBillGroupPaymentViewRoute = (
  billGroupId: string,
  paymentId: string,
) => `/bill-groups/${billGroupId}/payments/${paymentId}/view`;

export const getBillGroupContractsEditRoute = (
  billGroupId: string,
  contractId: string,
) => `/bill-groups/${billGroupId}/contracts/${contractId}/edit`;

export const getBillingScheduleContractsEditRoute = (
  billGroupId: string,
  contractId: string,
) =>
  `/bill-groups/${billGroupId}/${BILL_GROUP_BILLING_SCHEDULE}/${contractId}/edit`;

// Product Catalog
// export const PRODUCT_CATALOG_LIST = '/product-catalogs';

// Sales
export const SALES_LANDING = '/sales';
export const SALES_OPPORTUNITIES = 'opportunities';
export const SALES_QUOTES = 'quotes';
export const SALES_CONTRACTS = 'contracts';
export const SALES_QUOTES_ROUTE = `${SALES_LANDING}/${SALES_QUOTES}`;
export const SALES_CONTRACTS_ROUTE = `${SALES_LANDING}/${SALES_CONTRACTS}`;
export const getSalesContractEditRoute = (contractId: string) =>
  `/sales/contracts/${contractId}/edit`;
export const getSalesOpportunityEditRoute = (opportunityId: string) =>
  `/sales/opportunities/${opportunityId}/edit`;

// Contracts
export const CONTRACT_EDIT = ':contractId/edit';

// Opportunities
export const OPPORTUNITY_EDIT = ':opportunityId/edit';

// Products
export const PRODUCT_LIST = '/products';
export const PRODUCT_NEW = '/products/new';
export const PRODUCT_EDIT = '/products/:productId/edit';
export const getProductEditRoute = (productId: string) =>
  `/products/${productId}/edit`;

// Offerings
export const OFFERING_LIST = '/offerings';
export const OFFERING_NEW = '/offerings/new';
export const OFFERING_EDIT = '/offerings/:offeringId/edit';
export const getOfferingEditRoute = (offeringId: string) =>
  `/offerings/${offeringId}/edit`;
export const getOfferingCreateRoute = (copyOfferingId?: string) =>
  `${OFFERING_NEW}?copyOfferingId=${copyOfferingId}`;

// OneTime
export const ONETIME_LIST = '/one-times';
export const ONETIME_NEW = '/one-times/new';
export const ONETIME_EDIT = '/one-times/:oneTimeId/edit';
export const getOneTimeEditRoute = (oneTimeId: string) =>
  `/one-times/${oneTimeId}/edit`;

// Discounts
export const DISCOUNT_LIST = '/discounts';
export const DISCOUNT_NEW = '/discounts/new';
export const DISCOUNT_EDIT = '/discounts/:discountId/edit';
export const getDiscountEditRoute = (discountId: string) =>
  `/discounts/${discountId}/edit`;

// Settings
export const SETTINGS = '/settings';
export const COMPANY_SETTINGS = '/settings/company';
export const ONBOARDING = '/settings/onboarding';

export const CUSTOM_FIELDS_LIST = '/settings/custom-fields';
export const CUSTOM_FIELDS_NEW = '/settings/custom-fields/new';
export const CUSTOM_FIELDS_EDIT = '/settings/custom-fields/:entity/:key/edit';
export const getCustomFieldEditRoute = (entity: string, key: string) =>
  `/settings/custom-fields/${entity}/${key}/edit`;

export const API_KEYS = '/settings/api-keys';

export const LEGAL_ENTITY_NEW = '/settings/company/new';
export const LEGAL_ENTITY_EDIT = '/settings/company/:legalEntityId/edit';
export const getLegalEntityEditRoute = (legalEntityId: string) =>
  `/settings/company/${legalEntityId}/edit`;

export const SETTINGS_PAYMENT_PROCESSOR = '/settings/payment-processor';
export const SETTINGS_TAX = '/settings/tax';

export const SETTINGS_REV_REC = '/settings/revenueRecognition';

export const SETTINGS_BILLING_SETTINGS = '/settings/billingSettings';

export const SETTINGS_DUNNING_LIST = '/settings/dunnings';
export const SETTINGS_DUNNING_NEW = '/settings/dunnings/new';
export const SETTINGS_DUNNING_EDIT = '/settings/dunnings/:dunningId/edit';
export const getDunningEditRoute = (dunningId: string) =>
  `/settings/dunnings/${dunningId}/edit`;

export const SETTINGS_SUBSCRIPTIONS = '/settings/subscriptions';
export const SETTINGS_PRICING = '/settings/pricing';
export const SETTINGS_PAYMENTS = '/settings/payments';
export const SETTINGS_INVOICES = '/settings/invoices';
export const SETTINGS_INVOICE_TEMPLATES = '/settings/invoice/templates';

export const SETTINGS_CRM = '/settings/crm';
export const SETTINGS_CRM_CALLBACK = '/settings/crm/callback';

export const SETTINGS_ESIGN = '/settings/esign';
export const SETTINGS_ESIGN_CALLBACK = '/settings/esign/callback';

export const SETTINGS_WEBHOOK_LIST = '/settings/webhooks';
export const SETTINGS_WEBHOOK_NEW = '/settings/webhooks/new';
export const SETTINGS_WEBHOOK_EDIT = '/settings/webhooks/:webhookId/edit';
export const SETTINGS_WEBHOOK_VIEW = '/settings/webhooks/:webhookId/view';
export const getWebhookEditRoute = (webhookId: string) =>
  `/settings/webhooks/${webhookId}/edit`;
export const getWebhookViewRoute = (webhookId: string) =>
  `/settings/webhooks/${webhookId}/view`;

export const SETTINGS_NOTIFICATIONS = '/settings/notifications';
export const SETTINGS_CURRENCIES = '/settings/currencies';
export const SETTINGS_DOMAINS = '/settings/domains';
export const SETTINGS_QUOTE_TEMPLATES = '/settings/quote/templates';

export const SETTINGS_ACCOUNTING = '/settings/accounting';
export const SETTINGS_INTEGRATION_LOG = '/settings/integration-log';
export const SETTINGS_INTEGRATION_LOG_VIEW =
  '/settings/integration-log/view/:sourceRecordId';
export const getIntegrationLogViewRoute = (sourceRecordId: string) =>
  `/settings/integration-log/view/${sourceRecordId}`;

// Tenants
export const TENANT_LIST = '/tenants';
export const TENANT_DETAIL = '/tenants/:tenantId';
// Add users is nested under settings
export const TENANT_DETAIL_USERS_LIST = '/settings/tenant/users';

// Usage Types
export const USAGE_TYPE_LIST = '/usage-types';
export const USAGE_TYPE_NEW = '/usage-types/new';
export const USAGE_TYPE_EDIT = '/usage-types/:usageId/edit';
export const getUsageTypeEditRoute = (usageId: string) =>
  `/usage-types/${usageId}/edit`;

// Quote Settings
export const SETTINGS_QUOTE_SETTINGS = '/settings/quote-settings';

// Guided Quoting
export const SETTINGS_GUIDED_QUOTING = '/settings/guided-quoting';
export const SETTINGS_GUIDED_QUOTING_CREATE = '/settings/guided-quoting/new';
export const SETTINGS_GUIDED_QUOTING_EDIT =
  '/settings/guided-quoting/:guidedQuotingId/edit';

// teams
export const SETTINGS_TEAMS = '/settings/teams';
export const SETTINGS_TEAMS_NEW = '/settings/teams/new';
export const SETTINGS_TEAMS_EDIT = '/settings/teams/:teamId/edit';
export const SETTINGS_TEAMS_DETAILS = '/settings/teams/:teamId';
export const getTeamsDetailsRoute = (teamId: string) =>
  `/settings/teams/${teamId}`;
export const getTeamEditRoute = (teamId: string) =>
  `/settings/teams/${teamId}/edit`;

// Quote Reminder Emails
export const SETTINGS_EMAILS = '/settings/emails';

// Profile
export const PROFILE = '/profile';

//
export const ACCESS_DENIED = '/access-denied';
export const ERROR_PAGE = '/error-page';
export const REDIRECT = '/redirect';
export const UNAUTHORIZED_PAGE = '/unauthorized';
// CRM SPECIFIC ROUTES
export const CRM_AMEND_RENEW = '/crm/contracts/:contractId/:action';

// Billing
export const BILLING = '/billing';
export const BILLING_BILL_RUN = `${BILLING}/bill-run`;
export const BILLING_INVOICe_MANAGEMENT = `${BILLING}/invoice-management`;

// Reporting
export const REPORTING = '/reporting';

// Revenue
export const REVENUE = '/revenue';

// Invoice share - ****ANONYMOUS ROUTES****
export const INVOICE_SHARE = '/share/:tenantId/invoices/:invoiceSecretId';
export const getInvoiceShareRoute = (
  tenantId: string,
  invoiceSecretId: string,
) => `/share/${tenantId}/invoices/${invoiceSecretId}`;
export const INVOICE_SHARE_STRIPE_PAYMENT_CALLBACK =
  '/share/:tenantId/invoices/:invoiceSecretId/stripe/callback';
export const getInvoiceShareStripePaymentCallbackRoute = (
  tenantId: string,
  invoiceSecretId: string,
) => `/share/${tenantId}/invoices/${invoiceSecretId}/stripe/callback`;

// Quote share - ****ANONYMOUS ROUTES****
export const QUOTE_SHARE = '/share/:tenantId/quotes/:recordId';
export const getQuoteShareRoute = (
  tenantId: string,
  recordId: string,
  secret: string,
  email: string,
) => `/share/${tenantId}/quotes/${recordId}?token=${secret}&email=${email}`;
export const QUOTE_SHARE_STRIPE_PAYMENT_CALLBACK =
  '/share/:tenantId/quotes/:recordId/stripe/callback';
export const getQuoteShareStripePaymentCallbackRoute = (
  tenantId: string,
  recordId: string,
  secret: string,
) => `/share/${tenantId}/quotes/${recordId}/stripe/callback?token=${secret}`;

// RULES ROUTES
export const SETTINGS_APPROVALS_V3 = '/settings/approvals-v3';
export const SETTINGS_APPROVALS_V3_CREATE = '/settings/approvals-v3/new';
export const SETTINGS_APPROVALS_V3_EDIT = '/settings/approvals-v3/:ruleId/edit';

export const SETTINGS_CONDITIONAL_TERMS_V3 = '/settings/conditional-terms-v3';
export const SETTINGS_CONDITIONAL_TERMS_V3_CREATE =
  '/settings/conditional-terms-v3/new';
export const SETTINGS_CONDITIONAL_TERMS_V3_EDIT =
  '/settings/conditional-terms-v3/:ruleId/edit';

export const SETTINGS_VALIDATIONS_V3 = '/settings/validations-v3';
export const SETTINGS_VALIDATIONS_V3_CREATE = '/settings/validations-v3/new';
export const SETTINGS_VALIDATIONS_V3_EDIT =
  '/settings/validations-v3/:ruleId/edit';

export const getRuleListRoute = (
  type: 'approvals' | 'conditional-terms' | 'validations',
) => `/settings/${type}-v3`;

export const getRuleCreateRoute = (
  type: 'approvals' | 'conditional-terms' | 'validations',
) => `/settings/${type}-v3/new`;

export const getRuleEditRoute = (
  id: string,
  type: 'approvals' | 'conditional-terms' | 'validations',
) => `/settings/${type}-v3/${id}/edit`;

export const getRuleEditRouteV3Wrapper =
  (type: 'approvals' | 'conditional-terms' | 'validations') => (id: string) =>
    getRuleEditRoute(id, type);

// DealRoom routes
export const DEAL_ROOM = '/dealroom';
export const getDealRoomByOpportunityId = (oppId: string) => {
  return `/dealroom/${oppId}`;
};

export const SETTINGS_DEAL_ROOM = '/settings/deal-room';

// Guided Quoting
export const getGuidedQuotingRoute = (guidedQuotingId: string) =>
  `/settings/guided-quoting/${guidedQuotingId}/edit`;

//refund

export const REFUND_DETAILS_VIEW = `/view`;
