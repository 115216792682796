import { arrayToObject } from '@monetize/utils/core';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { useGetContracts } from '../../api/cpqService';
import { useGetListData } from '../../api/queryUtils';
import {
  ApiListResponse,
  BillGroupRespSchema,
  ContractRespSchema,
  DEFAULT_PAGER,
  FilterType,
  GetListApiFilter,
  IBillGroupResp,
  TDataTablePager,
} from '../../types';
import { buildFilterParamsRequestObject } from '../../utils';

const ContractWithBillGroup = ContractRespSchema.extend({
  billGroup: BillGroupRespSchema.nullish(),
});

export type IContractWithBillGroup = z.infer<typeof ContractWithBillGroup>;

export const useContractsData = (accountId: string, billGroupId?: string) => {
  const [filters, setFilters] = useState<FilterType[]>([]);
  const [searchKey] = useState<string>('description');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pager, setPager] = useState<TDataTablePager>(DEFAULT_PAGER);
  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(filters, searchTerm, searchKey),
  );

  const [contractsWithBillGroup, setContractsWithBillGroup] =
    useState<ApiListResponse<IContractWithBillGroup>>();

  const {
    isLoading: isBillGroupResLoading,
    isFetching: isBillGroupResFetching,
    data: billGroupsById,
  } = useGetListData<IBillGroupResp, Record<string, IBillGroupResp>>(
    'billGroups',
    { config: { first: 50, page: 0 } },
    {
      endpointArgs: { accountId },
      select: (data) => arrayToObject(data?.content || [], 'id'),
    },
  );

  useEffect(() => {
    if (filters || searchTerm || pager) {
      setFilterParams(
        buildFilterParamsRequestObject(filters, searchTerm, searchKey),
      );
    }
  }, [filters, searchTerm, pager, searchKey]);

  const {
    isLoading,
    isFetched,
    isRefetching,
    data: listData,
  } = useGetContracts(
    {
      config: pager,
      filters: billGroupId ? { ...filterParams, billGroupId } : filterParams,
      url: `/api/accounts/${accountId}/contracts`,
    },
    { meta: { showErrorToast: true } },
  );

  useEffect(() => {
    if (listData && billGroupsById) {
      setContractsWithBillGroup(() => {
        return {
          ...listData,
          content: listData.content.map((contract) => {
            return {
              ...contract,
              billGroup: contract.billGroupId
                ? billGroupsById[contract.billGroupId]
                : undefined,
            };
          }),
        };
      });
    }
  }, [listData, billGroupsById]);

  const onResetFilter = () => {
    setFilters([]);
  };

  return {
    pager,
    setPager,
    filters,
    setFilters,
    onResetFilter,
    searchKey,
    searchTerm,
    setSearchTerm,
    totalRecords: listData?.totalElements || 0,
    listData: contractsWithBillGroup,
    loading:
      (isLoading && isBillGroupResLoading) ||
      (isRefetching && !isFetched) ||
      (isBillGroupResFetching && isBillGroupResLoading),
  };
};
