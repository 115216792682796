import {
  IContactRespSchema,
  IPaymentMethodContactSchema,
  QuoteShareResponse,
} from '../../types';
import {
  QuoteShareContactRequiredAddress,
  QuoteShareForm,
} from '../../types/recordShareTypes';

function getFormContact(
  contact?: IContactRespSchema,
): QuoteShareContactRequiredAddress {
  return {
    id: contact?.id,
    fullName: contact?.fullName || '',
    email: contact?.email || '',
    phone: contact?.phone || '',
    title: contact?.title || '',
    address: {
      line1: contact?.address?.line1 || '',
      line2: contact?.address?.line2 || '',
      city: contact?.address?.city || '',
      state: contact?.address?.state || '',
      postalCode: contact?.address?.postalCode || '',
      country: contact?.address?.country || '',
    },
  };
}

function getPaymentMethodBillingDetails(
  contact?: IContactRespSchema,
): IPaymentMethodContactSchema {
  return {
    fullName: contact?.fullName || '',
    email: contact?.email || '',
    phone: contact?.phone || '',
    title: contact?.title || '',
    line1: contact?.address?.line1 || '',
    line2: contact?.address?.line2 || '',
    city: contact?.address?.city || '',
    state: contact?.address?.state || '',
    postalCode: contact?.address?.postalCode || '',
    country: contact?.address?.country || '',
  };
}

export function getFormFromResponse(
  requireAddress: boolean,
  requirePaymentMethod: boolean,
  quoteData?: QuoteShareResponse,
): QuoteShareForm {
  return {
    config: {
      requireAddress: !!requireAddress,
      requirePaymentMethod:
        !!requirePaymentMethod && !!quoteData?.paymentGatewayId,
      allowPoNumber: !!quoteData?.allowPoNumber,
    },
    purchaseOrderNumber: quoteData?.quote?.purchaseOrderNumber || '',
    contacts: {
      requireAddress: !!requireAddress,
      billingContact: getFormContact(quoteData?.quote.billingContact),
      shippingContact: getFormContact(quoteData?.quote.shippingContact),
      useBillingForShippingContact:
        quoteData?.quote.billingContact.id ===
        quoteData?.quote.shippingContact.id,
    },
    payment:
      requirePaymentMethod && !!quoteData?.paymentGatewayId
        ? {
            requirePaymentMethod: true,
            paymentGatewayId: quoteData.paymentGatewayId,
            paymentMethodType:
              quoteData.paymentMethods.length > 0 ? 'existing' : 'new',
            paymentMethodId:
              quoteData.paymentMethods.find((item) => item.isDefault)?.id ??
              quoteData.paymentMethods[0]?.id,
            paymentMethod: {
              amount: 0,
              paymentMethodName: '',
              billingDetails: getPaymentMethodBillingDetails(
                quoteData?.quote.billingContact,
              ),
            },
          }
        : {
            requirePaymentMethod: false,
            paymentGatewayId: null,
            paymentMethodType: 'new',
            paymentMethod: null,
            paymentMethodId: null,
          },
  };
}
