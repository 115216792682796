import { CardBody, useDisclosure } from '@chakra-ui/react';
import { CurrencyIcon } from '@monetize/ui/icons';
import { FunctionComponent as FC, useEffect, useRef, useState } from 'react';
import { useOfferingRate } from '../../../hooks';
import { useConfirmModal } from '../../../services/confirmModal';
import {
  IAccount,
  IAccountRateCreateReqSchema,
  IAccountRateUpdateReqSchema,
  IOrderedObj,
  IPriceUnderProductSchemaUI,
  IQuoteItemRespSchema,
  IQuoteOfferingRespSchema,
  IRateResSchema,
  Maybe,
  OfferingTypesEnum,
} from '../../../types';
import {
  CONFIRM_CLOSE_UNSAVED_DESC,
  CONFIRM_CLOSE_UNSAVED_TITLE,
} from '../../../utils/messages';
import { arrayToObjectOrdered } from '../../../utils/misc';
import {
  MBox,
  MButton,
  MCard,
  MDivider,
  MDrawer,
  MDrawerBody,
  MDrawerCloseButton,
  MDrawerContent,
  MDrawerFooter,
  MDrawerHeader,
  MDrawerOverlay,
  MFlex,
  MPageLoader,
  MText,
} from '../../Monetize';
import { PricesDrawerBodyEdit } from './PricesDrawerBodyEdit';
import { PricesDrawerBodyView } from './PricesDrawerBodyView';

interface PricesDrawerProps {
  account?: IAccount | Pick<IAccount, 'id' | 'accountName'>;
  // either (quoteOffering, offeringRate, orderedPricesUnderProduct) or (offeringId, rateId) should be passed in together
  quoteOffering?: IQuoteOfferingRespSchema | null;
  offeringRate?: IRateResSchema | null;
  providedOfferingRate?: IRateResSchema | null;
  orderedPricesUnderProduct?: IPriceUnderProductSchemaUI[];
  offeringType: OfferingTypesEnum;
  // These can be provided instead of the above optional properties and above data will be auto-fetched
  offeringId?: string;
  rateId?: Maybe<string>;
  allowPriceCustomization?: boolean;
  disabled?: boolean;
  isOfferingOpen?: boolean;
  onRateSaved?: (rate: IRateResSchema) => void;
  setOfferingRate?: (val: IRateResSchema | null) => void;
  setOrderedPricesUnderProduct?: (val: IPriceUnderProductSchemaUI[]) => void;
  onDrawerToggle?: (isOpen: boolean) => void;
}

export const PricesDrawer: FC<PricesDrawerProps> = ({
  account,
  quoteOffering,
  offeringId = undefined,
  rateId = null,
  orderedPricesUnderProduct,
  offeringRate: providedOfferingRate,
  allowPriceCustomization,
  disabled,
  isOfferingOpen,
  offeringType,
  onRateSaved,
  setOfferingRate,
  setOrderedPricesUnderProduct,
  onDrawerToggle,
  ...rest
}: PricesDrawerProps) => {
  const xhrController = useRef(new AbortController());
  const pricesBtnRef = useRef<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [productsObj, setProductsObj] = useState<
    IOrderedObj<IQuoteItemRespSchema>
  >({
    byId: {},
    orderedIds: [],
  });
  const [isEditMode, setIsEditMode] = useState(false);

  const {
    loading: loadingInitialData,
    offeringRate: offeringRateData,
    orderedPricesUnderProduct: orderedPricesUnderProductData,
    fetchOfferingRate,
    createOfferingRate,
    updateOfferingRate,
  } = useOfferingRate(offeringId);
  const { showConfirmModal, closeModal: closeConfirmationModal } =
    useConfirmModal();

  const offeringRate = providedOfferingRate || offeringRateData;

  const offeringName = offeringRate?.offering?.name || '';

  const productsWithPrices: IPriceUnderProductSchemaUI[] =
    orderedPricesUnderProduct || orderedPricesUnderProductData;

  // Since we have one api and separate components to update RateName and Prices
  // the api request might not send the latest update when we try to update
  // both the RateName and Prices at the same time with the single api for that
  // reason we are keeping track of latest changes with latestPayload.
  const [latestPayload, setLatestPayload] = useState<
    IAccountRateCreateReqSchema | IAccountRateUpdateReqSchema
  >();

  useEffect(() => {
    const { current } = xhrController;
    return () => {
      current?.abort();
    };
  }, []);

  useEffect(() => {
    if (quoteOffering && quoteOffering?.items?.length > 0) {
      setProductsObj(arrayToObjectOrdered(quoteOffering.items, 'productId'));
    }
  }, [quoteOffering]);

  useEffect(() => {
    if (rateId && !loadingInitialData && !providedOfferingRate) {
      fetchOfferingRate(rateId);
    }
  }, [rateId, providedOfferingRate]);

  useEffect(() => {
    onDrawerToggle?.(isOpen);
  }, [isOpen]);

  async function handleClose(skipEditWarning = false, submitting = false) {
    if (!skipEditWarning && isEditMode) {
      showConfirmModal({
        title: CONFIRM_CLOSE_UNSAVED_TITLE,
        description: CONFIRM_CLOSE_UNSAVED_DESC,
        onYes: () => {
          closeConfirmationModal();
          setIsEditMode(false);
          onClose();
        },
        yesBtnProps: {
          variant: 'primary',
        },
        noBtnProps: {
          variant: 'cancel',
        },
      });
    } else {
      setIsEditMode(false);

      if (!submitting) {
        onClose();
      }
    }
  }

  function cancelSaveCustomRates() {
    xhrController.current?.abort();
  }

  return (
    <MBox position="relative" {...rest}>
      <MButton
        leftIcon={<CurrencyIcon />}
        iconSpacing={isOfferingOpen ? 1 : 0}
        ref={pricesBtnRef}
        onClick={onOpen}
        variant="tertiary"
        size="sm"
        isDisabled={disabled}
        data-testid="prices-btn"
      >
        {!!isOfferingOpen && 'Prices'}
      </MButton>

      <MDrawer
        isOpen={isOpen}
        size="lg"
        placement="right"
        onClose={handleClose}
        finalFocusRef={pricesBtnRef}
        blockScrollOnMount={false}
      >
        <MDrawerOverlay />
        <MDrawerContent>
          <MDrawerCloseButton />
          <MDrawerHeader
            fontSize="md"
            borderBottomColor="tGray.back"
            borderBottomWidth="1px"
            borderBottomStyle="solid"
          >
            {offeringName}
          </MDrawerHeader>

          <MDrawerBody px="2" backgroundColor="tGray.drawerSidebar">
            {loadingInitialData && !offeringRate && (
              <MFlex justify="center" grow={1}>
                <MPageLoader />
              </MFlex>
            )}
            <MCard variant="borderless" mb="2">
              <CardBody>
                <MText>
                  You can change the prices of these products. Any changes will
                  only affect this Quote.
                </MText>
              </CardBody>
            </MCard>
            {!allowPriceCustomization && (
              <PricesDrawerBodyView
                offeringType={offeringType}
                currency={offeringRate?.currency || ''}
                rateName={offeringRate?.name || ''}
                billingFrequency={offeringRate?.billingFrequency}
                billingFrequencyInMonths={
                  offeringRate?.billingFrequencyInMonths
                }
                productsWithPrices={productsWithPrices}
                productsObj={productsObj}
              />
            )}
            {allowPriceCustomization &&
              offeringRate &&
              providedOfferingRate &&
              account && (
                <MBox>
                  {/* Allow editing rates if not custom or if it is custom is is not locked */}
                  <PricesDrawerBodyEdit
                    account={account}
                    offeringRate={offeringRate}
                    providedOfferingRate={providedOfferingRate}
                    productsWithPrices={productsWithPrices}
                    productsObj={productsObj}
                    latestPayload={latestPayload}
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    setLatestPayload={setLatestPayload}
                    onRateSaved={onRateSaved}
                    createOfferingRate={createOfferingRate}
                    updateOfferingRate={updateOfferingRate}
                    setOfferingRate={setOfferingRate}
                    setOrderedPricesUnderProduct={setOrderedPricesUnderProduct}
                    cancelSaveCustomRates={cancelSaveCustomRates}
                    handleClose={handleClose}
                    offeringType={offeringType}
                    selectedProductsWithPrices={productsWithPrices}
                  />
                </MBox>
              )}
          </MDrawerBody>

          <MDivider />

          <MDrawerFooter>
            <MBox w="full">
              <MFlex justifyContent="center">
                <MButton
                  type="button"
                  variant="cancel"
                  onClick={() => {
                    handleClose(false, false);
                    cancelSaveCustomRates();
                  }}
                >
                  Close
                </MButton>
              </MFlex>
            </MBox>
          </MDrawerFooter>
        </MDrawerContent>
      </MDrawer>
    </MBox>
  );
};
