import React from 'react';
import { useGetListData } from '../../api/queryUtils';
import {
  ILegalEntitiesSelector,
  selectLegalEntities,
} from '../../api/selectors';
import { DEFAULT_PAGER } from '../../types';
import { ILegalEntityResponseSchema } from '../../types/legalEntityTypes';
import { MCustomSelectProps } from '../../types/mCustomSelectTypes';
import { MCustomSelect } from './MCustomSelect/MCustomSelect';

interface MLegalEntitySelectProps
  extends Omit<
    MCustomSelectProps,
    | 'items'
    | 'itemTitle'
    | 'itemValue'
    | 'loading'
    | 'defaultValue'
    | 'showQueryInput'
  > {}

export const MLegalEntitySelect = React.forwardRef<
  any,
  MLegalEntitySelectProps
>((props, ref) => {
  const { data: legalEntityData, isLoading } = useGetListData<
    ILegalEntityResponseSchema,
    ILegalEntitiesSelector
  >(
    'legalEntities',
    { config: DEFAULT_PAGER },
    {
      select: selectLegalEntities,
      isGetAll: true,
    },
  );

  return (
    <MCustomSelect
      placeholder="Legal Entity"
      ref={ref}
      itemTitle="name"
      itemValue="id"
      items={legalEntityData?.legalEntities}
      loading={isLoading}
      defaultValue={legalEntityData?.defaultLegalEntity?.id}
      showQueryInput
      {...props}
    />
  );
});
