import { arrayToObject, objectToObjArray } from "@monetize/utils/core";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import { useGetQuoteList } from "../../api/cpqService";
import { useGetListData } from "../../api/queryUtils";
import { AccountIdTableFilterOptionContent, AmountTableFilterOptionContent, DateGlteTableFilterOptionContent } from "../../components/Monetize";
import { CurrencyTableFilterOptionContent } from "../../components/Monetize/DataTable/FilterOptions/CurrencyTableFilterOptionContent";
import { OwnerTableFilterOptionContent } from "../../components/Monetize/DataTable/FilterOptions/OwnerTableFilterOptionContent";
import { ToggleFilterOptionContent } from "../../components/Monetize/DataTable/FilterOptions/ToggleFilterOptionContent";
import MEmptyDataPlaceholder from "../../components/Monetize/MEmptyDataPlaceholder";
import { ROUTES } from "../../constants";
import { QuoteStatusEnumDisplay, QuoteTypeEnumDisplay } from "../../constants/quotes";
import { QuoteActions } from '../../routes/Quotes/Quote/QuoteActions';
import { useACL } from "../../services/acl/acl";
import { useDocumentHead } from "../../services/documentHead";
import { useFlags } from "../../services/launchDarkly";
import { tableFilterSelector, tablePagerSelector } from "../../store/global.store";
import { FilterStateKeys } from "../../store/store.types";
import { ApiListResponse, CollaborationAccessEnum, FilterOptionType, FilterType, FilterTypeOperator, GetListApiFilter, IBillGroupResp, IQuoteRespSchema, IQuoteWithBillGroup, QuoteStatusEnum, RenderTableFilterOptionProps, TDataTablePager } from "../../types";
import { buildFilterParamsRequestObject, transformTableFilterValue } from "../../utils";
import { editQuote, openQuote, reviewQuote } from "../../utils/quotes";

type FilterKey =
  | FilterStateKeys.QUOTE_LIST
  | FilterStateKeys.QUOTE_DASHBOARD_LIST;

/**
 * Get table data for quotes
 * @param accountId If provided, quotes for the specific account will be displayed instead of all quotes
 * @param enabled Defaults to true, passed to the underlying useQuery hook
 * @returns
 */
export const useQuotesTableData = ({
  accountId,
  billGroupId,
  enabled = true,
  filterKey = FilterStateKeys.QUOTE_LIST,
}: {
  accountId?: string;
  billGroupId?: string;
  enabled?: boolean;
  filterKey?: FilterKey;
} = {}) => {
  const navigate = useNavigate();
  const { canDo } = useACL();
  const canReadQuoteOwner = canDo([['quote_owner', 'read']]);
  // Account scoped quotes filters are not stored in sessionStorage
  const persistFilters = !accountId && enabled;
  const [initialFilters, persistTableFilter] = useRecoilState(
    tableFilterSelector(filterKey),
  );

  const [pager, setPager] = useRecoilState(tablePagerSelector(filterKey));

  const { guidedSelling, guidedSellingViaRulesService } = useFlags();

  const [filters, setFilters] = useState<FilterType[]>(
    persistFilters ? initialFilters : [],
  );
  const [createdByFilter, setCreatedByFilter] = useState<
    { userId?: string; teamId?: string } | undefined
  >(undefined);
  const [searchKey] = useState<string>('description');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() => {
    const newFilterParams = buildFilterParamsRequestObject(
      transformTableFilterValue(filters),
      searchTerm,
      searchKey,
    );

    if (createdByFilter) {
      if (createdByFilter.userId) {
        Object.assign(newFilterParams, { createdBy: createdByFilter.userId });
      }

      if (createdByFilter.teamId) {
        Object.assign(newFilterParams, { teamId: createdByFilter.teamId });
      }
    }

    return newFilterParams;
  });

  const [quotesWithBillGroup, setQuotesWithBillGroup] =
    useState<ApiListResponse<IQuoteWithBillGroup>>();

  const presencePager: TDataTablePager = {
    // for determining whether any records exist
    first: 0,
    rows: 1,
    page: 0,
  };

  useEffect(() => {
    persistTableFilter(filters);
  }, [filters, persistTableFilter]);

  const {
    isLoading: isBillGroupResLoading,
    isFetching: isBillGroupResFetching,
    data: billGroupsById,
  } = useGetListData<IBillGroupResp, Record<string, IBillGroupResp>>(
    'billGroups',
    { config: { first: 50, page: 0 } },
    {
      endpointArgs: { accountId },
      enabled: !!accountId,
      select: (data) => {
        return arrayToObject(data?.content || [], 'id');
      },
    },
  );

  const { data: guidedQuotingData } = useGetListData(
    guidedSellingViaRulesService ? 'guidedQuoting_v2' : 'guidedQuoting',
    {
      config: { rows: 1, page: 0 },
      filters: { status: 'ACTIVE' },
    },
    {
      gcTime: 1000 * 60 * 10, // 10 min
      staleTime: 1000 * 60 * 3, // 3 min
    },
  );

  const {
    isLoading,
    isRefetching,
    isFetched,
    refetch,
    data: quoteList,
  } = useGetQuoteList(
    {
      config: pager,
      filters: billGroupId ? { ...filterParams, billGroupId } : filterParams,
      accountId,
    },
    {
      enabled,
    },
  );

  const { data: isOfferingAvailable } = useGetListData(
    'productCatalogOfferings',
    { config: presencePager },
    { select: (value) => value.totalElements > 0 },
  );

  const { data: isProductAvailable } = useGetListData(
    'productCatalogProducts',
    { config: presencePager },
    { select: (value) => value.totalElements > 0 },
  );

  const { setDocTitle } = useDocumentHead();
  useEffect(() => {
    setDocTitle('Quotes');
  }, []);

  useEffect(() => {
    const newFilterParams = buildFilterParamsRequestObject(
      transformTableFilterValue(filters),
      searchTerm,
      searchKey,
    );

    if (createdByFilter) {
      if (createdByFilter.userId) {
        Object.assign(newFilterParams, { createdBy: createdByFilter.userId });
      }

      if (createdByFilter.teamId) {
        Object.assign(newFilterParams, { teamId: createdByFilter.teamId });
      }
    }

    setFilterParams(newFilterParams);

    // if external loader is provided, invoke it
    // otherwise query will be auto-invoked
  }, [pager, searchTerm, filters, searchKey, createdByFilter]);

  useEffect(() => {
    if (quoteList && billGroupsById) {
      setQuotesWithBillGroup(() => {
        return {
          ...quoteList,
          content: quoteList.content.map((quote) => {
            return {
              ...quote,
              billGroup: quote.billGroupId
                ? billGroupsById[quote.billGroupId]
                : undefined,
            };
          }),
        };
      });
    }
  }, [quoteList, billGroupsById]);

  const statusFilterItems = objectToObjArray(
    QuoteStatusEnumDisplay,
    'value',
    'label',
  );
  const typeFilterItems = objectToObjArray(
    QuoteTypeEnumDisplay,
    'value',
    'label',
  );

  let filterOptions: FilterOptionType[] = [
    {
      title: 'Amount',
      key: 'amount',
      operator: FilterTypeOperator.GLTE,
      renderOptionContent: (props) => (
        <AmountTableFilterOptionContent {...props} />
      ),
    },
    {
      title: 'Created',
      key: 'createDate',
      operator: FilterTypeOperator.GLTE,
      renderOptionContent: (props) => (
        <DateGlteTableFilterOptionContent showTimePicker {...props} />
      ),
    },
    {
      title: 'Updated',
      key: 'modifyDate',
      operator: FilterTypeOperator.GLTE,
      renderOptionContent: (props) => (
        <DateGlteTableFilterOptionContent showTimePicker {...props} />
      ),
    },
    {
      title: 'Owner',
      key: 'owner',
      operator: FilterTypeOperator.EQUAL,
      renderOptionContent: OwnerTableFilterOptionContent,
    },
    {
      title: 'Status',
      key: 'status',
      operator: FilterTypeOperator.IN,
      items: statusFilterItems,
    },
    {
      title: 'Type',
      key: 'type',
      operator: FilterTypeOperator.IN,
      items: typeFilterItems,
    },
  ];

  if (!canReadQuoteOwner) {
    filterOptions = filterOptions.filter(({ key }) => key !== 'owner');
  }

  if (!accountId) {
    filterOptions.unshift({
      title: 'Account',
      key: 'accountId',
      operator: FilterTypeOperator.EQUAL,
      renderOptionContent: (props: RenderTableFilterOptionProps) => (
        <AccountIdTableFilterOptionContent {...props} />
      ),
    });

    filterOptions.splice(0, 0, {
      title: '',
      key: 'collaborationAccess',
      operator: FilterTypeOperator.TOGGLE,
      renderOptionContent: (props) => (
        <ToggleFilterOptionContent
          {...props}
          label="Show quotes shared with me"
        />
      ),
      options: {
        trueValue: `in:${CollaborationAccessEnum.VIEW},${CollaborationAccessEnum.EDIT}`,
      },
    });

    filterOptions.splice(2, 0, {
      title: 'Currency',
      key: 'currency',
      operator: FilterTypeOperator.EQUAL,
      renderOptionContent: (props) => (
        <CurrencyTableFilterOptionContent {...props} />
      ),
    });
  }



  const actionBodyTemplate = (data: IQuoteRespSchema) => (
    <QuoteActions
      setQuote={() => refetch()}
      quote={data}
      loadData={refetch}
      isQuotesList
    />
  );

  const onResetFilter = () => {
    setFilters([]);
  };

  const handleCreateQuotes = () => {
    navigate(ROUTES.QUOTE_NEW);
  };

  const handleEditQuote = (quoteId: string, openInNewWindow = false) => {
    editQuote(navigate, quoteId, openInNewWindow);
  };

  const handleReviewQuote = (quoteId: string, openInNewWindow = false) => {
    reviewQuote(navigate, quoteId, openInNewWindow);
  };

  const handleOpenQuote = (
    quoteId: string,
    status: QuoteStatusEnum,
    openInNewWindow = false,
  ) => {
    openQuote(navigate, quoteId, status, openInNewWindow);
  };

  const EmptyQuotesPlaceholder: FC = () => {
    let message = '';
    let route = ROUTES.QUOTE_NEW;
    let btnLabel = 'New Quote';
    if (isProductAvailable === false) {
      message =
        'It seems that you have not set up any products. You need to add products, then offerings, to get a quote started.';
      route = ROUTES.PRODUCT_NEW;
      btnLabel = 'New Product';
    } else if (isOfferingAvailable === false) {
      message =
        'It seems that you have not set up any offerings. You need to add offerings to get a quote started.';
      route = ROUTES.OFFERING_NEW;
      btnLabel = 'New Offering';
    }
    return (
      <MEmptyDataPlaceholder
        mainMessage="Looks like there are no quotes here."
        smallMessage={message}
        btnLabel={btnLabel}
        to={route}
        alternateBtnLabel={
          guidedQuotingData?.totalElements === 1 && guidedSelling
            ? 'New Guided Quote'
            : null
        }
        alternateTo={
          guidedQuotingData?.totalElements === 1 && guidedSelling
            ? ROUTES.QUOTE_NEW_GUIDED
            : undefined
        }
      />
    );
  };

  return {
    quoteList: accountId ? quotesWithBillGroup : quoteList,
    loading:
      isLoading ||
      (isRefetching && !isFetched) ||
      (isBillGroupResFetching && isBillGroupResLoading),
    searchKey,
    searchTerm,
    setSearchTerm,
    setCreatedByFilter,
    pager,
    setPager,
    actionBodyTemplate,
    handleCreateQuotes,
    handleEditQuote,
    handleReviewQuote,
    handleOpenQuote,
    filters,
    filterOptions,
    setFilters,
    onResetFilter,
    EmptyQuotesPlaceholder,
    isGuidedQuotingAvailable:
      guidedQuotingData?.totalElements === 1 && guidedSelling,
  };
};