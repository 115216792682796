import { ComingSoonIcon, HistoryEduIcon, ReportChartIcon, RevenueIcon } from '@monetize/ui/icons';
import { FC, useEffect, useState } from 'react';
import { FaMoneyBillTrendUp } from 'react-icons/fa6';
import {
  MdAccountBalance,
  MdDashboard,
  MdFormatQuote,
  MdSettings,
} from 'react-icons/md';
import { Link, Location, useLocation } from 'react-router-dom';
import { useRevRecSettings } from '../../api/revenueService';
import { ROLES, ROUTES } from '../../constants';
import { useAuth } from '../../services/auth0';
import { useFlags } from '../../services/launchDarkly';
import { AppLayoutMode, AppSidebarMenuItem } from '../../types/appTypes';
import {
  MFlex,
  MGrid,
  MIcon,
  MList,
  MListItem,
  MText,
  MTooltip,
} from '../Monetize';

export interface AppMenuProps {
  layoutMode: AppLayoutMode;
}

export const AppMenu = ({ layoutMode }: AppMenuProps) => {
  const { currentTenantUserHasRole } = useAuth();
  const { billRun, metabaseReporting, revenueRecognition } = useFlags();
  const { data: recRevSettings } = useRevRecSettings();

  const isOverlay: boolean = layoutMode === 'overlay';

  const menuItems: AppSidebarMenuItem[] = [
    {
      label: 'Dashboard',
      icon: MdDashboard,
      to: ROUTES.DASHBOARD,
      activeWhen: [{ op: 'startsWith', route: ROUTES.DASHBOARD }],
      roles: ROLES.DASHBOARD_ROLES,
    },
    {
      label: 'Accounts',
      icon: MdAccountBalance,
      to: ROUTES.ACCOUNT_LIST,
      activeWhen: [{ op: 'startsWith', route: ROUTES.ACCOUNT_LIST }],
      roles: ROLES.ACCOUNTS_ROLES,
    },
    {
      label: 'Sales',
      icon: MdFormatQuote,
      to: ROUTES.SALES_QUOTES_ROUTE,
      activeWhen: [{ op: 'startsWith', route: ROUTES.SALES_LANDING }],
      roles: ROLES.QUOTES_ROLES,
    },
    {
      label: 'Billing',
      icon: FaMoneyBillTrendUp,
      to: ROUTES.BILLING_BILL_RUN,
      activeWhen: [{ op: 'startsWith', route: ROUTES.BILLING_BILL_RUN }],
      hidden: !billRun,
      roles: ROLES.BILLING_ADMIN_ROLES,
    },
    {
      label: 'Product Catalog',
      icon: HistoryEduIcon,
      to: ROUTES.PRODUCT_LIST,
      activeWhen: [
        { op: 'startsWith', route: ROUTES.PRODUCT_LIST },
        { op: 'startsWith', route: ROUTES.ONETIME_LIST },
        { op: 'startsWith', route: ROUTES.OFFERING_LIST },
        { op: 'startsWith', route: ROUTES.DISCOUNT_LIST },
        { op: 'startsWith', route: ROUTES.USAGE_TYPE_LIST },
      ],
      roles: ROLES.PROD_CATALOG_ROLES,
    },
    {
      label: 'Reporting',
      icon: ReportChartIcon,
      to: ROUTES.REPORTING,
      activeWhen: [{ op: 'startsWith', route: ROUTES.REPORTING }],
      /** Only show when feature flag is enabled */
      roles: metabaseReporting ? ROLES.REPORTING_ROLES : [],
    },
    {
      label: 'Revenue',
      icon: RevenueIcon,
      to: ROUTES.REVENUE,
      activeWhen: [{ op: 'startsWith', route: ROUTES.REVENUE }],
      roles: ROLES.REVENUE_ROLES,
      hidden: !recRevSettings?.enabled || !revenueRecognition,
    },
    {
      label: 'Settings',
      icon: MdSettings,
      to: ROUTES.SETTINGS,
      activeWhen: [{ op: 'startsWith', route: ROUTES.SETTINGS }],
      roles: ROLES.SETTINGS_ROLES,
    },
  ];

  const availableItems = menuItems.filter(
    (item) => !item.hidden && currentTenantUserHasRole(item?.roles || []),
  );

  return (
    <MList role="menu">
      {availableItems.map((item) => (
        <MListItem
          key={item.label}
          style={
            isOverlay
              ? {
                  display: 'flex',
                  justifyContent: 'center',
                }
              : {}
          }
          tabIndex={-1}
        >
          <Link
            to={item.comingSoon ? '#' : item.to}
            role="menuitem"
            target={item.target}
            style={{ position: 'relative' }}
            data-testid={`menu-${item.label.split(' ').join('-')}`}
          >
            <RenderLinkContent item={item} layoutMode={layoutMode} />
          </Link>
        </MListItem>
      ))}
    </MList>
  );
};

const isRouteActive = (location: Location, item: AppSidebarMenuItem) => {
  if (!item.activeWhen) {
    return location.pathname === item.to;
  }
  return item.activeWhen.some(({ op, route }) => {
    switch (op) {
      case 'startsWith':
        return location.pathname.startsWith(route);
      case 'exact':
        return location.pathname === route;
      default:
        return false;
    }
  });
};

const RenderLinkContent: FC<any> = ({ item, layoutMode }: any) => {
  const location = useLocation();
  const isActive = isRouteActive(location, item);
  const [animationEnded, setAnimationEnded] = useState<boolean>(false);

  const isOverlay = layoutMode === 'overlay';

  useEffect(() => {
    if (isOverlay) {
      setTimeout(() => setAnimationEnded(false), 200);
    } else {
      setTimeout(() => setAnimationEnded(true), 200);
    }
  }, [isOverlay]);

  let color = 'tPurple.dark';
  let iconColor = isActive ? 'tPurple.dark' : 'tBlue.lightShade';

  if (item.comingSoon) {
    color = 'tGray.lightPurple';
    iconColor = 'tGray.lightPurple';
  }

  const hoverColor = item.comingSoon ? 'tGray.lightPurple' : 'tPurple.dark';

  return (
    <MTooltip
      label={item.comingSoon && !isOverlay ? 'Coming Soon' : item.label}
      placement="bottom-end"
      isDisabled={!isOverlay && !item.comingSoon}
    >
      <MGrid
        pl={!isOverlay ? 4 : 0}
        mr={!isOverlay ? 4 : 0}
        ml={!isOverlay ? 2 : 0}
        p={4}
        borderBottomColor="transparent"
        borderBottomWidth={0.5}
        gridTemplateColumns={!isOverlay ? '0.1fr 1fr' : '1fr'}
        role="group"
        justifyContent="flex-start"
        columnGap={!isOverlay ? 4 : 0}
        fontWeight={isActive ? 'semibold' : 'normal'}
        borderColor="transparent"
        borderRadius="md"
        borderWidth="1px"
        _focus={{
          borderColor: 'tPurple.dark',
        }}
      >
        <MIcon
          as={item.icon}
          justifyContent="flex-start"
          color={iconColor}
          w={5}
          h={5}
          _groupHover={{ color: hoverColor, fontWeight: 'semibold' }}
        />
        {!isOverlay && animationEnded && (
          <MFlex align="center" justify="space-between">
            <MText
              alignSelf="center"
              color={color}
              _groupHover={{
                color: hoverColor,
                fontWeight: item.comingSoon ? 'normal' : 'semibold',
              }}
            >
              {item.label}
            </MText>
            {item.comingSoon && (
              <MIcon
                as={ComingSoonIcon}
                color={color}
                display="none"
                _groupHover={{
                  color: hoverColor,
                  fontWeight: 'semibold',
                  display: 'block',
                }}
              />
            )}
          </MFlex>
        )}
      </MGrid>
    </MTooltip>
  );
};
