import { Heading, Stack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { toDateOnly, toDateShort } from '@monetize/utils/core';
import { addDays } from 'date-fns/addDays';
import { formatISO } from 'date-fns/formatISO';
import { parseISO } from 'date-fns/parseISO';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { handleApiErrorToast } from '../../api/axios';
import { doRenewContract } from '../../api/cpqService';
import { getQuoteEditRoute } from '../../constants/routes';
import {
  ContractEndActionEnum,
  ContractRenewalUi,
  ContractRenewalUiSchema,
  IContract,
  IContractRenewalReqSchema,
  ProductInclusionEnum,
} from '../../types';
import {
  MBox,
  MButton,
  MCenterModal,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MRadio,
  MRadioGroup,
  MStack,
  MText,
} from '../Monetize';
import { DatePicker } from '../Monetize/DatePicker/DatePicker';
import { ContractLengthPopover } from './ContractLengthPopover.component';

interface RenewalModalProps {
  contract: IContract;
  onClose: () => void;
}

export const RenewalModal = ({ contract, onClose }: RenewalModalProps) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<ContractRenewalUi>({
    resolver: zodResolver(ContractRenewalUiSchema),
    mode: 'onChange',
    defaultValues: {
      newQuoteType: ContractEndActionEnum.RENEW,
      renewalDate: formatISO(new Date()),
      productInclusion: ProductInclusionEnum.AS_OF_RENEWAL_DATE,
    },
  });

  const watchNewQuoteType = watch('newQuoteType');
  const watchRenewalDateStr = watch('renewalDate');
  const watchRenewalDate = useMemo(
    () => toDateShort(watchRenewalDateStr ?? new Date()),
    [watchRenewalDateStr],
  );
  const contractEndDate = toDateShort(contract.endDate);

  const handleRenewContract = async () => {
    setIsLoading(true);
    try {
      let payload:
        | { contractRenewalOptions: IContractRenewalReqSchema }
        | undefined;

      const { newQuoteType, renewalDate, productInclusion, contractLength } =
        getValues();

      if (newQuoteType === ContractEndActionEnum.EARLY_RENEWAL) {
        payload = {
          contractRenewalOptions: {
            newQuoteType: newQuoteType,
            renewalDate: renewalDate ? toDateOnly(renewalDate) : undefined,
            includeSubscriptionsAsOfRenewalDate:
              productInclusion === ProductInclusionEnum.AS_OF_RENEWAL_DATE,
            contractLength: contractLength,
          },
        };
      }

      const renewedQuote = await doRenewContract(contract.id, payload);
      renewedQuote?.id &&
        navigate({
          pathname: getQuoteEditRoute(renewedQuote.id),
        });
    } catch (error) {
      handleApiErrorToast(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MCenterModal
      modalHeaderProps={{ px: 6, py: 4 }}
      modalBodyProps={{ px: 6, py: 4 }}
      renderModalTitleActions={() => (
        <Heading fontSize="18px" color="tPurple.base">
          Renew Contract
        </Heading>
      )}
      isOpen
      size="lg"
      onClose={onClose}
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="end"
          flex={1}
        >
          <MButton onClick={onClose} variant="cancel" minW="auto">
            Cancel
          </MButton>
          <MButton
            type="submit"
            minW="auto"
            isLoading={isLoading}
            onClick={handleRenewContract}
          >
            Next
          </MButton>
        </MStack>
      )}
    >
      <MGrid gap={4} templateColumns="1">
        <MGridItem>
          <MText color="tGray.darkGrayPurple">
            This contract term is {''}
            {toDateShort(contract.startDate)} {''}
            to {''}
            {contractEndDate}.
          </MText>
        </MGridItem>
        <MGridItem>
          <Stack direction="row">
            <MFlex gap="2" align="center">
              <MText>Renew Contract for another </MText>
              <ContractLengthPopover
                contractLength={12}
                isCustomContractLength
                handleValueUpdate={(value) => setValue('contractLength', value)}
              />
              <MText fontWeight="bold">Months</MText>
            </MFlex>
          </Stack>
        </MGridItem>
        <MGridItem>
          <MGrid templateColumns="0.08fr 1fr">
            <MGridItem>
              <MText>On:</MText>
            </MGridItem>
            <MGridItem>
              <MFormField error={errors?.newQuoteType} mb={2} mt={0.5}>
                <Controller
                  name="newQuoteType"
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <MRadioGroup
                      onChange={(value) => {
                        onChange(value);
                        if (value === ContractEndActionEnum.EARLY_RENEWAL) {
                          setValue(
                            'productInclusion',
                            ProductInclusionEnum.AS_OF_RENEWAL_DATE,
                          );
                        }
                      }}
                      {...rest}
                    >
                      <MStack direction="column">
                        <MRadio value={ContractEndActionEnum.RENEW}>
                          End of Contract Term - After {contractEndDate}
                        </MRadio>
                        <MRadio value={ContractEndActionEnum.EARLY_RENEWAL}>
                          Early Renewal Date - Before {contractEndDate}
                        </MRadio>
                      </MStack>
                    </MRadioGroup>
                  )}
                />
              </MFormField>
              <MFormField error={errors?.renewalDate} pl="5" mb={4}>
                <Controller
                  name="renewalDate"
                  control={control}
                  render={({ field }) => (
                    <MBox maxWidth="11rem">
                      <DatePicker
                        minDate={addDays(parseISO(contract.startDate), 1)}
                        maxDate={addDays(parseISO(contract.endDate), -1)}
                        {...field}
                        onChange={(data) => {
                          setValue(
                            'newQuoteType',
                            ContractEndActionEnum.EARLY_RENEWAL,
                          );
                          setValue('renewalDate', data ?? undefined, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    </MBox>
                  )}
                />
              </MFormField>

              {watchNewQuoteType === ContractEndActionEnum.EARLY_RENEWAL && (
                <MFormField error={errors?.productInclusion} mb={2} mt={0.5}>
                  <MText fontWeight={600} mb={2}>
                    Include Products as of:
                  </MText>
                  <Controller
                    name="productInclusion"
                    control={control}
                    render={({ field: { ...rest } }) => (
                      <MRadioGroup {...rest}>
                        <MStack direction="column">
                          <MRadio
                            value={ProductInclusionEnum.AS_OF_RENEWAL_DATE}
                          >
                            Early Renewal Date ({watchRenewalDate})
                          </MRadio>
                          <MRadio
                            value={ProductInclusionEnum.END_OF_CONTRACT_DATE}
                          >
                            End of Current Contract ({contractEndDate})
                          </MRadio>
                        </MStack>
                      </MRadioGroup>
                    )}
                  />
                </MFormField>
              )}
            </MGridItem>
          </MGrid>
        </MGridItem>
        {watchNewQuoteType === ContractEndActionEnum.EARLY_RENEWAL && (
          <MGridItem>
            <MText color="tGray.darkGrayPurple">
              This renews the contract on {watchRenewalDate} and ends the
              existing contract the day before this date.
            </MText>
          </MGridItem>
        )}
      </MGrid>
    </MCenterModal>
  );
};
