import { GridItem } from '@chakra-ui/react';
import { Fragment } from 'react';
import {
  MCheckbox,
  MText,
  MTooltip,
} from '../../../../../../components/Monetize';
import { PRODUCT_TYPE_DISPLAY } from '../../../../../../constants/products';
import { SelectedProductsWithinOffering } from '../../../../../../types';

interface ManageProductsRowProps {
  row: SelectedProductsWithinOffering;
  index: number;
  value: boolean;
  isDrawer: boolean;
  handleCheckboxChange: (productId: string, value: boolean) => void;
}

export const ManageProductsRow = ({
  row,
  value,
  isDrawer,
  handleCheckboxChange,
}: ManageProductsRowProps) => {
  return (
    <Fragment>
      <GridItem alignSelf="start">
        <MCheckbox
          onChange={(e) => {
            handleCheckboxChange(row.id, e.target.checked);
          }}
          py={2}
          isChecked={value}
          isDisabled={row.isMandatory}
          isReadOnly={row.isMandatory}
        >
          <MTooltip label={row.isMandatory ? 'This product is required' : null}>
            <MText ml={1}>
              <MText display={'inline'} fontWeight="bold">
                {row.name}
              </MText>{' '}
              {`(${PRODUCT_TYPE_DISPLAY[row.productType]})`}
            </MText>
          </MTooltip>
        </MCheckbox>

        <MText pl={7} fontSize="xs">
          {row.name}
        </MText>
      </GridItem>
    </Fragment>
  );
};
