import { formatCurrency } from '@monetize/utils/core';
import { MAlert, MCheckbox, MFlex, MText } from '../../components/Monetize';
import { IInvoiceRespSchema } from '../../types';

interface CreditAndRebillApplyCheckboxProps {
  invoice: IInvoiceRespSchema;
  applyCreditNote: boolean;
  onApplyCreditNote: (value: boolean) => void;
}

export const CreditAndRebillApplyCheckbox = ({
  invoice,
  applyCreditNote,
  onApplyCreditNote,
}: CreditAndRebillApplyCheckboxProps) => {
  const creditAmount = invoice.amountDue;
  const creditAmountFormatted = formatCurrency(creditAmount, {
    currency: invoice.currency,
  });
  const remainingAmount = formatCurrency(invoice.amount - invoice.amountDue, {
    currency: invoice.currency,
  });
  const applicationText = `Apply the full Credit Note of ${creditAmountFormatted} to this invoice.`;
  const partialApplicationText = `Apply ${creditAmountFormatted} to this invoice; the remaining ${remainingAmount} will remain unapplied.`;

  if (invoice.amount === 0) {
    return null;
  }

  if (invoice.amountDue === 0) {
    return (
      <MAlert type="warning">
        <MText>
          Since this Invoice is fully paid, the Credit Note of{' '}
          {remainingAmount} will be available for manual application.
        </MText>
      </MAlert>
    );
  }

  return (
    <MFlex flexDirection="column" mb={2}>
      <MCheckbox
        isChecked={applyCreditNote}
        onChange={(e) => onApplyCreditNote(e.target.checked)}
        mb={2}
        fontWeight={500}
      >
        Apply Credit Note
      </MCheckbox>
      <MText ml={5}>
        {invoice.amount === creditAmount
          ? applicationText
          : partialApplicationText}
      </MText>
    </MFlex>
  );
};
