import {
  MAccordion,
  MAccordionCustomButton,
  MAccordionItem,
  MAccordionPanel,
} from '../../../components/Monetize';
import {
  IQuoteRespSchema,
  QuoteOfferingProps,
  QuoteOfferingWithChildren,
  QuoteStatusEnum,
} from '../../../types';
import { isQuoteOfferingAddedToAmendment } from '../../../utils/quotes';
import QuoteOffering from './components/quoteOffering/QuoteOffering';
import { QuoteFormOfferingTableHeader } from './QuoteFormOfferingTableHeader';

interface QuoteFormOfferingRowProps
  extends Pick<QuoteOfferingProps, 'onChange' | 'quotePrices'> {
  quoteOfferings: QuoteOfferingWithChildren[];
  quote?: IQuoteRespSchema | null;
  renderQuoteOfferings: (
    quoteOfferings: QuoteOfferingWithChildren[],
    baseDragIndex?: number,
    dragIndex?: number,
  ) => JSX.Element[];
}

export const QuoteFormOfferingTableContentAmendV2 = ({
  quoteOfferings,
  quote,
  onChange,
  quotePrices,
  renderQuoteOfferings,
}: QuoteFormOfferingRowProps) => {
  const amendmentOfferings = quoteOfferings.filter(
    ({ quoteOffering }) => !isQuoteOfferingAddedToAmendment(quoteOffering),
  );
  const baseDragIndex = amendmentOfferings.length;
  const baseIndex =
    baseDragIndex +
    amendmentOfferings.reduce((sum, { children }) => sum + children.length, 0);
  const nonAmendmentOfferings = quoteOfferings.filter(({ quoteOffering }) =>
    isQuoteOfferingAddedToAmendment(quoteOffering),
  );

  return (
    <>
      <MAccordion allowMultiple mb="8" defaultIndex={[0]} mt="8">
        <MAccordionItem>
          {({ isExpanded }) => (
            <>
              <MAccordionCustomButton
                isExpanded={isExpanded}
                label="Existing Offerings"
              ></MAccordionCustomButton>

              <MAccordionPanel p="4" pt="0" m="0">
                <QuoteFormOfferingTableHeader mt="2" />
                {renderQuoteOfferings(amendmentOfferings)}
              </MAccordionPanel>
            </>
          )}
        </MAccordionItem>
      </MAccordion>
      <MAccordion allowMultiple defaultIndex={[0]} mb="8">
        <MAccordionItem>
          {({ isExpanded }) => (
            <>
              <MAccordionCustomButton
                isExpanded={isExpanded}
                label="New Offerings"
              ></MAccordionCustomButton>

              <MAccordionPanel p="4" pt="0" m="0">
                <QuoteFormOfferingTableHeader mt="2" />
                {renderQuoteOfferings(
                  nonAmendmentOfferings,
                  baseDragIndex,
                  baseIndex,
                )}

                {quote?.status === QuoteStatusEnum.DRAFT && quote?.id && (
                  <QuoteOffering
                    quotePrices={quotePrices}
                    onChange={onChange}
                    index={-1}
                    quoteOfferings={quoteOfferings}
                  />
                )}
              </MAccordionPanel>
            </>
          )}
        </MAccordionItem>
      </MAccordion>
    </>
  );
};
