import { zodResolver } from '@hookform/resolvers/zod';
import pick from 'lodash/pick';
import toString from 'lodash/toString';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useCreateEntity,
  useGetById,
  useUpdateEntity,
} from '../../../api/queryUtils';
import {
  MBox,
  MButton,
  MCenterModal,
  MCustomNumberInput,
  MCustomSelect,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MInput,
  MPageHeader,
  MSpinner,
  MStack,
  MVStack,
} from '../../../components/Monetize';
import { STATUS_PLACEHOLDER } from '../../../constants/placeholders';
import { USAGE_TYPE_STATUS_DISPLAY } from '../../../constants/usageTypes';
import { useCtrlEnterHotkey } from '../../../hooks/useHotkeys';
import { useSettingsReadOnlyRule } from '../../../hooks/useSettingsReadOnlyRule';
import {
  IUsageTypeResSchema,
  IUsageTypeSchema,
  UsageTypeSchema,
  UsageTypeStatusEnum,
} from '../../../types';
import {
  nullifyEmptyStrings,
  objectToSelectOptions,
} from '../../../utils/misc';

interface UsageTypeFormProps {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: (id?: string) => void;
  usageTypeId?: string;
  isReadOnly?: boolean;
}

export const UsageTypeForm: FC<UsageTypeFormProps> = ({
  isOpen,
  onClose,
  usageTypeId,
  isReadOnly,
}: UsageTypeFormProps) => {
  const { isLoading, data: usageType } = useGetById<IUsageTypeResSchema>(
    'productCatalogUsageTypes',
    usageTypeId!,
    {
      enabled: !!usageTypeId,
      refetchOnWindowFocus: false,
      meta: { showErrorToast: true },
    },
  );

  useEffect(() => {
    if (usageType) {
      const formData = pick(usageType, Object.keys(UsageTypeSchema.shape));
      reset({ ...formData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usageType]);

  const doCreateUsageType = useCreateEntity<
    IUsageTypeResSchema,
    IUsageTypeSchema
  >('productCatalogUsageTypes', {
    onSuccess: (response) => onCloseHandle(response?.id),
    meta: { showErrorToast: true },
  });

  const doUpdateUsageType = useUpdateEntity<
    IUsageTypeResSchema,
    IUsageTypeSchema
  >('productCatalogUsageTypes', {
    onSuccess: (response) => onCloseHandle(response?.id),
    meta: { showErrorToast: true },
  });

  const loading = !!usageTypeId && isLoading;
  const isSaveLoading =
    doCreateUsageType.isPending || doUpdateUsageType.isPending;

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm<IUsageTypeSchema>({
    resolver: zodResolver(UsageTypeSchema),
    mode: 'onChange',

    defaultValues: {
      status: UsageTypeStatusEnum.ACTIVE,
      ...usageType,
    },
  });

  useCtrlEnterHotkey(() => {
    if (isValid && isDirty) {
      handleSubmit(onSubmit)();
    }
  });

  const onSubmit = async (data: IUsageTypeSchema) => {
    const payload = nullifyEmptyStrings({
      id: usageTypeId,
      ...data,
      decimalPlaces: Number(data.decimalPlaces),
    });
    if (!usageTypeId) {
      doCreateUsageType.mutate(payload);
    } else {
      doUpdateUsageType.mutate({ id: usageTypeId, payload: { ...payload } });
    }
  };

  const onCloseHandle = (id?: string) => {
    reset();
    onClose(id);
  };

  const { isReadOnly: canReadSettings } = useSettingsReadOnlyRule();
  const isReadOnlyProps = {
    isDisabled: isReadOnly || isSaveLoading || canReadSettings,
    disabled: isReadOnly || isSaveLoading,
    isReadOnly: isReadOnly || canReadSettings,
    variant: isReadOnly || canReadSettings ? 'readonly' : 'primary',
  };
  const headerTitle = canReadSettings
    ? 'Usage Type'
    : usageTypeId
      ? 'Edit Usage Type'
      : 'New Usage Type';
  return (
    <MCenterModal
      isOpen={isOpen}
      onClose={onCloseHandle}
      renderModalTitleActions={() =>
        !loading && (
          <MVStack alignItems="flex-start">
            <MPageHeader
              title={headerTitle}
              id={usageType?.id}
              copyUrl
              status={
                usageType?.status
                  ? USAGE_TYPE_STATUS_DISPLAY[usageType?.status]
                  : ''
              }
              containerProps={{ mb: 0 }}
            ></MPageHeader>
          </MVStack>
        )
      }
      renderFooter={() => {
        if (canReadSettings) {
          return null;
        }
        return (
          <MStack
            spacing={5}
            direction="row"
            align="center"
            justifyContent="end"
            flex={1}
          >
            <MButton
              onClick={() => onCloseHandle()}
              variant="cancel"
              minW="auto"
            >
              {isReadOnly ? 'Close' : 'Cancel'}
            </MButton>
            {!isReadOnly && (
              <MButton
                variant="primary"
                isLoading={isSaveLoading}
                isDisabled={isSaveLoading || !isValid || !isDirty}
                minW="auto"
                type="submit"
                form="usage-type-form"
              >
                Save
              </MButton>
            )}
          </MStack>
        );
      }}
      modalFooterProps={canReadSettings ? { border: 'none', pb: 0 } : undefined}
    >
      <MBox>
        {loading && (
          <MFlex height="375px" justifyContent="center" alignItems="center">
            <MSpinner />
          </MFlex>
        )}
        {!loading && (
          <form id="usage-type-form" onSubmit={handleSubmit(onSubmit)}>
            <MGrid templateColumns="repeat(6, 1fr)" gap={4}>
              <MGridItem colSpan={6}>
                <MFormField
                  error={errors.name}
                  label="Name"
                  isRequired={!isReadOnly}
                >
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <MInput
                        maxLength={40}
                        placeholder="Enter Usage Type Name"
                        {...field}
                        {...isReadOnlyProps}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={6}>
                <MFormField
                  error={errors.pluralName}
                  label="Plural Name"
                  isRequired={!isReadOnly}
                >
                  <Controller
                    name="pluralName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <MInput
                        maxLength={40}
                        placeholder="Enter Plural Name of the Usage Type"
                        {...field}
                        {...isReadOnlyProps}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={6}>
                <MFormField
                  error={errors.description}
                  label="Description"
                  isRequired={!isReadOnly}
                >
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <MInput
                        maxLength={80}
                        placeholder="Enter Usage Type Description"
                        {...field}
                        {...isReadOnlyProps}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={3}>
                <MFormField
                  error={errors.unitName}
                  label="Unit Name"
                  isRequired={!isReadOnly}
                >
                  <Controller
                    name="unitName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <MInput
                        maxLength={40}
                        placeholder="EX. GB"
                        {...field}
                        {...isReadOnlyProps}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={3}>
                <MFormField
                  error={errors.decimalPlaces}
                  label="Decimal Place"
                  isRequired={!isReadOnly}
                >
                  <Controller
                    name="decimalPlaces"
                    control={control}
                    render={({ field: { value, onChange, ...rest } }) => (
                      <MCustomNumberInput
                        onChange={(
                          valueAsString: string,
                          valueAsNumber: number,
                        ) => onChange(valueAsString)}
                        value={toString(value)}
                        placeholder="EX. 2"
                        {...rest}
                        {...isReadOnlyProps}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={3}>
                <MFormField
                  error={errors.status}
                  label="Status"
                  isRequired={!isReadOnly}
                >
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <MCustomSelect
                        placeholder={STATUS_PLACEHOLDER}
                        items={objectToSelectOptions(USAGE_TYPE_STATUS_DISPLAY)}
                        {...field}
                        {...isReadOnlyProps}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>
            </MGrid>
          </form>
        )}
      </MBox>
    </MCenterModal>
  );
};
