import { useDisclosure } from '@chakra-ui/react';
import { toDateShort } from '@monetize/utils/core';
import {
  QueryFunction,
  useQueries,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { ColumnProps } from 'primereact/column';
import { DataTableExpandedRows } from 'primereact/datatable';
import React, { useEffect, useMemo, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { subscriptionServiceQueryKeys } from '../../../api/queryKeysService';
import { fetchUsageConsumption } from '../../../api/subscriptionsService';
import {
  MBox,
  MCustomIconButton,
  MFlex,
  MText,
} from '../../../components/Monetize';
import DataTableActions from '../../../components/Monetize/DataTable/MDataTableActions';
import {
  ISubscriptionWithBillGroup,
  useSubscriptions,
} from '../../../hooks/AccountDetail';
import {
  AggregationModelEnum,
  IAccountRespSchema,
  IBillGroupResp,
  IGetSubscriptionItemSchema,
  IGetSubscriptionSchema,
} from '../../../types';
import {
  nameWithIdBodyTemplate,
  textBodyTemplate,
} from '../../../utils/tableUtils';
import { ManualUsageForm } from '../Subscriptions/Usage/ManualUsageForm';
import { UsageOverviewChartDrawer } from '../Subscriptions/Usage/UsageOverviewChartDrawer';
import { UsagePageHeader } from './components/UsagePageHeader';
import { UsageSubscriptionTable } from './components/UsageSubscriptionTable';

export const UsagePage: React.FC<{
  account: IAccountRespSchema;
  billGroupId?: string;
}> = ({ account, billGroupId }) => {
  const { id: accountId } = account;
  const { listData, loading } = useSubscriptions(accountId, billGroupId, true);

  const {
    isOpen: isUsageChartsOpen,
    onOpen: onOpenUsageCharts,
    onClose: onCloseUsageCharts,
  } = useDisclosure();

  const {
    isOpen: isUsageFormOpen,
    onOpen: onOpenUsageForm,
    onClose: onCloseUsageForm,
  } = useDisclosure();

  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>({});
  const [isExpandedAll, setIsExpandedAll] = useState(true);
  const [hasInitialExpanded, setHasInitialExpanded] = useState(false);
  const [selectedSubscription, setSelectedSubscription] =
    useState<IGetSubscriptionSchema>();
  const [selectedSubscriptionItem, setSelectedSubscriptionItem] = useState<
    IGetSubscriptionItemSchema | undefined
  >(undefined);
  const [productIdForUsageChart, setProductIdForUsageChart] = useState('');

  const queryClient = useQueryClient();

  const subscriptionsWithUsage: ISubscriptionWithBillGroup[] = useMemo(() => {
    return listData?.content
      .filter((item) =>
        item.subscriptionItems.some(
          (subscriptionItem: IGetSubscriptionItemSchema) =>
            subscriptionItem.product.productType === 'USAGE',
        ),
      )
      .map((item) => ({
        ...item,
        subscriptionItems: item.subscriptionItems.filter(
          (subscriptionItem: IGetSubscriptionItemSchema) =>
            subscriptionItem.product.productType === 'USAGE',
        ),
      }));
  }, [listData]);

  const usageConsumptionQueries = useMemo(() => {
    if (!subscriptionsWithUsage) {
      return [];
    }

    return subscriptionsWithUsage.flatMap((subscription) =>
      subscription.subscriptionItems.map((item) => ({
        queryKey: subscriptionServiceQueryKeys.subscriptionUsageConsumption({
          subscriptionId: subscription.id,
          productId: item.product.id,
          usageTypeIds: item.product.usageTypes?.map((type) => type.id) || [],
          startTime: subscription.usagePeriodStart ?? '',
          endTime: subscription.usagePeriodEnd ?? '',
          aggregationModel: AggregationModelEnum.SUM,
        }),
        queryFn: () =>
          fetchUsageConsumption(
            subscription.id,
            item.product.usageTypes?.map((type) => type.id),
            `${subscription.usagePeriodStart}Z`,
            `${subscription.usagePeriodEnd}Z`,
            AggregationModelEnum.SUM,
          ),
        enabled: expandedRows[subscription.id] || isExpandedAll,
      })),
    );
  }, [subscriptionsWithUsage, expandedRows, isExpandedAll]);

  const usageConsumptionResults: UseQueryResult<
    { unitsConsumed: number },
    unknown
  >[] = useQueries({
    queries: usageConsumptionQueries.map((query) => ({
      queryKey: query.queryKey,
      queryFn: query.queryFn as QueryFunction<{ unitsConsumed: number }>,
      enabled: query.enabled,
      staleTime: 5 * 60 * 1000,
      gcTime: 30 * 60 * 1000,
      retry: 1,
      placeholderData: (previousData, previousQuery) => previousData,
      refetchOnMount: 'always' as const,
    })),
  });

  const usageConsumptions = useMemo(() => {
    return usageConsumptionResults.reduce(
      (
        acc: Map<
          string,
          Record<string, { unitsConsumed: number; error: boolean }>
        >,
        result,
        index,
      ) => {
        const query = usageConsumptionQueries[index];
        const { queryKey } = query;

        const [, , subscriptionId, productId] = queryKey as [
          string, // 'subscriptions'
          string, // 'usageConsumption'
          string, // subscriptionId
          string, // productId
          {
            usageTypeIds: string[];
            startTime: string;
            endTime: string;
            aggregationModel: AggregationModelEnum;
          },
        ];

        if (!acc.has(subscriptionId)) {
          acc.set(subscriptionId, {});
        }

        if (result.isSuccess) {
          acc.get(subscriptionId)![productId] = {
            unitsConsumed: result.data.unitsConsumed,
            error: false,
          };
        } else if (result.isError) {
          acc.get(subscriptionId)![productId] = {
            unitsConsumed: 0,
            error: true,
          };
        }

        return acc;
      },
      new Map<
        string,
        Record<string, { unitsConsumed: number; error: boolean }>
      >(),
    );
  }, [usageConsumptionQueries, usageConsumptionResults]);

  const toggleExpandAll = () => {
    if (isExpandedAll) {
      // Collapse all rows
      setExpandedRows({});
    } else if (subscriptionsWithUsage) {
      // Expand all rows
      const allExpanded: DataTableExpandedRows = {};
      subscriptionsWithUsage.forEach((item) => {
        allExpanded[item.id] = true;
      });
      setExpandedRows(allExpanded);
    }

    setIsExpandedAll(!isExpandedAll);
  };

  const handleRowClick = (id: string) => {
    setExpandedRows((prevExpandedRows) => {
      const isExpanded = !!prevExpandedRows[id];
      const newExpandedRows = { ...prevExpandedRows };

      if (isExpanded) {
        delete newExpandedRows[id];
      } else {
        newExpandedRows[id] = true;
      }

      return newExpandedRows;
    });
  };

  const handleUsageUpload = (
    subscriptionId: string,
    activeSubscriptionItem: IGetSubscriptionItemSchema,
  ) => {
    const activeSubscription = subscriptionsWithUsage.find(
      (item) => item.id === subscriptionId,
    );
    setSelectedSubscription(activeSubscription);
    setSelectedSubscriptionItem(activeSubscriptionItem);
    onOpenUsageForm();
  };

  const handleUsageUploadOnClose = () => {
    if (selectedSubscription) {
      // Invalidate all usageConsumption queries for the selected subscription
      selectedSubscription.subscriptionItems.forEach((item) => {
        queryClient.invalidateQueries({
          queryKey: subscriptionServiceQueryKeys.subscriptionUsageConsumption({
            subscriptionId: selectedSubscription.id,
            productId: item.product.id,
            usageTypeIds: item.product.usageTypes?.map((type) => type.id) || [],
            startTime: selectedSubscription.usagePeriodStart ?? '',
            endTime: selectedSubscription.usagePeriodEnd ?? '',
            aggregationModel: AggregationModelEnum.SUM,
          }),
        });
      });
    }
    onCloseUsageForm();
  };

  const handleViewTrendSingleProduct = (
    subscriptionId: string,
    activeProductId: string,
  ) => {
    const activeSubscription = subscriptionsWithUsage.find(
      (item) => item.id === subscriptionId,
    );
    setSelectedSubscription(activeSubscription);
    setProductIdForUsageChart(activeProductId);
    onOpenUsageCharts();
  };

  const handlePorductChartClose = () => {
    setSelectedSubscription(undefined);
    setProductIdForUsageChart('');
    onCloseUsageCharts();
  };

  // Expand all rows initially
  useEffect(() => {
    if (
      subscriptionsWithUsage &&
      subscriptionsWithUsage.length > 0 &&
      !hasInitialExpanded
    ) {
      const allExpanded: DataTableExpandedRows = {};
      subscriptionsWithUsage.forEach((item) => {
        allExpanded[item.id] = true;
      });
      setExpandedRows(allExpanded);
      setIsExpandedAll(true);
      setHasInitialExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionsWithUsage, hasInitialExpanded]);

  // Synchronize isExpandedAll with the current state of expandedRows
  useEffect(() => {
    if (subscriptionsWithUsage && subscriptionsWithUsage.length > 0) {
      const allExpanded = subscriptionsWithUsage.every(
        (item) => expandedRows[item.id],
      );
      setIsExpandedAll(allExpanded);
    }
  }, [expandedRows, subscriptionsWithUsage]);

  const columns: ColumnProps[] = [
    {
      expander: true,
      field: 'offering',
      header: 'Name',
      style: { width: '12rem' },
      body: (data, op) => {
        return (
          <MFlex gap={2} align="center">
            <MCustomIconButton
              icon={expandedRows[data.id] ? MdExpandLess : MdExpandMore}
              btnSize={5}
              onClick={op.expander?.onClick}
              _focus={{
                bg: 'transparent',
              }}
              aria-label={
                expandedRows[data.id]
                  ? `Collapse row ${data.id}`
                  : `Expand row ${data.id}`
              }
            />
            <MBox>
              <MText fontWeight="500">{data.offering.name}</MText>
              <MText color="tGray.darkPurple" fontSize="xs">
                {toDateShort(data.usagePeriodStart)} &mdash;{' '}
                {toDateShort(data.usagePeriodEnd)}
              </MText>
            </MBox>
          </MFlex>
        );
      },
    },
    ...(!billGroupId
      ? [
          {
            field: 'billGroupId',
            header: 'Bill Group',
            style: { width: '10rem' },
            body: nameWithIdBodyTemplate<
              ISubscriptionWithBillGroup,
              IBillGroupResp
            >('billGroup', { fallbackProperty: 'billGroupId' }),
          },
        ]
      : []),
    {
      field: 'id',
      header: 'ID',
      style: { width: '7rem' },
      body: (data) => <MText>{data.id}</MText>,
    },
    {
      field: 'description',
      header: 'Description',
      style: { width: '10rem' },
      body: textBodyTemplate<ISubscriptionWithBillGroup>('description'),
    },
    {
      header: 'Units',
      style: { width: '5rem' },
    },
    {
      header: 'Type',
      style: { width: '5rem' },
    },
    {
      style: { textAlign: 'left', width: '2rem' },
      body: (data) => {
        const actions = [
          {
            title: 'View Trend',
            enabled: true,
            action: () => {
              setSelectedSubscription(data);
              onOpenUsageCharts();
            },
          },
        ];
        return <DataTableActions actions={actions} />;
      },
    },
  ];

  return (
    <MBox>
      <UsagePageHeader
        hideHeader={loading || subscriptionsWithUsage.length === 0}
        isExpandedAll={isExpandedAll}
        onToggleExpandAll={toggleExpandAll}
      />
      <UsageSubscriptionTable
        accountId={accountId}
        loading={loading}
        columns={columns}
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        subscriptionsWithUsage={subscriptionsWithUsage}
        usageConsumptions={usageConsumptions}
        billGroupId={billGroupId}
        onRowClick={handleRowClick}
        handleUsageUpload={handleUsageUpload}
        handleViewTrendSingleProduct={handleViewTrendSingleProduct}
      />
      {isUsageChartsOpen && (
        <UsageOverviewChartDrawer
          open={isUsageChartsOpen}
          onClose={handlePorductChartClose}
          accountId={accountId as string}
          subscriptionId={selectedSubscription?.id!}
          subscription={selectedSubscription!}
          singleProductId={productIdForUsageChart}
        />
      )}
      {isUsageFormOpen && selectedSubscription && selectedSubscriptionItem && (
        <ManualUsageForm
          isOpen={isUsageFormOpen}
          onClose={handleUsageUploadOnClose}
          subscriptionId={selectedSubscription.id}
          periodStartDate={selectedSubscription.periodStartDate!}
          usageTypes={selectedSubscriptionItem.product.usageTypes}
          accountId={accountId}
        />
      )}
    </MBox>
  );
};
