import { CardBody, CardHeader } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { DocusignIcon } from '@monetize/ui/icons';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { handleApiErrorToast } from '../../../../../api/axios';
import {
  MBox,
  MButton,
  MCenter,
  MCustomSelect,
  MDivider,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MInput,
  MText,
} from '../../../../../components/Monetize';
import { ESIGN, ROUTES } from '../../../../../constants';
import { useAuth } from '../../../../../services/auth0';
import { tempDocusignState } from '../../../../../store/global.store';
import {
  EsignAccountBaseUrlEnum,
  EsignSchema,
  IEsign,
  IEsignSchema,
} from '../../../../../types';
import { generateDocusignOauthUri } from '../../../../../utils/docusign';

interface EsignFormProps {
  esign: IEsign | null;
  handleFormVisible: (val: boolean) => void;
}

const EsignForm = ({ esign, handleFormVisible }: EsignFormProps) => {
  const { currentTenant } = useAuth();
  const setTempDocuSign = useSetRecoilState(tempDocusignState);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm<IEsignSchema>({
    resolver: zodResolver(EsignSchema),
    mode: 'onChange',
    defaultValues: {
      accountBaseURI: EsignAccountBaseUrlEnum.PRODUCTION,
    },
  });

  useEffect(() => {
    const values = esign || {};
    reset(values);
  }, [esign, reset]);

  const onSubmit = async (data: IEsignSchema) => {
    try {
      setTempDocuSign({
        method: esign ? 'put' : 'post',
        data,
      });

      const redirectUri = `${window.location.origin}${ROUTES.SETTINGS_ESIGN_CALLBACK}`;
      const uri = generateDocusignOauthUri(data.accountBaseURI, redirectUri);
      window.location.href = uri;
    } catch (error) {
      handleApiErrorToast(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MBox>
        <CardHeader>
          <MFlex justifyContent="space-between" align="center">
            <MFlex alignItems="center">
              <MCenter minW="12" minH="8">
                <DocusignIcon />
              </MCenter>
              <MBox ml={2}>
                <MText>DocuSign</MText>
              </MBox>
            </MFlex>
          </MFlex>
        </CardHeader>

        <CardBody>
          <MGrid templateColumns="repeat(10, 1fr)" rowGap="3.5" columnGap="4">
            <MGridItem colSpan={5}>
              <MFormField error={errors.userId} label="User ID" isRequired>
                <Controller
                  name="userId"
                  control={control}
                  render={({ field }) => <MInput {...field} />}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={5}>
              <MFormField
                error={errors.accountBaseURI}
                label="Environment"
                isRequired
              >
                <Controller
                  name="accountBaseURI"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      items={
                        currentTenant?.testTenant
                          ? ESIGN.DocusignAccountUrlDisplay
                          : ESIGN.DocusignAccountUrlDisplayForProduction
                      }
                      key="value"
                      {...field}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={10}>
              <MFormField error={errors.hmacKey} label="HMAC Key" isRequired>
                <Controller
                  name="hmacKey"
                  control={control}
                  render={({ field }) => <MInput {...field} />}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={10}>
              <MDivider my={1} />
            </MGridItem>
            <MGridItem colSpan={10}>
              <MFlex justify="end" gap={4}>
                <MButton
                  variant="cancel"
                  onClick={() => handleFormVisible(false)}
                >
                  Cancel
                </MButton>

                <MButton
                  variant="primary"
                  onClick={handleSubmit(onSubmit)}
                  isDisabled={!isDirty || !isValid}
                >
                  Connect
                </MButton>
              </MFlex>
            </MGridItem>
          </MGrid>
        </CardBody>
      </MBox>
    </form>
  );
};
export default EsignForm;
