import { useMemo } from 'react';
import { QuoteBodyAccordion } from '../../../../components/Quotes/QuoteBodyAccordion';
import { QuoteStatusEnum } from '../../../../types';
import { QuoteCustomFields } from '../../Quote/components/QuoteCustomFields';
import { useQuoteContext } from '../../Quote/quoteContext';
import { QuoteTerms } from './QuoteTerms';
import { QuoteUploadDocuments } from './QuoteUploadDocuments';

interface QuoteInternalExtraInfoProps {}

export const QuoteInternalExtraInfo = ({}: QuoteInternalExtraInfoProps) => {
  const { quoteData } = useQuoteContext();
  const { quote } = quoteData;

  const title = useMemo(() => {
    if (quote?.status !== QuoteStatusEnum.DRAFT) {
      return 'Extra Information';
    } else {
      return 'Review Extra Information';
    }
  }, [quote?.status]);

  return (
    <QuoteBodyAccordion title="Contract Documents and Terms">
      <QuoteUploadDocuments />

      <QuoteCustomFields
        accordionAutoExpanded
        accordionBorderColor="tGray.lightGray"
        showDivider={false}
        mt="6"
      />

      <QuoteTerms mt={6} />
    </QuoteBodyAccordion>
  );
};
