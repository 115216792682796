import { FieldMappingType } from '../types/fieldMappingTypes';

export const MN_REFERENCE_TYPE_FIELD_API_NAMES = new Set([
  'legalEntityId.customId',
  'legalEntityId.name',
  'contacts.esign',
]);
export const MN_QUOTE_DEPENDENT_FIELD_API_NAMES = new Set([
  'legalEntityId.customId',
  'legalEntityId.name',
  'currency',
  'contacts.esign',
]);
export const MN_BLACKLISTED_FIELD_API_NAMES = new Set(['legalEntityId']);
export const HS_VALIDATION_IGNORABLE_FIELD_API_NAMES = new Set([
  'hs_object_id',
]);

export const MN_CRM_FIELDS = {
  Quote: [
    {
      fieldApiName: 'description',
      dataType: 'text',
      displayLabel: 'Description',
    },
    {
      fieldApiName: 'customId',
      dataType: 'text',
      displayLabel: 'Custom ID',
    },
    {
      fieldApiName: 'currency',
      dataType: 'text',
      displayLabel: 'Quote Currency',
    },
    {
      fieldApiName: 'legalEntityId.customId',
      dataType: 'text',
      displayLabel: 'Legal Entity Custom ID',
    },
    {
      fieldApiName: 'legalEntityId.name',
      dataType: 'text',
      displayLabel: 'Legal Entity Name',
    },
  ],
  Account: [
    {
      fieldApiName: 'ccEmails',
      dataType: 'text',
      displayLabel: 'Account Level CC Emails',
    },
    {
      fieldApiName: 'defaultCurrency',
      dataType: 'text',
      displayLabel: 'Currency',
    },
    {
      fieldApiName: 'defaultDunningProcessId',
      dataType: 'text',
      displayLabel: 'Default Dunning Process ID',
    },
    {
      fieldApiName: 'defaultLegalEntityId',
      dataType: 'text',
      displayLabel: 'Legal Entity ID',
    },
  ],
  Contact: [],
  system: 'monetizenow',
} as FieldMappingType;

export const MN_CRM_FIELDS_FOR_SALESFORCE = {
  ...MN_CRM_FIELDS,
  Quote: [
    ...(MN_CRM_FIELDS.Quote ?? []),
    {
      fieldApiName: 'contacts.esign',
      dataType: 'text',
      displayLabel: 'Contact eSign Email',
    },
  ],
} as FieldMappingType;
