import {
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { FC, forwardRef } from 'react';
import { MdArrowDropDown, MdClose, MdSearch } from 'react-icons/md';
import { MCustomIconButton } from '../../../../../../components/Monetize';
import { MainInputProps } from '../../../../../../components/Monetize/MCustomSelect/components/MainInput';

interface CrmFieldMappingSearchInputProps extends MainInputProps {}

export const CrmFieldMappingSearchInput: FC<CrmFieldMappingSearchInputProps> =
  forwardRef<any, CrmFieldMappingSearchInputProps>(
    (
      {
        clearable,
        disabled,
        externalLoading,
        getTitleFromValue,
        inputProps,
        isOpen,
        isReadOnly,
        loading,
        onChange,
        onClear,
        onClose,
        onOpen,
        onQueryChange,
        onToggleOpen,
        placeholder,
        query,
        renderRightElement,
        value,
      },
      ref,
    ) => {
      const onBlurInput = (ev: React.FocusEvent<unknown>) => {
        if (
          ev.relatedTarget?.hasAttribute('data-custom-select-item') ||
          ev.relatedTarget?.classList?.contains('chakra-popover__content')
        ) {
          return;
        }
        onClose();
      };

      const getVariant = () => {
        if (inputProps?.variant) {
          return inputProps.variant;
        }
        return isReadOnly ? 'readonly' : 'primary';
      };

      const handleOnClear = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (onClear) {
          onClear();
        } else {
          onChange && onChange('' as any);
        }
      };

      const rightElement = renderRightElement && renderRightElement({ value });

      const showClearBtn = clearable && !isEmpty(value) && !disabled;

      let rightMargin = rightElement ? rightElement.width : 8;
      if (showClearBtn) {
        rightMargin += 4;
      }
      let inputValue = '';
      if (isOpen) {
        inputValue = query;
      } else if (!isOpen) {
        inputValue = getTitleFromValue(value);
      }
      return (
        <InputGroup>
          <InputLeftElement pointerEvents="none" w="8">
            <Icon w="4" h="4" as={MdSearch} color="tPurple.base" />
          </InputLeftElement>
          <Input
            type="text"
            ref={ref}
            placeholder={placeholder}
            onChange={(e: any) => {
              !isOpen && onOpen(); // open if required
              onQueryChange(e.target.value);
            }}
            value={inputValue}
            onClick={() => !disabled && onOpen()}
            onBlur={onBlurInput}
            isDisabled={disabled}
            isReadOnly={isReadOnly}
            variant={getVariant()}
            tabIndex={isOpen ? -1 : 0}
            pr={`${rightMargin * 4}px`}
            _placeholder={{
              color: 'tGray.darkGrayPurple',
            }}
            {...inputProps}
          />
          <InputRightElement width={`${rightMargin * 5}px`}>
            {showClearBtn && (
              <MCustomIconButton
                icon={MdClose}
                bg="none"
                color="tPurple.dark"
                _hover={{ bg: 'none' }}
                ml="2"
                btnSize={4}
                padding={0}
                containerSize={4}
                onClick={handleOnClear}
              />
            )}
            {loading || externalLoading ? (
              <Spinner size="xs" />
            ) : (
              <Icon
                as={MdArrowDropDown}
                fontSize="20"
                onClick={disabled ? () => {} : onToggleOpen}
                zIndex="1"
              />
            )}
          </InputRightElement>
        </InputGroup>
      );
    },
  );
