import { UseQueryResult } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import {
  MBox,
  MFlex,
  MPageLoader,
  MSpacer,
  MText,
} from '../../../../../components/Monetize';
import { QuoteDocumentLinkV2 } from '../../../../../components/Quotes/QuoteDocumentLinkV2';
import {
  IContactRespSchema,
  IQuoteRespSchema,
  IStorage,
  SigningOrderEnum,
} from '../../../../../types';
import {
  convertToBase64,
  showPdfInNewTab,
} from '../../../../../utils/download';

type DocumentType = 'file' | 'link' | 'none';
interface DocumentItemState {
  savedData: {
    storage?: IStorage;
    link?: string;
  };
  activeItem: DocumentType;
  linkUrl: string;
  fileToUpload?: {
    file: File;
    fileName: string;
  };
  savedItemText: string;
}
interface EsignContentProps {
  label: string;
  fullName?: string;
  email?: string;
  isSentFirst: boolean;
  isSentBoth: boolean;
}

const EsignContent = (props: EsignContentProps) => {
  const { label, fullName, email, isSentFirst, isSentBoth } = props;
  return (
    <MFlex
      py={1}
      borderBottom={isSentFirst ? '1px solid' : 'none'}
      borderColor="tGray.back"
    >
      <MBox py="2">
        <MText fontWeight="bold">{label}</MText>
        <MText fontSize="xs" color="tGray.darkPurple">
          {!isSentBoth && isSentFirst ? 'Receives the Quote first' : ''}
          {isSentBoth && 'Receives the Quote at the same time'}
        </MText>
      </MBox>
      <MSpacer />
      <MBox py="2">
        <MText fontWeight="400" color="tGray.darkPurple" textAlign="right">
          {fullName}
        </MText>
        <MText
          fontSize="xs"
          fontWeight="400"
          color="tGray.darkPurple"
          textAlign="right"
        >
          {email}
        </MText>
      </MBox>
    </MFlex>
  );
};

interface ContentContentProps {
  quote: IQuoteRespSchema;
  internalContact?: IContactRespSchema;
  externalContact?: IContactRespSchema;
  sowDocument: UseQueryResult<IStorage, unknown>;
  msaDocument: UseQueryResult<IStorage, unknown>;
}

export const SendQuoteThroughEsignContent = ({
  quote,
  internalContact,
  externalContact,
  sowDocument,
  msaDocument,
}: ContentContentProps) => {
  const [msaData, setMsaData] = useState<DocumentItemState>({
    savedData: {
      storage: msaDocument.data,
      link: quote.documentUrl || undefined,
    },
    activeItem: 'none',
    linkUrl: '',
    savedItemText: '',
  });

  const [sowData, setSowData] = useState<DocumentItemState>({
    savedData: {
      storage: sowDocument.data,
    },
    activeItem: 'none',
    linkUrl: '',
    savedItemText: '',
  });

  // Set initial state id msaDocument changes
  useEffect(() => {
    if (msaDocument.data || quote.documentUrl) {
      setMsaData((priorData) => {
        let activeItem: DocumentType = 'none';
        if (msaDocument.data) {
          activeItem = 'file';
        } else if (quote.documentUrl) {
          activeItem = 'link';
        }
        return {
          ...priorData,
          savedData: {
            link: quote.documentUrl || undefined,
            storage: msaDocument.data,
          },
          activeItem,
          savedItemText: msaDocument.data?.filename || quote.documentUrl || '',
        };
      });
    }
  }, [msaDocument.data, quote.documentUrl]);

  useEffect(() => {
    if (sowDocument.data) {
      setSowData((priorData) => {
        let activeItem: DocumentType = 'none';
        if (sowDocument.data) {
          activeItem = 'file';
        }
        return {
          ...priorData,
          savedData: {
            link: undefined,
            storage: sowDocument.data,
          },
          activeItem,
          savedItemText: sowDocument.data?.filename || '',
        };
      });
    }
  }, [sowDocument.data?.filename, sowDocument.data]);

  const externalESign = (
    <EsignContent
      key={externalContact?.id}
      label={`${quote?.accountName || 'External'} Signature`}
      fullName={externalContact?.fullName}
      email={externalContact?.email}
      isSentFirst={quote.signingOrder === SigningOrderEnum.EXTERNAL_FIRST}
      isSentBoth={quote.signingOrder === SigningOrderEnum.INSTANTANEOUS}
    />
  );
  const internalESign = (
    <EsignContent
      key={internalContact?.id}
      label={`${quote.fromCompany.name || 'Internal'} Signature`}
      fullName={internalContact?.fullName}
      email={internalContact?.email}
      isSentFirst={quote.signingOrder === SigningOrderEnum.INTERNAL_FIRST}
      isSentBoth={quote.signingOrder === SigningOrderEnum.INSTANTANEOUS}
    />
  );

  const eSignContent = [externalESign];
  if (quote.signingOrder === SigningOrderEnum.INTERNAL_FIRST) {
    eSignContent.unshift(internalESign);
  } else {
    eSignContent.push(internalESign);
  }

  const isLoadingInitialData = msaDocument.isLoading || sowDocument.isLoading;

  async function handleGoToItem(type: 'msa' | 'sow') {
    const getter = type === 'msa' ? msaData : sowData;
    if (getter.activeItem === 'file') {
      let base64Data = '';
      let fileName = '';
      if (getter.fileToUpload) {
        base64Data = await convertToBase64(getter.fileToUpload.file);
        // eslint-disable-next-line prefer-destructuring
        fileName = getter.fileToUpload.fileName;
      } else if (getter.savedData.storage?.contentBase64Encoded) {
        base64Data = getter.savedData.storage.contentBase64Encoded;
        fileName = getter.savedData.storage.filename;
      }
      showPdfInNewTab(base64Data, fileName);
    } else {
      const link = getter.linkUrl || getter.savedData.link;
      if (link) {
        window.open(link, '_blank');
      }
    }
  }

  if (isLoadingInitialData) {
    return <MPageLoader maxH={100} />;
  }

  return (
    <MBox>
      {eSignContent}
      <MFlex
        py={4}
        justifyContent="space-between"
        borderTop="1px solid"
        borderColor="tGray.back"
      >
        <MText minW="fit-content" fontWeight={600}>
          MSA
        </MText>

        {(msaDocument.data || quote.documentUrl) && (
          <QuoteDocumentLinkV2
            onClick={() => handleGoToItem('msa')}
            fileName={
              quote.documentUrl || msaDocument?.data?.referenceFilename || ''
            }
            showLinkIcon={!!quote.documentUrl}
          />
        )}
        {!quote.documentUrl && !msaDocument.isFetching && !msaDocument.data && (
          <MText fontStyle="italic">No document added</MText>
        )}
      </MFlex>

      <MFlex
        py={4}
        justifyContent="space-between"
        borderTop="1px solid"
        borderColor="tGray.back"
      >
        <MText minW="fit-content" fontWeight={600}>
          SOW
        </MText>
        {sowDocument.data && (
          <QuoteDocumentLinkV2
            onClick={() => handleGoToItem('sow')}
            fileName={sowDocument.data.referenceFilename}
          />
        )}
        {!sowDocument.isFetching && !sowDocument.data && (
          <MText fontStyle="italic">No document added</MText>
        )}
      </MFlex>
    </MBox>
  );
};
