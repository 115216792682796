import { zodResolver } from '@hookform/resolvers/zod';
import { ResolverOptions } from 'react-hook-form';
import { boolean, number, string, z } from 'zod';
import { FrequencyMapReturn } from '../constants/offerings';
import { getRequiredMessage } from '../utils/messages';
import {
  FirstInvoiceCreationStrategyEnumZ,
  InvoiceCreationEnumZ,
} from './InvoiceSettingsTypes';
import { AccountRespSchema } from './accountTypes';
import { AddressSchema } from './addressTypes';
import { ContactRespSchema } from './contactTypes';
import { CustomFieldRecordSchema } from './customFieldsTypes';
import { IDunnings } from './dunningTypes';
import { BaseResponseSchema, EmailTextAreaTypeSchema } from './miscTypes';
import { NetTermsEnumZ } from './netTermsType';
import { IPaymentGatewaySchema } from './paymentGatewayTypes';
import { IPaymentMethodResp } from './paymentMethodTypes';
import { QuoteSettingsDefaultAddressSourceEnumZ } from './quoteSettingsAddressTypes';

export enum BillGroupStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  INACTIVE = 'INACTIVE',
}
export const BillGroupStatusEnumZ = z.nativeEnum(BillGroupStatusEnum);

export enum BillGroupDunningStatusEnum {
  GOOD_STANDING = 'GOOD_STANDING',
  IN_DUNNING = 'IN_DUNNING',
  SUSPENDED = 'SUSPENDED',
}
export const BillGroupDunningStatusEnumZ = z.nativeEnum(
  BillGroupDunningStatusEnum,
);
export enum BillGroupInvoiceFrequencyEnum {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMIANNUALLY = 'SEMIANNUALLY',
  ANNUALLY = 'ANNUALLY',
  CUSTOM = 'CUSTOM',
}

export const BILL_GROUP_INVOICE_FREQUENCY_MAP: {
  [key in BillGroupInvoiceFrequencyEnum]: (
    val?: number | null,
  ) => FrequencyMapReturn;
} = {
  DAILY: () => ({
    label: 'Daily',
    btnLabel: 'Daily (0)',
    month: 0,
    value: BillGroupInvoiceFrequencyEnum.DAILY,
  }),
  MONTHLY: () => ({
    label: 'Monthly',
    btnLabel: 'Monthly (1)',
    month: 1,
    value: BillGroupInvoiceFrequencyEnum.MONTHLY,
  }),
  QUARTERLY: () => ({
    label: 'Quarterly',
    btnLabel: 'Quarterly (3)',
    month: 3,
    value: BillGroupInvoiceFrequencyEnum.QUARTERLY,
  }),
  SEMIANNUALLY: () => ({
    label: 'Semi-Annually',
    btnLabel: 'Semi-Annually (6)',
    month: 6,
    value: BillGroupInvoiceFrequencyEnum.SEMIANNUALLY,
  }),
  ANNUALLY: () => ({
    label: 'Annually',
    btnLabel: 'Annually (12)',
    month: 12,
    value: BillGroupInvoiceFrequencyEnum.ANNUALLY,
  }),
  CUSTOM: (frequency) => {
    let label: string = '';
    if (frequency) {
      if (frequency % 12 === 0) {
        label = `Every ${frequency / 12} Year(s)`;
      } else {
        label = `Every ${frequency} Month(s)`;
      }
    }
    return {
      label,
      btnLabel: label,
      month: frequency,
      value: BillGroupInvoiceFrequencyEnum.CUSTOM,
    };
  },
};
export const BillGroupInvoiceFrequencyEnumZ = z.nativeEnum(
  BillGroupInvoiceFrequencyEnum,
);

export enum BillGroupErrorStatusEnum {
  NONE = 'NONE',
  TAX_CALCULATION_ERROR = 'TAX_CALCULATION_ERROR',
}

export const BillGroupErrorStatusEnumZ = z.nativeEnum(BillGroupErrorStatusEnum);

export const BillGroupAddressDataSchema = z.object({
  addressSource: QuoteSettingsDefaultAddressSourceEnumZ,
  shippingAddressId: z.string().nullish(),
  billingAddressId: z.string().nullish(),
});

export const BillGroupContactSchema = ContactRespSchema.pick({
  id: true,
  fullName: true,
  email: true,
});

export type BillGroupContact = z.infer<typeof BillGroupContactSchema>;

// from POST /api/accounts/{accountId}/billGroups (PUT uses same schema)
export const BillGroupReqSchema = z.object({
  name: string().min(1, getRequiredMessage('Name')),
  contactId: string().min(1, getRequiredMessage('Billing Contact')), // API takes contactId but returns billingContactId
  shippingContactId: string().min(1, getRequiredMessage('Shipping Contact')),
  defaultPaymentGatewayId: string().nullish(),
  paymentMethodId: string().nullish(),
  dunningProcessId: string().nullish(),
  billDay: string().or(number()),
  status: BillGroupStatusEnumZ,
  collectInvoiceBalanceAutomatically: boolean(),
  customId: string().nullish(),
  netTerms: NetTermsEnumZ,
  purchaseOrderNumber: z
    .string()
    .max(45, 'PO Number can be no more than 45 characters long')
    .nullish(),
  autoEmailInvoice: boolean(),
  invoiceCreation: InvoiceCreationEnumZ,
  invoiceDelay: z.number().min(-30).max(30),
  firstInvoiceCreationStrategy: FirstInvoiceCreationStrategyEnumZ.nullish(),
  ccEmails: z.array(z.string().email()).nullish(),
  legalEntityId: z.string({
    required_error: getRequiredMessage('Legal Entity'),
  }),
  customFields: CustomFieldRecordSchema,
  currency: z.string({
    required_error: getRequiredMessage('Currency'),
  }),
  addressData: BillGroupAddressDataSchema,
  vatNumber: z.string().nullable(),
  registrationNumber: z.string().nullable(),
});
export type IBillGroupReq = z.infer<typeof BillGroupReqSchema>;

export const BillGroupReqUISchema = BillGroupReqSchema.extend({
  invoicingFrequency: BillGroupInvoiceFrequencyEnumZ, // not yet accepted by API
  invoicingFrequencyInMonths: number().nullish(), // not yet accepted by API
  invoiceTemplateId: string().optional(), // not yet accepted by API
  // read-only values provided by API:
  locked: boolean(),
  activeContract: boolean().nullable(),
  nextInvoiceDate: string().nullish(),
  strCcEmails: EmailTextAreaTypeSchema,
  currency: z.string({
    required_error: getRequiredMessage('Currency'),
  }),
  customFields: CustomFieldRecordSchema,

  addressData: BillGroupAddressDataSchema,
  shippingAddress: AddressSchema.nullish(),
  billingAddress: AddressSchema.nullish(),
});

export const BillGroupFormSchema = BillGroupReqSchema.extend({
  strCcEmails: EmailTextAreaTypeSchema,
}).omit({ ccEmails: true });

export type IBillGroupReqUI = z.infer<typeof BillGroupReqUISchema>;

// from /api/accounts/{accountId}/billGroups
export const BillGroupRespSchema = BaseResponseSchema.extend({
  accountId: z.string(),
  account: AccountRespSchema,
  accountName: z.string(),
  contractId: z.string().nullable(),
  activeContract: z.boolean().nullable(),
  locked: z.boolean(),
  defaultPaymentGateway: IPaymentGatewaySchema.nullable(),
  paymentMethodId: z.string().nullable(),
  billingContactId: z.string(),
  billingContact: BillGroupContactSchema.nullish(),
  shippingContactId: z.string(),
  shippingContact: BillGroupContactSchema.nullish(),
  customId: z.string().nullable(),
  name: z.string(),
  billDay: z.number(),
  netTerms: NetTermsEnumZ,
  purchaseOrderNumber: z.string().nullish(),
  invoicingFrequency: BillGroupInvoiceFrequencyEnumZ,
  invoicingFrequencyInMonths: z.number().nullish(),
  nextInvoiceDate: z.string().nullable(),
  invoiceCreation: InvoiceCreationEnumZ,
  invoiceDelay: z.number(),
  firstInvoiceCreationStrategy: FirstInvoiceCreationStrategyEnumZ.nullish(),
  previousInvoiceDate: z.string().nullable(),
  status: BillGroupStatusEnumZ,
  dunningStatus: BillGroupDunningStatusEnumZ,
  dunningProcessId: z.string().nullable(),
  dunningStepId: z.string().nullable(),
  invoicingFailed: z.boolean(),
  collectInvoiceBalanceAutomatically: z.boolean(),
  autoEmailInvoice: z.boolean(),
  ccEmails: z.array(z.string().email()).nullish(),
  legalEntityId: z.string(),
  currency: z.string(),
  customFields: CustomFieldRecordSchema,
  errorStatus: BillGroupErrorStatusEnumZ,
  addressSource: QuoteSettingsDefaultAddressSourceEnumZ,
  shippingAddress: AddressSchema.nullish(),
  billingAddress: AddressSchema.nullish(),
  vatNumber: z.string().nullable(),
  registrationNumber: z.string().nullable(),
});
export type IBillGroupResp = z.infer<typeof BillGroupRespSchema>;

/**
 * Includes additional data points for UI presentation
 */
export type BillGroupListUi = IBillGroupResp & {
  invoiceFrequencyLabel: string;
  emailCustomerInvoice: 'Automatically' | 'Manually';
};

// properties applied to the response object for UI purposes only, such as displaying list of bill groups to select from
// or merging complete objects, like contacts, for list view display
// not for use on the form, instead use BillGroupReqUISchema
export type IBillGroupRespUI = IBillGroupResp & {
  paymentMethod?: IPaymentMethodResp;
  dunningProcess?: IDunnings;
  rightLabel?: string;
};

export const PaymentCollectAutoConfigSchema = z.object({
  collectAutomatically: z.boolean(),
  allowPartialPayment: z.boolean(),
});

export type IPaymentCollectAutoConfigReqSchema = z.infer<
  typeof PaymentCollectAutoConfigSchema
>;

export type IPaymentCollectAutoConfigResSchema = z.infer<
  typeof PaymentCollectAutoConfigSchema
>;

export const BillGroupCCEmailsSchema = BillGroupFormSchema.pick({
  strCcEmails: true,
});

export type IBillGroupCCEmailsResSchema = z.infer<
  typeof BillGroupCCEmailsSchema
>;

export const getBillGroupCCEmailsResSchema =
  () =>
  (
    data: IBillGroupCCEmailsResSchema,
    context: z.RefinementCtx,
    options: ResolverOptions<IBillGroupCCEmailsResSchema>,
  ) => {
    const mod = BillGroupCCEmailsSchema.superRefine(({ strCcEmails }, ctx) => {
      const result = EmailTextAreaTypeSchema.safeParse(strCcEmails);
      if (!result.success) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: result.error.issues[0].message,
        });
      }
    });
    return zodResolver(mod)(data, context, options);
  };

export const BillGroupStats = z.object({
  openQuotes: z.number(),
  invoicedAmount: z.number(),
  outstandingAmount: z.number(),
});

export type IBillGroupStats = z.infer<typeof BillGroupStats>;
