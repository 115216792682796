import { toDateShort } from '@monetize/utils/core';
import { parseISO } from 'date-fns/parseISO';
import { FC } from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { handleApiErrorToast } from '../../../api/axios';
import {
  MBox,
  MButton,
  MCheckbox,
  MCustomSelect,
  MFormField,
  MGrid,
  MText,
} from '../../../components/Monetize';
import { DatePicker } from '../../../components/Monetize/DatePicker/DatePicker';
import { QuoteContractLengthPeriodPopover } from '../../../components/Quotes/QuoteContractLengthPeriodPopover';
import { useNetTerms } from '../../../hooks';
import { useIsTenantEsignConfigured } from '../../../hooks/useIsTenantEsignConfigured';
import { useFlags } from '../../../services/launchDarkly';
import { INetTerm, IQuoteRequestSchema } from '../../../types';
import { getQuoteType } from '../../../utils';
import { getQuoteFormHeaderGridTemplateColumns } from '../../../utils/quotes';
import { QuoteContractRenewalTermsAction } from './components/QuoteContractRenewalTermsAction';
import QuoteContactInput from './components/contacts/QuoteContactInput';
import { useQuoteContext } from './quoteContext';

interface QuoteFormHeaderProps {
  onOpenContactDrawer: () => void;
}

const QuoteFormHeader: FC<QuoteFormHeaderProps> = ({
  onOpenContactDrawer,
}: QuoteFormHeaderProps) => {
  const {
    handleSubmitButtonWithoutDirtyCheck,
    quoteData: { quote, modifiedFields, updateQuote, setQuote },
    quoteSettingsData,
    showPrimaryContactRequiredBg,
    isReadOnly,
    isLoading,
    useAmendmentV2,
  } = useQuoteContext();
  const { isNew, isAmendment, isRenewal } = getQuoteType(quote);
  const { activeNetTerms, netTermsById } = useNetTerms();
  const isTenantEsignConfigured = useIsTenantEsignConfigured();
  const { hideAutoRenewal } = useFlags();
  const { control, getValues, setValue } =
    useFormContext<IQuoteRequestSchema>();
  const { errors } = useFormState({
    control,
  });

  const selectedNetTerm = getValues('netTerms');
  const gridTemplateColumns = getQuoteFormHeaderGridTemplateColumns({
    isAmendment,
    isRenewal,
    useAmendmentV2,
  });

  // make sure that if inactive net term is selected, it is included in dropdown
  let netTermsOptions: INetTerm[] = activeNetTerms;
  if (
    selectedNetTerm &&
    netTermsById[selectedNetTerm] &&
    !netTermsById[selectedNetTerm].active
  ) {
    netTermsOptions = [...netTermsOptions, netTermsById[selectedNetTerm]];
  }

  const handleSaveQuoteContractRenewalTerms = async (
    payload: IQuoteRequestSchema,
  ): Promise<void> => {
    try {
      await updateQuote(quote?.id!, payload);
    } catch (ex) {
      handleApiErrorToast(ex);
    }
  };

  if (!quote) {
    return null;
  }

  return (
    <MGrid gap={4} gridTemplateColumns={gridTemplateColumns} alignItems="start">
      {isAmendment && !useAmendmentV2 && (
        <MFormField error={errors.contractAmendmentDate} label="Amendment Date">
          <Controller
            name="contractAmendmentDate"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <DatePicker
                {...rest}
                triggerType="button"
                btnText={toDateShort(quote?.contractAmendmentDate || null)}
                minDate={
                  quote?.contractStartDate
                    ? parseISO(quote.contractStartDate)
                    : undefined
                }
                maxDate={
                  quote?.contractEndDate
                    ? parseISO(quote.contractEndDate)
                    : undefined
                }
                onChange={(date) => {
                  onChange(date);
                  handleSubmitButtonWithoutDirtyCheck();
                }}
                isDisabled={!quote}
                isReadOnly={isReadOnly}
                btnProps={{
                  my: 0,
                  ml: -3,
                  mr: 0,
                  'data-testid': 'contractStartDateBtn',
                }}
                readonlyContentProps={{ pb: 0, px: 3 }}
              />
            )}
          />
        </MFormField>
      )}

      <MFormField label="Contract Length">
        <QuoteContractLengthPeriodPopover
          isCustomContractLength={
            quoteSettingsData.quoteSettings?.customContractLength
              .allowCustomLength!
          }
          isNew={isNew}
          isAmendment={isAmendment}
          quote={quote!}
          setValue={setValue}
          handleSubmitButton={handleSubmitButtonWithoutDirtyCheck}
          isReadOnly={isReadOnly}
          isRenewal={isRenewal}
          setQuote={setQuote}
          renderTrigger={({
            isDisabled,
            contractLengthFormattedVal,
            contractPeriodFormattedVal,
            handleOnOpen,
          }) => (
            <MBox position="relative">
              {isDisabled ? (
                <MBox textAlign="left">
                  <MText>{contractPeriodFormattedVal}</MText>
                  <MText fontSize="sm">{contractLengthFormattedVal}</MText>
                </MBox>
              ) : (
                <MButton
                  variant="tertiary"
                  onClick={handleOnOpen}
                  minH="40px"
                  ml="-4"
                >
                  <MBox textAlign="left">
                    <MText color="inherit">{contractPeriodFormattedVal}</MText>
                    <MText color="inherit" fontSize="sm">
                      {contractLengthFormattedVal}
                    </MText>
                  </MBox>
                </MButton>
              )}
            </MBox>
          )}
        />
      </MFormField>
      {!hideAutoRenewal && (
        <QuoteContractRenewalTermsAction
          contractRenewalTerms={quote?.contractRenewalTerms}
          defaultContractRenewalTerms={
            quoteSettingsData.quoteSettings?.contractRenewalTerms
          }
          onSubmit={async (contractRenewalTerms) => {
            try {
              await handleSaveQuoteContractRenewalTerms({
                ...getValues(),
                contractRenewalTerms,
              });
              setValue('contractRenewalTerms', contractRenewalTerms, {
                shouldDirty: false,
              });
            } catch (ex) {
              handleApiErrorToast(ex);
            }
          }}
          isReadOnly={isReadOnly}
        />
      )}
      <MFormField error={errors.expirationDate} label="Quote Expiration Date">
        <Controller
          name="expirationDate"
          control={control}
          render={({ field: { onChange, ...rest } }) => (
            <DatePicker
              data-testid="date-picker-expirationDate"
              {...rest}
              isDisabled={isReadOnly}
              isReadOnly={isReadOnly}
              minDate={new Date()}
              placeholder="Select End Date"
              onChange={(date) => {
                onChange(date);
                handleSubmitButtonWithoutDirtyCheck();
              }}
              readonlyContentProps={{ pb: 0, pt: 1.5 }}
            />
          )}
        />
      </MFormField>

      <MFormField error={errors.netTerms} label="Net Terms" isRequired>
        <Controller
          name="netTerms"
          control={control}
          render={({ field: { onChange, ...rest } }) => (
            <MCustomSelect
              isDisabled={isReadOnly}
              isReadOnly={isReadOnly}
              itemTitle="name"
              itemValue="value"
              items={netTermsOptions}
              placeholder="Select Net Terms"
              onChange={(e: any) => {
                onChange(e);
                handleSubmitButtonWithoutDirtyCheck();
              }}
              {...rest}
            />
          )}
        />
      </MFormField>
      <QuoteContactInput
        showPrimaryContactRequiredBg={showPrimaryContactRequiredBg}
        contact={quote?.contacts?.primary}
        onEditContacts={() => {
          onOpenContactDrawer();
        }}
      />

      {isTenantEsignConfigured && (
        <MFormField
          display="flex"
          error={errors.requiresEsign}
          alignSelf="flex-end"
          alignItems="center"
          minH={8}
          label=""
        >
          <Controller
            name="requiresEsign"
            control={control}
            render={({ field: { value, onChange, ...rest } }) => (
              <MCheckbox
                isReadOnly={isReadOnly}
                isDisabled={isReadOnly}
                isChecked={value as any}
                onChange={(e) => {
                  onChange(e.target.checked);
                  handleSubmitButtonWithoutDirtyCheck();
                }}
                {...rest}
              >
                eSign
              </MCheckbox>
            )}
          />
        </MFormField>
      )}
    </MGrid>
  );
};

export default QuoteFormHeader;
