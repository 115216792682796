import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  ApiListResponse,
  GetListApiConfig,
  GetListApiFilter,
  IUser,
  IUserInviteLink,
  IUserRole,
  IUserRolesModifySchema,
  TDataTablePager,
} from '../types';
import { apiDelete, apiGet, apiPost, MAxiosCustomConfig } from './axios';
import { ApiQueryItem } from './queryUtils';
import {
  updateListCacheWithRemovedItem,
  updateListCacheWithUpdatedItem,
} from './queryUtilsHelpers';
import { asQueryUtil, composeGetQuery } from './utils';

export const userServiceQueryKeys = {
  roles: () => ['roles'] as const,

  users: () => ['users'] as const,
  usersList: () => [...userServiceQueryKeys.users(), 'list'] as const,
  usersById: (id: string) => [...userServiceQueryKeys.users(), id] as const,
};

export const tenantUsersKeys: ApiQueryItem = {
  byId: {
    endpoint: (id: string) => `/api/users/${id}`,
    queryKey: (id: string) => userServiceQueryKeys.usersById(id),
  },
  update: {
    endpoint: (id: string) => `/api/users/${id}`,
    invalidateKeys: [userServiceQueryKeys.usersList()],
    setDataKey: (id: string) => userServiceQueryKeys.usersById(id),
  },
};
export const TENANT_USERS_SERVICE_API = asQueryUtil({
  tenantUser: tenantUsersKeys,
});

export function useInviteUser<Response, Payload>(
  tenantId: string,
  options: Partial<UseMutationOptions<Response, unknown, Payload>> = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation({
    mutationFn: (payload) =>
      apiPost<Response>(`/api/invites`, payload, {
        axiosConfig: { customXTenantId: tenantId },
      }).then((res) => res.data),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: userServiceQueryKeys.usersList(),
      });
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

export function useResendVerificationLink<Response, Payload>(
  options: Partial<UseMutationOptions<Response, unknown, Payload>> = {},
) {
  return useMutation({
    mutationFn: (payload) =>
      apiPost<Response>(`/api/users/verification`, payload, {
        axiosConfig: {
          excludeUserIdFromHeader: true,
          excludeTenantIdFromHeader: true,
        },
      }).then((res) => res.data),
    ...options,
  });
}

export function useGetUsersListByTenantId<IUser>({
  tenantId,
  config,
  filters,
  axiosConfig,
  options = {},
}: {
  tenantId: string;
  config: TDataTablePager | GetListApiConfig;
  filters?: GetListApiFilter;
  axiosConfig?: MAxiosCustomConfig;
  options?: Partial<UseQueryOptions<ApiListResponse<IUser>, unknown>>;
}) {
  const params = composeGetQuery(config, filters);
  return useQuery({
    queryKey: [...userServiceQueryKeys.usersList(), axiosConfig, params],
    queryFn: () =>
      apiGet<ApiListResponse<IUser>>(`/api/tenants/${tenantId}/users`, {
        params,
        axiosConfig,
      }).then((res) => res.data),
    meta: {
      setByIdCacheFromReturnedList: {
        byIdQueryKey: (id) => userServiceQueryKeys.usersById(id),
        endpointArgs: tenantUsersKeys.list,
      },
    },
    ...options,
  });
}

export function useGetTenantUserById({
  tenantId,
  userId,
  options = {},
}: {
  tenantId: string;
  userId: string;
  options?: Partial<UseQueryOptions<IUser, unknown>>;
}) {
  return useQuery({
    queryKey: [...userServiceQueryKeys.usersById(userId)],
    queryFn: () =>
      apiGet<IUser>(`/api/tenants/${tenantId}/users/${userId}`, {}).then(
        (res) => res.data,
      ),
    ...options,
  });
}

export function useGetUserRoles<SelectData = ApiListResponse<IUserRole>>(
  config: TDataTablePager,
  filters?: GetListApiFilter,
  options: Partial<
    UseQueryOptions<ApiListResponse<IUserRole>, unknown, SelectData>
  > & { axiosConfig?: MAxiosCustomConfig } = {},
) {
  const params = composeGetQuery(config, filters);
  const { axiosConfig, ...restOptions } = options;
  return useQuery({
    queryKey: [userServiceQueryKeys.roles(), params, axiosConfig],
    queryFn: () =>
      apiGet<ApiListResponse<IUserRole>>(`/api/roles`, {
        params,
        axiosConfig,
      }).then((res) => res.data),
    // Data never changes
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    gcTime: 1000 * 60 * 60 * 24,
    ...restOptions,
  });
}

export function useAddOrDeleteUserRoles(
  options: Partial<
    UseMutationOptions<any, unknown, IUserRolesModifySchema>
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, onError, onSettled, ...restOptions } = options;
  return useMutation<any, unknown, IUserRolesModifySchema>({
    mutationFn: ({ userId, idsToAdd, idsToRemove, tenantId }) => {
      return Promise.allSettled([
        ...idsToAdd.map((id) =>
          apiPost<any>(
            `/api/users/${userId}/roles/${id}`,
            {},
            { axiosConfig: { customXTenantId: tenantId } },
          ).then((res) => res.data),
        ),
        ...idsToRemove.map((id) =>
          apiDelete<any>(`/api/users/${userId}/roles/${id}`, {
            axiosConfig: { customXTenantId: tenantId },
          }).then((res) => res.data),
        ),
      ]).then((res) => res);
    },
    // Optimistically update the user and list of users assuming the change will be successful
    onMutate: ({ userId, idsToAdd, idsToRemove, userRoles }) => {
      if (!userRoles) {
        return;
      }
      const previousUser = queryClient.getQueryData<IUser>(
        userServiceQueryKeys.usersById(userId),
      );
      if (!previousUser) {
        return;
      }
      // Calculate the new roles that the user will have
      const newUser = { ...previousUser };
      const newRoleIds = new Set([
        ...idsToAdd,
        ...newUser.roles.map(({ id }) => id),
      ]);
      idsToRemove.forEach((id) => newRoleIds.delete(id));
      const userRolesById = new Map(userRoles.map((role) => [role.id, role]));
      newUser.roles = Array.from(newRoleIds)
        .map((id) => userRolesById.get(id))
        .filter(Boolean) as IUserRole[];

      // Set user in byId and list cache
      queryClient.setQueryData(userServiceQueryKeys.usersById(userId), newUser);
      updateListCacheWithUpdatedItem(
        queryClient,
        userServiceQueryKeys.usersList(),
        newUser,
      );
      return { previousUser };
    },
    onError: (err, variables, context) => {
      // Revert cache update if the API call was not successful
      if (!context || !(context as any).previousUser) {
        return;
      }
      queryClient.setQueryData(
        userServiceQueryKeys.usersById(variables.userId),
        (context as any).previousUser,
      );
      updateListCacheWithUpdatedItem(
        queryClient,
        userServiceQueryKeys.usersList(),
        (context as any).previousUser,
      );
      onError && onError(err, variables, context);
    },
    onSuccess: (data, variables, context) => {
      onSuccess && onSuccess(data, variables, context);
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: userServiceQueryKeys.usersList(),
      });
      queryClient.invalidateQueries({
        queryKey: userServiceQueryKeys.usersById(variables.userId),
      });
      onSettled && onSettled(data, error, variables, context);
    },
    ...restOptions,
  });
}

/**
 * Cancels or re-sends a user invoice
 *
 * @param options
 * @returns
 */
export function useCancelOrResendInvite(
  options: Partial<
    UseMutationOptions<
      any,
      unknown,
      { userId: string; action: 'cancel' | 'resend' }
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation({
    mutationFn: ({ action, userId }) => {
      if (action === 'cancel') {
        return apiDelete<any>(`/api/users/${userId}/uninvited`).then(
          (res) => res.data,
        );
      } else {
        return apiPost<Response>(`/api/invites/resend/${userId}`).then(
          (res) => res.data,
        );
      }
    },
    onSuccess: (data, variables, content) => {
      if (variables.action === 'cancel') {
        updateListCacheWithRemovedItem(
          queryClient,
          userServiceQueryKeys.usersList(),
          variables.userId,
        );
        queryClient.invalidateQueries({
          queryKey: userServiceQueryKeys.usersById(variables.userId),
        });
        queryClient.invalidateQueries({
          queryKey: userServiceQueryKeys.usersList(),
        });
      }
      onSuccess && onSuccess(data, variables, content);
    },
    ...restOptions,
  });
}

export const doGetTenantUser = async (tenantId: string, userId: string) => {
  const res = await apiGet<IUser>(`/api/tenants/${tenantId}/users/${userId}`);
  return res.data;
};

export const doCreateUser = async (id: string): Promise<any> => {
  const res = await apiPost<any>(
    `/api/users`,
    {
      subject: id,
    },
    {
      axiosConfig: {
        excludeUserIdFromHeader: true,
        excludeTenantIdFromHeader: true,
      },
    },
  );
  return res.data;
};

/** @deprecated, prefer useGetById<IUser>('tenantUser', userId! {}) */
export const doGetUserById = async (userId: string): Promise<any> => {
  const res = await apiGet<IUser>(`/api/users/${userId}`);
  return res.data;
};

/** @deprecated use useGetUsersListByTenantId */
export const doGetUsersListByTenantId = async (
  tenantId: string,
  config: TDataTablePager | GetListApiConfig,
  filters?: GetListApiFilter,
  axiosConfig?: MAxiosCustomConfig,
) => {
  const params = composeGetQuery(config, filters);
  const res = await apiGet<ApiListResponse<IUser>>(
    `/api/tenants/${tenantId}/users`,
    {
      params,
      axiosConfig,
    },
  );
  return res.data;
};

export const doGetInviteLink = async (userId: string) => {
  const res = await apiGet<IUserInviteLink>(`/api/users/${userId}/invites`);
  return res.data;
};
