import { toDateTimeShort } from '@monetize/utils/core';
import { ColumnProps } from 'primereact/column';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { MdOutlineFileDownload } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {
  MBox,
  MCustomIconButton,
  MDataTable,
  MFlex,
  MPageContainer,
  MSettingsPageHeader,
  MText,
  MTooltip,
} from '../../../../components/Monetize';
import { MDataTableFilter } from '../../../../components/Monetize/DataTable';
import { DateTableFilter } from '../../../../components/Monetize/DataTable/FilterOptions/DateTableFilter';
import { LoadMore } from '../../../../components/Monetize/LoadMore';
import { ROUTES } from '../../../../constants';
import { useIntegrationLogCsvExport } from '../../../../hooks/useIntegrationLogCsvExport';
import { useIntegrationLogData } from '../../../../hooks/useIntegrationLogData';
import { useDocumentHead } from '../../../../services/documentHead';
import { FilterOptionType, FilterTypeOperator } from '../../../../types';
import {
  IntegrationLogStatusEnum,
  TIntegrationLogRequestSchema,
  TIntegrationLogResSchema,
} from '../../../../types/integrationLogTypes';
import { objectToObjArray } from '../../../../utils/misc';
import {
  dateTimeBodyTemplate,
  statusBodyTemplate,
  textBodyTemplate,
  textWithTooltipBodyTemplate,
} from '../../../../utils/tableUtils';
import {
  EntityNameEnumDisplay,
  EventTypeEnumDisplay,
  IntegrationLogStatusTagStyle,
  IntegrationStatusEnumDisplay,
} from './constants';

const entityFilters = objectToObjArray(EntityNameEnumDisplay, 'value', 'label');
const eventFilters = objectToObjArray(EventTypeEnumDisplay, 'value', 'label');
const statusFilters = objectToObjArray(
  IntegrationStatusEnumDisplay,
  'value',
  'label',
);

const filterOptions: FilterOptionType[] = [
  {
    title: 'From',
    key: 'fromLastUpdatedAt',
    renderOptionContent: (props) => (
      <DateTableFilter placeholder="From" showTimePicker {...props} />
    ),
  },
  {
    title: 'To',
    key: 'toLastUpdatedAt',
    renderOptionContent: (props) => (
      <DateTableFilter placeholder="To" showTimePicker {...props} />
    ),
  },
  {
    title: 'Object',
    key: 'entityName',
    operator: FilterTypeOperator.IN,
    items: entityFilters,
  },
  {
    title: 'Operation',
    key: 'eventType',
    operator: FilterTypeOperator.IN,
    items: eventFilters,
  },
  {
    title: 'Status',
    key: 'status',
    operator: FilterTypeOperator.IN,
    items: statusFilters,
  },
];

export function IntegrationLog() {
  const navigate = useNavigate();
  const { setDocTitle } = useDocumentHead();
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const [fetchCsvCount, setFetchCsvCount] = useState(1);

  const {
    filters,
    filterParams,
    logData,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    isError,
    setFilters,
    fetchNextPage,
  } = useIntegrationLogData();
  const { downloadCsv, isDownloading } = useIntegrationLogCsvExport(
    filterParams as TIntegrationLogRequestSchema,
  );

  const handleLoadMore = () => {
    setFetchCsvCount((prev) => prev + 1);
    fetchNextPage();
  };

  const handleDownload = () => {
    const headers = [
      {
        label: 'Timestamp',
        value: (row: TIntegrationLogResSchema) =>
          toDateTimeShort(row.lastUpdatedAt),
      },
      { label: 'Object', value: 'entityName' },
      { label: 'Operation', value: 'eventType' },
      { label: 'Label', value: 'label' },
      { label: 'Source Record ID', value: 'sourceId' },
      { label: 'Target Record ID', value: 'targetId' },
      { label: 'Status', value: 'status' },
    ];

    downloadCsv(fetchCsvCount, headers, 'integration_log_export.csv');
  };

  const wrapFailedStatusInTooltip = (
    renderer: (data: TIntegrationLogResSchema) => ReactNode,
    tooltipLabel: string,
  ) => {
    return function wrappedRenderer(data: TIntegrationLogResSchema) {
      const content = renderer(data);

      if (!content) {
        return null;
      }

      if (data.status === IntegrationLogStatusEnum.FAILED) {
        return <MTooltip label={tooltipLabel}>{content}</MTooltip>;
      }

      return content;
    };
  };

  const columns: ColumnProps[] = [
    {
      field: 'lastUpdatedAt',
      header: 'Timestamp',
      body: dateTimeBodyTemplate<TIntegrationLogResSchema>('lastUpdatedAt'),
      style: { paddingLeft: 0 },
    },
    {
      field: 'entityName',
      header: 'Object',
      body: textBodyTemplate<TIntegrationLogResSchema>('entityName'),
    },
    {
      field: 'eventType',
      header: 'Operation',
      body: textBodyTemplate<TIntegrationLogResSchema>('eventType'),
    },
    {
      field: 'label',
      header: 'Label',
      body: textWithTooltipBodyTemplate<TIntegrationLogResSchema>(
        'label',
        'label',
      ),
      style: { maxWidth: '10em' },
    },
    {
      field: 'sourceId',
      header: 'Source Record ID',
      body: textBodyTemplate<TIntegrationLogResSchema>('sourceId'),
    },
    {
      field: 'targetId',
      header: 'Target Record ID',
      body: textBodyTemplate<TIntegrationLogResSchema>('targetId'),
    },
    {
      field: 'status',
      header: 'Status',
      body: (data: TIntegrationLogResSchema) => {
        const baseRenderer = statusBodyTemplate<
          TIntegrationLogResSchema,
          IntegrationLogStatusEnum
        >('status', IntegrationStatusEnumDisplay, IntegrationLogStatusTagStyle);

        const tooltipWrappedRenderer = wrapFailedStatusInTooltip(
          baseRenderer,
          data.log,
        );

        return tooltipWrappedRenderer(data);
      },
    },
  ];

  const onRowClick = (sourceRecordId: string) => {
    const singleLogItem = logData.find(
      (item) => item.sourceId === sourceRecordId,
    );
    const encodedLogItem = encodeURIComponent(JSON.stringify(singleLogItem));
    navigate(
      ROUTES.getIntegrationLogViewRoute(sourceRecordId) +
        `?logData=${encodedLogItem}`,
    );
  };

  useEffect(() => {
    setDocTitle('Settings', 'Integration Log');
  }, []);

  return (
    <MPageContainer
      ref={setContainerRef}
      alignItems="stretch"
      overflowY="auto"
      overflowX="hidden"
    >
      <MSettingsPageHeader title="Integration Log">
        {!isFetching && (
          <>
            <MDataTableFilter
              filters={filters}
              filterOptions={filterOptions}
              setFilters={setFilters}
              onResetFilter={() => setFilters([])}
            />
            <MTooltip label="Export CSV" placement="bottom-end">
              <MCustomIconButton
                variant="icon"
                containerSize="8"
                btnSize={6}
                fontSize="lg"
                cursor="pointer"
                icon={MdOutlineFileDownload}
                onClick={handleDownload}
                iconColor="tPurple.base"
                isLoading={isDownloading}
              />
            </MTooltip>
          </>
        )}
      </MSettingsPageHeader>
      <MFlex justifyContent="space-between" mb={8}>
        <MText>
          This is a log of all successful and unsuccessful data syncs from
          MonetizeNow to external integrations.
        </MText>
      </MFlex>
      <MBox ref={tableContainerRef} height={1}>
        <MDataTable
          rowHover
          value={logData}
          totalRecords={logData.length}
          className="p-datatable-responsive"
          loading={isFetching && !isFetchingNextPage}
          columns={columns}
          emptyProps={{
            mainMessage: 'Looks like there are no data here.',
          }}
          onRowClick={(e) => onRowClick(e.data?.sourceId)}
        />
        {!isError && (
          <MBox pb={4}>
            <LoadMore
              fetchedElementLength={logData.length}
              isLoading={isFetchingNextPage}
              containerElement={containerRef}
              scrollToTopElement={tableContainerRef.current}
              hasMoreItem={hasNextPage}
              onLoadMore={handleLoadMore}
            />
          </MBox>
        )}
      </MBox>
    </MPageContainer>
  );
}
