import { useEffect, useRef, useState } from 'react';
import {
  MBox,
  MCustomMultiSelect,
  MFlex,
  MText,
  MTooltip,
} from '../../../components/Monetize';
import { MCustomMultiSelectRef } from '../../../components/Monetize/MCustomSelect/MCustomMultiSelect';
import {
  handleTransformRoleLabel,
  renderRoleItemContent,
} from '../../../components/Users/InviteForm';
import { IUser, IUserRole } from '../../../types';
import { pluralize } from '../../../utils';

interface UserRoleDropdownProps {
  user: IUser;
  userRoles: IUserRole[];
  onChangeRole: (user: IUser, ev: IUserRole[]) => void;
}

/**
 * Multi-select dropdown for user roles
 *
 * This component ensures the multi-select dropdown is always in sync with the user roles
 * There was an issue where the dropdown would not always retain the updated state of the user roles
 *
 */
export const UserRoleDropdown = ({
  user,
  userRoles,
  onChangeRole,
}: UserRoleDropdownProps) => {
  const inputRef = useRef<MCustomMultiSelectRef>();
  const [processedRoles, setProcessedRoles] = useState(() =>
    user.roles.map((role) => {
      return {
        roleName: handleTransformRoleLabel(role.name),
        ...role,
      };
    }),
  );

  useEffect(() => {
    setProcessedRoles(
      user.roles.map((role) => {
        return {
          roleName: handleTransformRoleLabel(role.name),
          ...role,
        };
      }),
    );
  }, [user.roles]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.updateItems(processedRoles);
    }
  }, [processedRoles]);

  return (
    <MBox>
      <MCustomMultiSelect
        mt={0}
        ref={inputRef}
        name="roleIds"
        items={userRoles}
        itemTitle="roleName"
        itemValue="id"
        returnItem
        inputWidth="75%"
        minWidth="150px"
        value={processedRoles}
        showTagsInInput
        renderItemContent={renderRoleItemContent}
        onChange={(ev) => onChangeRole(user, ev)}
        closeButtonText="Apply"
        renderContentInInput={({ onToggleOpen }) => {
          return (
            <MFlex alignItems="center">
              {processedRoles.length === 1 ? (
                <MText fontSize="sm" onClick={onToggleOpen}>
                  {processedRoles[0].roleName}
                </MText>
              ) : (
                <MTooltip
                  label={
                    <MBox>
                      <MText color="tWhite.base" fontSize={12}>
                        Roles:
                      </MText>
                      {processedRoles.map((role) => (
                        <MText key={role.id} color="tWhite.base" fontSize={12}>
                          {role.roleName}
                        </MText>
                      ))}
                    </MBox>
                  }
                  placement="bottom-start"
                >
                  <MText fontSize="sm" onClick={onToggleOpen}>
                    {processedRoles.length}{' '}
                    {pluralize('Role', processedRoles.length)}
                  </MText>
                </MTooltip>
              )}
            </MFlex>
          );
        }}
      />
    </MBox>
  );
};
