import {
  replaceUserTimezoneWithUtc,
  toDateShort,
} from '@monetize/utils/core';
import { isBefore } from 'date-fns/isBefore';
import { subMonths } from 'date-fns/subMonths';
import { atomFamily, selectorFamily } from 'recoil';
import {
  IBillGroupResp,
  IQuoteItemRespSchema,
  IQuoteRespSchema,
  ProductTypeEnum,
  QuoteItemAmendmentStatusEnum,
} from '../../../../types';

type QuoteId = string;

type BannerDismissedState = {
  ContractWillBeCancelledBanner: boolean;
  ContractIsCancelledBanner: boolean;
  ContractCancelledFromContractActionBanner: boolean;
  InactiveRateInfoBanner: boolean;
  InactiveRateWarningBanner: boolean;
  RenewalNotAvailableBanner: boolean;
  UsageProductWarningBanner: boolean;
  EarlyRenewalBanner: boolean;
};

// Banner state - whether the banner has been dismissed for a given quote
// atomFamily stores state for a given key, each quote has it's own state stored in memory
const bannerDismissedState = atomFamily<BannerDismissedState, QuoteId>({
  key: 'bannerDismissedState',
  default: {
    ContractWillBeCancelledBanner: false,
    ContractIsCancelledBanner: false,
    ContractCancelledFromContractActionBanner: false,
    InactiveRateInfoBanner: false,
    InactiveRateWarningBanner: false,
    RenewalNotAvailableBanner: false,
    UsageProductWarningBanner: false,
    EarlyRenewalBanner: false,
  },
});

// Convenience selector to get/set the dismissed state for a given quote+banner
export const bannerDismissedSelector = selectorFamily({
  key: 'bannerDismissedStateSelector',
  get:
    ([quoteId, bannerKey]: [QuoteId, keyof BannerDismissedState]) =>
    ({ get }) => {
      return get(bannerDismissedState(quoteId))[bannerKey];
    },
  set:
    ([quoteId, bannerKey]: [QuoteId, keyof BannerDismissedState]) =>
    ({ set }, newValue) => {
      set(bannerDismissedState(quoteId), (prevValue) => ({
        ...prevValue,
        [bannerKey]: newValue,
      }));
    },
});

// Banner messages as constants for maintainability
export const BANNER_MESSAGES = {
  CREDIT_WARNING:
    'Only unused portions of minimum commitments are credited in downgrades; usage is not credited',
  ADDITION_WARNING: (date: string) =>
    `Usage/Min Commit products added prior to ${date} will not be invoiced as invoicing has already progressed beyond this period. Finance may create a one-time invoice manually if required.`,
};

const hasProductTypeInQuoteOffering = (
  items: IQuoteItemRespSchema[],
  productType: ProductTypeEnum,
  amendmentStatus?: QuoteItemAmendmentStatusEnum,
) =>
  items.some(
    ({ productType: pt, amendmentStatus: as }) =>
      pt === productType && (!amendmentStatus || as === amendmentStatus),
  );

/**
 * Checks usage/min commit dates and returns banner messages if necessary.
 *
 * @param quote - The quote data.
 * @param billGroup - The billing group data.
 * @returns An array of banner messages.
 */
export const checkUsageDate = (
  quote: IQuoteRespSchema,
  billGroup: IBillGroupResp,
): string[] => {
  if (quote.quoteOfferings.length === 0 || !billGroup.nextInvoiceDate) {
    return [];
  }

  const nextInvoiceDate = replaceUserTimezoneWithUtc(billGroup.nextInvoiceDate);
  const previousInvoiceDate = subMonths(nextInvoiceDate, 1);
  const bannerText = new Set<string>();

  for (const quoteOffering of quote.quoteOfferings) {
    const {
      items,
      endDate: endDateStr,
      startDate: startDateStr,
    } = quoteOffering;
    const endDate = replaceUserTimezoneWithUtc(endDateStr);
    const startDate = replaceUserTimezoneWithUtc(startDateStr);

    const usageProductExists = hasProductTypeInQuoteOffering(
      items,
      ProductTypeEnum.USAGE,
    );
    const usageProductRemoved = hasProductTypeInQuoteOffering(
      items,
      ProductTypeEnum.USAGE,
      QuoteItemAmendmentStatusEnum.REMOVED,
    );
    const minCommitRemoved = hasProductTypeInQuoteOffering(
      items,
      ProductTypeEnum.MIN_COMMIT,
      QuoteItemAmendmentStatusEnum.REMOVED,
    );
    const usageProductAdded = hasProductTypeInQuoteOffering(
      items,
      ProductTypeEnum.USAGE,
      QuoteItemAmendmentStatusEnum.ADDED,
    );
    const minCommitUpdated = hasProductTypeInQuoteOffering(
      items,
      ProductTypeEnum.MIN_COMMIT,
      QuoteItemAmendmentStatusEnum.UPDATED,
    );

    // Check for credit warnings
    if (
      (usageProductExists && isBefore(endDate, nextInvoiceDate)) ||
      usageProductRemoved ||
      minCommitRemoved
    ) {
      bannerText.add(BANNER_MESSAGES.CREDIT_WARNING);
    }

    // Check for addition/back date warnings
    if (
      (usageProductAdded && isBefore(startDate, previousInvoiceDate)) ||
      (minCommitUpdated && isBefore(startDate, previousInvoiceDate))
    ) {
      bannerText.add(
        BANNER_MESSAGES.ADDITION_WARNING(toDateShort(previousInvoiceDate)),
      );
    }
  }

  return Array.from(bannerText);
};
