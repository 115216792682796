import { CardBody } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, Fragment, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '../../../api/axios';
import { getPricesFromProductObject } from '../../../routes/ProductCatalog/Offerings/components/Rate/RatePriceUtils';
import { logger } from '../../../services/logger';
import {
  AccountRateCreateReqSchemaUI,
  AccountRateUpdateReqSchemaUI,
  IAccount,
  IAccountRateCreateReqSchema,
  IAccountRateCreateReqSchemaUI,
  IAccountRateUpdateReqSchema,
  IAccountRateUpdateReqSchemaUI,
  IOrderedObj,
  IPriceUnderProductSchemaUI,
  IQuoteItemRespSchema,
  IRateReqSchema,
  IRateResSchema,
  OfferingTypesEnum,
} from '../../../types';
import { getOrderedPricesUnderProduct } from '../../../utils/product';
import { MButton, MCard, MDivider, MFlex } from '../../Monetize';
import { PricesDrawerBodyEditProduct } from './PricesDrawerBodyEditProduct';
import { PricesDrawerScopedProducts } from './PricesDrawerScopedProducts';
import { RateName } from './RateName';
import { prepareRateRequestData } from './utils';
import { sortByProductPosition } from '@monetize/utils/core';

interface PricesDrawerBodyEditProps {
  account: IAccount | Pick<IAccount, 'id' | 'accountName'>;
  selectedProductsWithPrices: IPriceUnderProductSchemaUI[];
  productsWithPrices: IPriceUnderProductSchemaUI[];
  offeringRate: IRateResSchema;
  providedOfferingRate: IRateResSchema;
  productsObj: IOrderedObj<IQuoteItemRespSchema>;
  latestPayload?: IAccountRateCreateReqSchema | IAccountRateUpdateReqSchema;
  isEditMode: boolean;
  offeringType: OfferingTypesEnum;
  setIsEditMode: (val: boolean) => void;
  setLatestPayload?: (
    val: IAccountRateCreateReqSchema | IAccountRateUpdateReqSchema,
  ) => void;
  onRateSaved?: (rate: IRateResSchema) => void;
  createOfferingRate: (
    data: IRateReqSchema | IAccountRateCreateReqSchema,
    offeringId?: string | undefined,
  ) => Promise<IRateResSchema | null>;
  updateOfferingRate: (
    data: IRateReqSchema | IAccountRateUpdateReqSchema,
    rateId: string,
    offeringId?: string | undefined,
  ) => Promise<IRateResSchema | null>;
  setOfferingRate?: (val: IRateResSchema | null) => void;
  setOrderedPricesUnderProduct?: (val: IPriceUnderProductSchemaUI[]) => void;

  cancelSaveCustomRates: () => void;
  handleClose: (
    skipEditWarning?: boolean,
    submitting?: boolean,
  ) => Promise<void>;
}

export const PricesDrawerBodyEdit: FC<PricesDrawerBodyEditProps> = ({
  account,
  selectedProductsWithPrices,
  productsWithPrices,
  offeringRate,
  providedOfferingRate,
  productsObj,
  latestPayload,
  isEditMode,
  offeringType,
  setIsEditMode,
  setLatestPayload,
  onRateSaved,
  createOfferingRate,
  updateOfferingRate,
  setOfferingRate,
  setOrderedPricesUnderProduct,
  cancelSaveCustomRates,
}: PricesDrawerBodyEditProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const defaultValues = prepareRateRequestData(
    account,
    productsWithPrices,
    offeringRate,
  );
  const shouldUpdate =
    offeringRate.rateType === 'ACCOUNT' && !offeringRate.locked;
  const methods = useForm<
    IAccountRateCreateReqSchemaUI | IAccountRateUpdateReqSchemaUI
  >({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(
      shouldUpdate
        ? AccountRateUpdateReqSchemaUI
        : AccountRateCreateReqSchemaUI,
    ),
    defaultValues,
    values: defaultValues,
    resetOptions: {
      keepDirty: true,
      keepErrors: true,
    },
  });
  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const sortedProductsWithPrices = sortByProductPosition(
    selectedProductsWithPrices.map((item) => ({
      ...item,
      position: productsObj.byId[item.id].position,
    })),
  );

  /**
   * User has created or edited a custom rate
   */
  async function onSubmit({
    products,
    ...rest
  }: IAccountRateCreateReqSchemaUI | IAccountRateUpdateReqSchemaUI) {
    const data = {
      ...rest,
      prices: getPricesFromProductObject(products),
    };
    try {
      setIsSaving(true);
      setLatestPayload?.(data);

      const savedRate = shouldUpdate
        ? await updateOfferingRate(
            data,
            offeringRate.id,
            offeringRate?.offering.id,
          )
        : await createOfferingRate(data, offeringRate?.offering.id);

      if (savedRate) {
        reset(data);
        setOfferingRate?.(savedRate);
        setOrderedPricesUnderProduct?.(getOrderedPricesUnderProduct(savedRate));
        onRateSaved && onRateSaved(savedRate);
      }
    } catch (ex) {
      handleApiErrorToast(ex);
    } finally {
      setIsSaving(false);
      setIsEditMode(false);
    }
  }

  function onError(error: any) {
    logger.error('[RATE FORM NAME ERROR]', error, methods);
  }

  return (
    <form id="rate-form" onSubmit={handleSubmit(onSubmit, onError)}>
      <FormProvider {...methods} data-testid="rate-form">
        <MCard variant="borderless" mb="2">
          <CardBody py="2">
            <RateName
              offeringRate={offeringRate}
              providedOfferingRate={providedOfferingRate}
              account={account}
              productsWithPrices={selectedProductsWithPrices}
              latestPayload={latestPayload}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              setLatestPayload={setLatestPayload}
              setOfferingRate={setOfferingRate}
              updateOfferingRate={updateOfferingRate}
              createOfferingRate={createOfferingRate}
              setOrderedPricesUnderProduct={setOrderedPricesUnderProduct}
              onRateSaved={onRateSaved}
            />
            <MDivider my="2" />

            {sortedProductsWithPrices.map((productWithPrices, index) => {
              const isLastRow = selectedProductsWithPrices.length - 1 === index;

              return (
                <Fragment key={productWithPrices.id}>
                  <PricesDrawerBodyEditProduct
                    offeringType={offeringType}
                    productWithPrices={productWithPrices}
                    productsObj={productsObj}
                    offeringRate={offeringRate}
                    providedOfferingRate={providedOfferingRate}
                    account={account}
                    productsWithPrices={selectedProductsWithPrices}
                    latestPayload={latestPayload}
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    setLatestPayload={setLatestPayload}
                    setOfferingRate={setOfferingRate}
                    updateOfferingRate={updateOfferingRate}
                    createOfferingRate={createOfferingRate}
                    setOrderedPricesUnderProduct={setOrderedPricesUnderProduct}
                    onRateSaved={onRateSaved}
                    cancelSaveCustomRates={cancelSaveCustomRates}
                  />
                  {!isLastRow && <MDivider my="2" />}
                </Fragment>
              );
            })}
            {offeringRate.percentOfTotalConfig && (
              <PricesDrawerScopedProducts
                {...offeringRate.percentOfTotalConfig}
              />
            )}
            {isEditMode && (
              <>
                <MDivider my="2" />
                <MFlex justifyContent="end" align="center">
                  <MButton
                    size="sm"
                    variant="primary"
                    type="submit"
                    isLoading={isSaving}
                    isDisabled={!isDirty || isSaving}
                    onClick={handleSubmit(onSubmit, onError)}
                  >
                    Save
                  </MButton>
                </MFlex>
              </>
            )}
          </CardBody>
        </MCard>
      </FormProvider>
    </form>
  );
};
