import { ColumnProps } from 'primereact/column';
import { DataTableRowClickEvent } from 'primereact/datatable';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  MButton,
  MDataTable,
  MPageContentContainer,
  MPageHeader,
  MPageSearchInput,
  shouldOpenInNewWindow,
} from '../../../components/Monetize';
import {
  MDataTableFilter
} from '../../../components/Monetize/DataTable';
import { ExportTableButton } from '../../../components/Monetize/ExportEntityButton';
import { ROUTES } from '../../../constants';
import {
  QuoteStatusEnumDisplay,
  QuoteStatusTagStyle,
  QuoteTypeEnumDisplay,
} from '../../../constants/quotes';
import { getAccountDetailRoute } from '../../../constants/routes';
import { useQuotesTableData } from '../../../hooks/AccountDetail';
import { ACLCheck } from '../../../services/acl/ACLCheck';
import { useACL } from '../../../services/acl/acl';
import {
  IQuoteRespSchema,
  QuoteStatusEnum,
  QuoteTypeEnum
} from '../../../types';
import {
  getFiltersApplied,
  getIsTrulyEmptyList
} from '../../../utils';
import {
  timerTemplate
} from '../../../utils/quotes';
import {
  currencyBodyTemplate,
  dateTimeBodyTemplate,
  headerAndIdTemplate,
  statusBodyTemplate,
  userAvatarBodyTemplate,
} from '../../../utils/tableUtils';

const QuoteListMain = () => {
  const {
    quoteList,
    loading,
    searchKey,
    searchTerm,
    setSearchTerm,
    pager,
    setPager,
    actionBodyTemplate,
    handleEditQuote,
    handleReviewQuote,
    filterOptions,
    filters,
    setFilters,
    onResetFilter,
    EmptyQuotesPlaceholder,
    isGuidedQuotingAvailable,
  } = useQuotesTableData();

  const isFilteredByOwner = filters.find(
    ({ key, value }) => key === 'owner' && !!value,
  );
  const { canDo } = useACL();
  const canReadQuoteOwner = canDo([['quote_owner', 'read']]);

  const handleOnRowClick = (e: DataTableRowClickEvent) => {
    if (e.data?.status === 'DRAFT') {
      handleEditQuote(e.data?.id, shouldOpenInNewWindow(e));
    } else {
      handleReviewQuote(e.data?.id, shouldOpenInNewWindow(e));
    }
  };

  let columns: ColumnProps[] = [
    {
      field: 'id',
      header: 'Name',
      body: headerAndIdTemplate<IQuoteRespSchema>('description', 'id'),
      // className: 'table-cell-lg',
    },
    {
      field: 'accountName',
      header: 'Account',
      body: headerAndIdTemplate<IQuoteRespSchema>('accountName', 'accountId', {
        idLinkFn: (id) => getAccountDetailRoute(id),
      }),
      sortable: true,
      className: 'table-cell-lg',
    },
    {
      field: 'owner',
      header: 'Owner',
      body: userAvatarBodyTemplate<IQuoteRespSchema>('ownerName'),
      style: {
        width: '3.125rem',
      },
      sortable: false,
    },
    {
      header: '',
      body: timerTemplate,
      style: { width: '1em' },
      sortable: false,
    },
    {
      field: 'modifyDate',
      header: 'Updated',
      body: dateTimeBodyTemplate<IQuoteRespSchema>('modifyDate'),
      sortable: true,
    },
    {
      field: 'type',
      header: 'Type',
      body: statusBodyTemplate<IQuoteRespSchema, QuoteTypeEnum>(
        'type',
        QuoteTypeEnumDisplay,
      ),
      sortable: true,
    },
    {
      field: 'status',
      header: 'Status',
      sortable: true,
      className: 'table-cell-sm',
      body: statusBodyTemplate<IQuoteRespSchema, QuoteStatusEnum>(
        'status',
        QuoteStatusEnumDisplay,
        QuoteStatusTagStyle,
        {
          extraProps: {
            fontWeight: 'normal',
            width: 'fit-content',
          },
        },
      ),
    },
    {
      field: 'amount',
      header: 'Total',
      sortable: true,
      body: currencyBodyTemplate<IQuoteRespSchema>('amount', 'currency'),
      style: { textAlign: 'right' },
    },
    {
      field: 'action',
      header: '',
      sortable: false,
      body: actionBodyTemplate,
      style: { paddingRight: '8px' },
    },
  ];

  if (!canReadQuoteOwner || isFilteredByOwner) {
    columns = columns.filter(({ field }) => field !== 'owner');
  }

  const filterComponentReset = React.useRef<any>(null);
  const searchComponentReset = React.useRef<any>(null);

  const isTrulyEmptyList = getIsTrulyEmptyList({
    loading,
    totalElements: quoteList?.totalElements || 0,
    filters,
    searchTerm,
    page: pager.page,
  });

  return (
    <MPageContentContainer>
      <MPageHeader title="Quotes" size="md" childrenProps={{ gap: 2 }}>
        {!isTrulyEmptyList && (
          <>
            <MPageSearchInput
              placeholderKey="Quote Name"
              value={searchTerm}
              onChange={(e: any) => setSearchTerm(e)}
              count={quoteList?.totalElements}
              resetSearch={searchComponentReset}
            />
            <MDataTableFilter
              filters={filters}
              filterOptions={filterOptions}
              setFilters={setFilters}
              onResetFilter={onResetFilter}
              resetFilter={filterComponentReset}
            />
            <ExportTableButton
              entity="quotes"
              filters={filters}
              searchKey={searchKey}
              searchTerm={searchTerm}
              sortField={pager.sortField}
              sortOrder={pager.sortOrder}
            />
            <ACLCheck acls={[['sales', 'create']]}>
              <MButton variant="secondary" as={Link} to={ROUTES.QUOTE_NEW}>
                New Quote
              </MButton>
              <>
                {isGuidedQuotingAvailable && (
                  <MButton
                    variant="secondary"
                    as={Link}
                    to={ROUTES.QUOTE_NEW_GUIDED}
                  >
                    New Guided Quote
                  </MButton>
                )}
              </>
            </ACLCheck>
          </>
        )}
      </MPageHeader>
      <MDataTable
        value={quoteList?.content}
        totalRecords={quoteList?.totalElements}
        totalPages={quoteList?.totalPages}
        pager={pager}
        setPager={setPager}
        className="p-datatable-responsive"
        emptyProps={{
          renderEmptyPlaceholder: () => <EmptyQuotesPlaceholder />,
        }}
        loading={loading}
        columns={columns}
        onRowClick={handleOnRowClick}
        filtersApplied={getFiltersApplied(filters) > 0 || !!searchTerm}
        resetFilter={() => {
          filterComponentReset.current && filterComponentReset.current();
          searchComponentReset.current && searchComponentReset.current();
        }}
      />
    </MPageContentContainer>
  );
};

export default QuoteListMain;
