import { zodResolver } from '@hookform/resolvers/zod';
import { FunctionComponent as FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  approvalsQueryKeys,
  doQuoteReject,
} from '../../../api/approvalService';
import { handleApiErrorToast } from '../../../api/axios';
import {
  IApprovalRespSchema,
  IQuoteRespSchema,
  IRejectSchema,
  RejectSchema,
} from '../../../types';
import {
  MBox,
  MButton,
  MFormField,
  MStack,
  MText,
  MTextarea,
} from '../../Monetize';

interface QuoteApprovalRejectFormProps {
  isOpen: boolean;
  quote: IQuoteRespSchema;
  approval: IApprovalRespSchema;
  onClose: () => void;
  // this prop is being made optional for the interim in order to support consumers
  // of this component's only parent, ApprovalSteps, that are not yet on React Query, it will eventually be removed
  reloadData?: () => void;
}

const formDefault: IRejectSchema = {
  declineReason: '',
};

export const QuoteApprovalRejectForm: FC<QuoteApprovalRejectFormProps> = (
  props: QuoteApprovalRejectFormProps,
) => {
  const { isOpen, onClose, reloadData, quote, approval } = props;
  const [saveLoading, setSaveLoading] = useState(false);
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm<IRejectSchema>({
    resolver: zodResolver(RejectSchema),
    mode: 'onChange',
    defaultValues: formDefault,
  });

  useEffect(() => {
    reset(formDefault);
  }, [reset, isOpen]);

  const { mutate: rejectQuote, isPending: isSaveLoading } = useMutation({
    mutationFn: ({
      approvalId,
      data,
    }: {
      approvalId: string;
      data: IRejectSchema;
    }) => doQuoteReject(approvalId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...approvalsQueryKeys.dashboard()],
      });
      onClose && onClose();
    },
    onError: (error) => {
      handleApiErrorToast(error);
    },
  });

  useEffect(() => {
    setSaveLoading(isSaveLoading);
  }, [isSaveLoading]);

  const onSubmit = async (data: IRejectSchema) => {
    if (reloadData) {
      // use of reloadData to be removed once all consumers of this component are on React Query
      setSaveLoading(true);
      try {
        await doQuoteReject(approval.id, data);
        onClose();
      } catch (err) {
        handleApiErrorToast(err);
      }
      setSaveLoading(false);
      reloadData && reloadData();
    } else {
      // parent component is using React Query
      rejectQuote({ approvalId: approval.id, data });
    }
  };

  return (
    <MBox mt="2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <MText color="tRed.base" fontWeight="bold" fontSize="xs">
          Denied
        </MText>
        <MFormField
          error={errors.declineReason}
          label="Reason"
          isRequired
          labelProps={{ fontSize: 'xs', mb: 1 }}
          mb="2"
        >
          <Controller
            name="declineReason"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <MTextarea rows={1} {...field} fontSize="xs" />
            )}
          />
        </MFormField>

        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="end"
          flex={1}
        >
          <MButton onClick={onClose} variant="cancel" minW="auto">
            Cancel
          </MButton>
          <MButton
            variant="primary"
            isLoading={saveLoading}
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isDirty || !isValid}
            type="submit"
            minW="auto"
          >
            Save
          </MButton>
        </MStack>
      </form>
    </MBox>
  );
};
