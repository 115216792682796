import { useQueryClient } from '@tanstack/react-query';
import { FormEvent, useState } from 'react';
import { handleApiErrorToast } from '../../../../api/axios';
import { doUpdateCustomFields } from '../../../../api/customFieldsService';
import { accountServiceQueryKeys } from '../../../../api/queryKeysService';
import { CustomFieldDataForm } from '../../../../components/CustomFields/CustomFieldDataForm';
import {
  MBox,
  MButton,
  MCenter,
  MCenterModal,
  MDivider,
  MFormFieldReadOnly,
  MGrid,
  MGridItem,
  MHStack,
  MStack,
  MStatusIDBox,
} from '../../../../components/Monetize';
import { MEntityStatusBadge } from '../../../../components/Monetize/MEntityStatusBadge';
import { MParentLink } from '../../../../components/Monetize/MParentLink';
import {
  CreditStatusEnumDisplay,
  CreditTypeEnumDisplay,
} from '../../../../constants/credits';
import { getAccountCreditsRoute } from '../../../../constants/routes';
import { useACL } from '../../../../services/acl/acl';
import {
  CreditNoteStatusEnum,
  CreditTypeEnum,
  CustomFieldEntityEnum,
  IAccountRespSchema,
  ICreditNoteSchema,
  ICustomFieldRecordSchema,
} from '../../../../types';
import { NeutralStyle, formatCurrency } from '../../../../utils';
import { nullifyEmptyStrings } from '../../../../utils/misc';

interface CreditNoteFormEditModalProps {
  account: IAccountRespSchema;
  creditNote: ICreditNoteSchema;
  onClose: () => void;
  onSave: (customFields: ICustomFieldRecordSchema) => void;
}

/**
 * This is only used for viewing a credit note and editing custom fields
 */
export const CreditNoteFormEditModal = ({
  account,
  creditNote,
  onClose,
  onSave,
}: CreditNoteFormEditModalProps) => {
  const { canDo } = useACL();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);

  const canEdit = canDo([['billing', 'update']]);

  const hasCustomFields = Object.keys(creditNote.customFields ?? {}).length > 0;

  const [customFields, setCustomFields] = useState<ICustomFieldRecordSchema>(
    creditNote.customFields ?? {},
  );

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (creditNote && customFields) {
      const payload = {
        entity: 'creditNotes',
        id: creditNote.id,
        data: nullifyEmptyStrings(customFields),
      } as const;
      try {
        await doUpdateCustomFields(payload);
        queryClient.removeQueries({
          queryKey: [
            ...accountServiceQueryKeys.creditNotes.creditNoteById(
              creditNote.id,
            ),
          ],
        });
        onSave(customFields);
      } catch (err) {
        handleApiErrorToast(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const overrideStatusStyle =
    creditNote?.status === CreditNoteStatusEnum.APPLIED ? NeutralStyle : {};

  const renderFooterComponent = () => {
    return (
      <MStack
        spacing={4}
        direction="row"
        align="center"
        justify="right"
        flex={1}
      >
        <MButton onClick={onClose} variant="cancel" minW="auto">
          Cancel
        </MButton>
        <MButton
          data-testid="credit-form-submit"
          form="credit-form"
          type="submit"
          variant="primary"
          isLoading={loading}
          minW="auto"
        >
          Save
        </MButton>
      </MStack>
    );
  };
  return (
    <MCenterModal
      isOpen
      onClose={onClose}
      modalTitle={
        canEdit && hasCustomFields ? 'Edit Credit Note' : 'View Credit Note'
      }
      subTitle={
        <MHStack>
          <MParentLink
            parentLink={getAccountCreditsRoute(account.id)}
            parentLinkTitle={account.accountName}
          />
          <MCenter m={0} w={0} height={3}>
            <MDivider
              m={0}
              orientation="vertical"
              borderColor="tGray.darkGrayPurple"
            />
          </MCenter>
          <MStatusIDBox id={creditNote.id} mb="0.5" />
        </MHStack>
      }
      size="lg"
      renderFooter={
        canEdit && hasCustomFields ? renderFooterComponent : undefined
      }
      renderModalTitleActions={() => (
        <MEntityStatusBadge
          status={CreditStatusEnumDisplay[creditNote.status]}
          {...overrideStatusStyle}
        />
      )}
      modalHeaderProps={{ display: 'flex', alignItems: 'center' }}
    >
      <MBox>
        <form id="credit-form" data-testid="credit-form" onSubmit={onSubmit}>
          <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
            <MGridItem colSpan={6}>
              <MFormFieldReadOnly label="Type">
                {CreditTypeEnumDisplay[CreditTypeEnum.CREDIT_NOTE]}
              </MFormFieldReadOnly>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormFieldReadOnly label="Amount">
                {formatCurrency(creditNote.amount, {
                  currency: creditNote.currency,
                })}
              </MFormFieldReadOnly>
            </MGridItem>
            <MGridItem colSpan={12}>
              <MFormFieldReadOnly label="Reason">
                {creditNote.description}
              </MFormFieldReadOnly>
            </MGridItem>

            {hasCustomFields && (
              <MGridItem colSpan={12}>
                <CustomFieldDataForm
                  entity={CustomFieldEntityEnum.CREDIT_NOTE}
                  value={customFields}
                  isReadOnly={!canEdit}
                  setValue={(val) => {
                    setCustomFields(val);
                  }}
                  fieldColSpan={6}
                  mode="modal"
                />
              </MGridItem>
            )}
          </MGrid>
        </form>
      </MBox>
    </MCenterModal>
  );
};
