import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useUpdatePoNumberOnQuote } from '../../../../../api/accountsService';
import { handleApiErrorToast } from '../../../../../api/axios';
import {
  MBox,
  MFormField,
  MLink,
  MText,
} from '../../../../../components/Monetize';
import MEditableInput from '../../../../../components/Monetize/MEditableInput';
import { useToast } from '../../../../../services/toast';
import {
  IInvoiceAddPoNumberSchema,
  InvoiceAddPoNumberSchema,
  IQuoteRespSchema,
} from '../../../../../types';
import { getQuoteStatus } from '../../../../../utils';

const AddPoNumberSection = ({
  quote,
  setQuote,
}: {
  quote: IQuoteRespSchema;
  setQuote: (quote: IQuoteRespSchema | null) => void;
}) => {
  const { mutateAsync: doUpdatePoNumber } = useUpdatePoNumberOnQuote();
  const { isReview, isApproved } = getQuoteStatus(quote);
  const canEditPONumber = isReview || isApproved;

  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const { addToast } = useToast();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IInvoiceAddPoNumberSchema>({
    resolver: zodResolver(InvoiceAddPoNumberSchema),
    mode: 'onChange',
    defaultValues: { poNumber: '' },
  });

  useEffect(() => {
    reset({ poNumber: quote.purchaseOrderNumber || '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote]);

  const updatePONumber = async (poNumber?: string) => {
    try {
      await doUpdatePoNumber({
        quoteId: quote.id,
        poNumber: poNumber,
      });
      setQuote({ ...quote, purchaseOrderNumber: poNumber });
    } catch (err) {
      addToast({
        summary: 'Error',
        detail:
          'Failed to update purchase order number. Please try again later.',
        severity: 'error',
      });
    }
  };

  const onSubmit = async (formData: IInvoiceAddPoNumberSchema) => {
    setLoading(true);
    try {
      await updatePONumber(formData.poNumber);
    } catch (err) {
      handleApiErrorToast(err);
    }
    setLoading(false);
    setEditMode(false);
  };
  if (!canEditPONumber) {
    return (
      <MBox minW="100px">
        <MText color="tGray.darkPurple">{quote.purchaseOrderNumber}</MText>
      </MBox>
    );
  }

  if (!editMode && !quote.purchaseOrderNumber) {
    return (
      <MBox mr={2}>
        <MLink
          color="tIndigo.base"
          fontWeight="bold"
          fontSize="sm"
          onClick={() => setEditMode(true)}
        >
          Add PO Number
        </MLink>
      </MBox>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MFormField
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        error={errors?.poNumber}
        formErrorMessageProps={{
          display: 'none',
        }}
      >
        <Controller
          control={control}
          name="poNumber"
          render={({ field: { onChange, value, ...rest } }) => (
            <MEditableInput
              minH={8}
              isDisabled={!canEditPONumber && loading}
              placeholder="PO Number"
              value={value || ''}
              showCancel
              showSave={!errors?.poNumber}
              startWithEditView={!quote.purchaseOrderNumber}
              onChange={onChange}
              onCancel={(currValue) => {
                setEditMode(false);
                onChange(currValue);
                updatePONumber();
              }}
              onSubmit={(currValue) => {
                onChange(currValue);
                handleSubmit(onSubmit)();
              }}
              previewProps={
                editMode
                  ? {
                      fontSize: 'sm',
                      borderColor: 'tGray.lightPurple',
                      minHeight: '2rem',
                      minWidth: '12.5rem',
                      color: 'tGray.lightPurple',
                    }
                  : {
                      _hover: {
                        borderColor: 'tGray.lightPurple',
                      },
                      fontSize: 'sm',
                      minHeight: '2rem',
                      minWidth: '12.5rem',
                      color: 'tGray.darkPurple',
                    }
              }
              inputProps={{
                value: value || '',
                w: 'auto',
              }}
              {...rest}
            />
          )}
        />
      </MFormField>
    </form>
  );
};

export default AddPoNumberSection;
