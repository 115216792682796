import { AccordionItemProps, AccordionProps } from '@chakra-ui/react';
import { FunctionComponent as FC, useEffect, useState } from 'react';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { handleApiErrorToast } from '../../../../api/axios';
import {
  doRevertQuoteConditionalTerm,
  doUpdateQuoteConditionalTerm,
} from '../../../../api/cpqService';
import {
  MAccordion,
  MAccordionCustomButton,
  MAccordionCustomButtonItem,
  MAccordionItem,
  MAccordionPanel,
  MBox,
  MButton,
  MIcon,
  MText,
} from '../../../../components/Monetize';
import MEditor from '../../../../components/Monetize/MEditor';
import { useFlags } from '../../../../services/launchDarkly';
import {
  IQuoteConditionalTermSchema,
  QuoteConditionalTermStatusEnum,
  QuoteStatusEnum,
} from '../../../../types';
import { useQuoteContext } from '../quoteContext';

export interface QuoteFormConditionalTermsProps extends AccordionProps {
  itemProps?: AccordionItemProps;
}

export const QuoteFormConditionalTerms: FC<QuoteFormConditionalTermsProps> = ({
  itemProps,
  ...rest
}: QuoteFormConditionalTermsProps) => {
  const {
    quoteData: { quote, fetchQuote },
    isReadOnly,
  } = useQuoteContext();

  const [editingIds, setEditingIds] = useState<string[]>([]);

  const [conditionalTerms, setConditionalTerms] = useState<
    IQuoteConditionalTermSchema[]
  >([]);
  const [loadingState, setLoadingState] = useState<{
    [key: string]: { reverting?: boolean; editing?: boolean };
  }>({});
  const [openIndexes, setOpenIndexes] = useState<number[]>(
    quote?.conditionalTerms?.map((_, index) => index) || [],
  );

  useEffect(() => {
    setConditionalTerms(
      quote?.conditionalTerms?.filter(
        ({ status }) => status === QuoteConditionalTermStatusEnum.ACTIVE,
      ) || [],
    );
  }, [quote?.conditionalTerms]);
  const { conditionalQuoteTerms } = useFlags();

  if (conditionalTerms?.length === 0 || !quote || !conditionalQuoteTerms) {
    return null;
  }

  const handleOnEditTerm = async (
    conditionalTerm: IQuoteConditionalTermSchema,
    index: number,
  ) => {
    if (!editingIds.includes(conditionalTerm.id)) {
      setEditingIds((ids) => [...ids, conditionalTerm.id]);
      try {
        setLoadingState({
          ...loadingState,
          [conditionalTerm.id]: {
            editing: true,
          },
        });
        await handleOnSaveTerm(conditionalTerm);
      } catch (err) {
        handleApiErrorToast(err);
      }

      if (!openIndexes.includes(index)) {
        setOpenIndexes([...openIndexes, index]);
      }
    }

    setLoadingState({
      ...loadingState,
      [conditionalTerm.id]: {
        editing: false,
      },
    });
  };

  const handleOnChangeTerm = (
    value: string,
    conditionalTerm: IQuoteConditionalTermSchema,
  ) => {
    setConditionalTerms((terms) =>
      terms.map((term) =>
        term.id === conditionalTerm.id ? { ...term, terms: value } : term,
      ),
    );
  };
  const handleOnSaveTerm = async (
    conditionalTerm: IQuoteConditionalTermSchema,
  ) => {
    try {
      const newCondTerm = await doUpdateQuoteConditionalTerm(
        quote?.id,
        conditionalTerm.id,
        {
          terms: conditionalTerm.terms,
        },
      );
      setConditionalTerms((terms) =>
        terms.map((term) =>
          term.id === conditionalTerm.id ? newCondTerm : term,
        ),
      );
      setEditingIds((ids) => ids.filter((id) => id !== conditionalTerm.id));
      await fetchQuote(quote?.id);
    } catch (err) {
      handleApiErrorToast(err);
    }
  };
  const handleOnRevertTerm = async (
    conditionalTerm: IQuoteConditionalTermSchema,
    index: number,
  ) => {
    if (!openIndexes.includes(index)) {
      setOpenIndexes([...openIndexes, index]);
    }

    try {
      setLoadingState({
        ...loadingState,
        [conditionalTerm.id]: {
          reverting: true,
        },
      });
      const newCondTerm = await doRevertQuoteConditionalTerm(
        quote?.id,
        conditionalTerm.id,
      );
      setConditionalTerms((terms) =>
        terms.map((term) =>
          term.id === conditionalTerm.id ? newCondTerm : term,
        ),
      );
      setEditingIds((ids) => ids.filter((id) => id !== conditionalTerm.id));
      await fetchQuote(quote?.id);
    } catch (err) {
      handleApiErrorToast(err);
    }
    setLoadingState({
      ...loadingState,
      [conditionalTerm.id]: {
        reverting: false,
      },
    });
  };

  return (
    <MAccordion allowMultiple {...rest}>
      <MAccordionItem {...itemProps}>
        {({ isExpanded }) => (
          <>
            <MAccordionCustomButton
              isExpanded={isExpanded}
              label="Conditional Terms"
            >
              <MAccordionCustomButtonItem
                hideWhenExpanded
                isExpanded={isExpanded}
                label="Terms"
                value={conditionalTerms?.length}
              />
            </MAccordionCustomButton>
            <MAccordionPanel p="0" mr="0" ml="3">
              <MAccordion
                allowMultiple
                index={openIndexes}
                onChange={(expanded) => setOpenIndexes(expanded as number[])}
              >
                {conditionalTerms?.map((conditionalTerm, index) => (
                  <MAccordionItem
                    key={index}
                    borderRadius="none"
                    borderColor="tBlue.hover"
                    borderTop="none"
                    borderLeft="none"
                    borderRight="none"
                    borderBottomWidth={
                      index !== conditionalTerms.length - 1 ? '1px' : '0'
                    }
                  >
                    {({ isExpanded }) => (
                      <>
                        <MAccordionCustomButton
                          isExpanded={isExpanded}
                          label={conditionalTerm.ruleName}
                          labelProps={{ fontSize: 'sm' }}
                          buttonProps={{ as: 'div' }}
                        >
                          {conditionalTerm.editableOnQuote &&
                            !conditionalTerm.edited &&
                            !editingIds.includes(conditionalTerm.id) && (
                              <MButton
                                isDisabled={isReadOnly}
                                variant="tertiary"
                                size="sm"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  handleOnEditTerm(conditionalTerm, index);
                                }}
                                isLoading={
                                  loadingState[conditionalTerm.id]?.editing
                                }
                              >
                                Edit
                              </MButton>
                            )}
                          {conditionalTerm.edited && (
                            <MButton
                              variant="tertiary"
                              size="sm"
                              onClick={(ev) => {
                                ev.stopPropagation();
                                handleOnRevertTerm(conditionalTerm, index);
                              }}
                              isLoading={
                                loadingState[conditionalTerm.id]?.reverting
                              }
                            >
                              <MIcon
                                as={BsArrowCounterclockwise}
                                mr="1"
                                boxSize={4}
                              />
                              Revert to Original
                            </MButton>
                          )}
                        </MAccordionCustomButton>

                        <MAccordionPanel>
                          {editingIds.includes(conditionalTerm.id) ||
                          conditionalTerm.edited ? (
                            <>
                              <MText
                                color="tGray.acGray"
                                fontStyle="italic"
                                mb="1"
                              >
                                Recommended to not edit Conditional Terms until
                                Quote is substantially complete
                              </MText>
                              <MEditor
                                autoScroll
                                value={conditionalTerm.terms}
                                handleEditorChange={(value) =>
                                  handleOnChangeTerm(value, conditionalTerm)
                                }
                                onBlur={() => handleOnSaveTerm(conditionalTerm)}
                                boxProps={{
                                  pb: 0,
                                  minH: 150,
                                }}
                                disabled={
                                  isReadOnly ||
                                  quote.status !== QuoteStatusEnum.DRAFT
                                }
                              />
                            </>
                          ) : (
                            <MBox
                              className="rich-text"
                              textColor="tPurple.base"
                              fontSize="sm"
                              dangerouslySetInnerHTML={{
                                __html: conditionalTerm.terms,
                              }}
                            />
                          )}
                        </MAccordionPanel>
                      </>
                    )}
                  </MAccordionItem>
                ))}
              </MAccordion>
            </MAccordionPanel>
          </>
        )}
      </MAccordionItem>
    </MAccordion>
  );
};
