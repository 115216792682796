import {
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  Tooltip,
  useOutsideClick,
} from '@chakra-ui/react';
import React, {
  FunctionComponent as FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MdOutlineSearch, MdSearch } from 'react-icons/md';
import { useDebounce } from '../../../hooks/useDebounce';
import { pluralize } from '../../../utils';
import MCloseButton from '../MCloseButton';
import MCustomIconButton from '../MCustomIconButton';

interface MPageSearchInputProps {
  value: string;
  onChange: (val: string) => void;
  count?: number;
  searchTooltip?: string;
  placeholderKey?: string;
  resetSearch?: any;
}

const MPageSearchInput: FC<MPageSearchInputProps> = React.forwardRef<
  any,
  MPageSearchInputProps
>(
  (
    {
      value,
      onChange,
      count = 0,
      searchTooltip = 'Search',
      placeholderKey = '',
      resetSearch,
    }: MPageSearchInputProps,
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [internalValue, setInternalValue] = useState(value);
    const debouncedValue = useDebounce(internalValue);
    const [isOpen, setIsOpen] = useState(false);
    const currentRef = React.useRef(null);

    useEffect(() => {
      setInternalValue(value);
    }, [value]);

    useEffect(() => {
      onChange(debouncedValue);
    }, [debouncedValue]);

    useEffect(() => {
      if (resetSearch) {
        const resetRef = resetSearch;
        resetRef.current = onSetEmpty;
      }
    }, []);

    useEffect(() => {
      if (isOpen && inputRef.current) {
        inputRef.current.focus();
      }
    }, [isOpen]);

    useEffect(() => {
      if (internalValue) {
        setIsOpen(true);
      }
    }, [internalValue]);

    const handleOnKeyDown = (ev: React.KeyboardEvent) => {
      if (ev.key === 'Enter') {
        onChange(internalValue);
      }
    };

    useOutsideClick({
      ref: currentRef,
      handler: () => setIsOpen(false),
    });

    const onSetEmpty = () => {
      onChange('');
      setIsOpen(false);
    };
    const mainInputP = 4;
    let inputRightElementW = 70;
    if (internalValue) {
      inputRightElementW = 88;
    }

    return (
      <Tooltip isDisabled={isOpen} label={searchTooltip} placement="bottom-end">
        <Flex
          ref={currentRef}
          bg={isOpen ? 'tGray.support' : 'none'}
          borderRadius="3"
          maxWidth="400"
          alignItems="center"
          h="8"
          color="tPurple.base"
          overflow="hidden"
        >
          {!isOpen && (
            <MCustomIconButton
              variant="icon"
              data-testid="search-icon-button"
              containerSize="8"
              btnSize={6}
              fontSize="lg"
              cursor="pointer"
              _focus={{ background: 'tGray.support' }}
              icon={MdOutlineSearch}
              onClick={() => setIsOpen(true)}
              iconColor="tPurple.base"
            />
          )}
          {isOpen && (
            <Flex
              alignItems="center"
              width="277px"
              transition="width 1s ease-out"
            >
              <InputGroup>
                <InputLeftElement pointerEvents="none" w="8" textAlign="center">
                  <Icon w="5" h="5" as={MdSearch} color="tPurple.base" />
                </InputLeftElement>
                <Input
                  data-testid="search-input"
                  type="text"
                  ref={inputRef}
                  placeholder={`Search by ${placeholderKey}`}
                  value={internalValue}
                  onChange={(e) => setInternalValue(e.target.value)}
                  onBlur={() => onChange(internalValue)}
                  onKeyDown={handleOnKeyDown}
                  tabIndex={-1}
                  _placeholder={{
                    color: 'tGray.darkGrayPurple',
                  }}
                  pr={inputRightElementW + mainInputP}
                />

                <InputRightElement w={inputRightElementW}>
                  <Text fontSize="xs" mr="2" whiteSpace="nowrap">
                    {count
                      ? `${count} ${pluralize('result', count)}`
                      : 'No Results'}
                  </Text>

                  {internalValue && (
                    <MCloseButton onClick={() => onSetEmpty()} mr={1} />
                  )}
                </InputRightElement>
              </InputGroup>
            </Flex>
          )}
        </Flex>
      </Tooltip>
    );
  },
);

export default MPageSearchInput;
