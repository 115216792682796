import { useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { toDateShort } from '@monetize/utils/core';
import { format as formatDate } from 'date-fns/format';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MdOutlineError } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useGetPaymentMethodsByAccountWithGroupedItems } from '../../../../../api/accountsService';
import { handleApiErrorToast } from '../../../../../api/axios';
import { doGetContactById } from '../../../../../api/contactsService';
import {
  useGetById,
  useGetListData,
  useUpdateEntity,
} from '../../../../../api/queryUtils';
import {
  ILegalEntitiesSelector,
  selectLegalEntities,
} from '../../../../../api/selectors';
import { useGetDunningProcesses } from '../../../../../api/settingsService';
import { AccountAddressSelect } from '../../../../../components/Account/AccountAddressSelect';
import { ContactSelect } from '../../../../../components/Contacts/ContactSelect';
import { CustomFieldDataForm } from '../../../../../components/CustomFields/CustomFieldDataForm';
import {
  MBox,
  MCheckbox,
  MCurrencySelect,
  MCustomSelect,
  MDivider,
  MFormField,
  MIcon,
  MInput,
  MLockedTextOrContent,
  MRadio,
  MRadioGroup,
  MSimpleGrid,
  MStack,
  MText,
  MTextarea,
  MTooltip,
} from '../../../../../components/Monetize';
import { useCustomSelectValue } from '../../../../../components/Monetize/MCustomSelect/useCustomSelectValue';
import { AddPaymentMethodForm } from '../../../../../components/PaymentMethods/Form/AddPaymentMethodForm';
import { PaymentMethodItemRenderer } from '../../../../../components/PaymentMethods/PaymentMethodItemRenderer';
import { BILL_GROUPS, PAYMENT_METHODS } from '../../../../../constants';
import { BILL_GROUP_STATUS_DISPLAY } from '../../../../../constants/billGroups';
import { ACH_OR_BANK_TRANSFER } from '../../../../../constants/paymentMethods';
import { STATUS_PLACEHOLDER } from '../../../../../constants/placeholders';
import {
  SETTING_INVOICE_CREATION_DISPLAY,
  SETTING_INVOICE_CREATION_OPTIONS,
  SETTING_INVOICE_DELAY_DISPLAY,
  SETTING_INVOICE_DELAY_DISPLAY_FUTURE,
  SETTING_INVOICE_DELAY_OPTIONS_IN_FUTURE,
  SETTING_INVOICE_DELAY_OPTIONS_PAST_AND_FUTURE,
} from '../../../../../constants/settings';
import { useNetTerms } from '../../../../../hooks';
import useCurrentPaymentGateways from '../../../../../hooks/useCurrentPaymentGateways';
import { useACL } from '../../../../../services/acl/acl';
import { useFlags } from '../../../../../services/launchDarkly';
import Sentry from '../../../../../services/sentry';
import { appGlobalDataState } from '../../../../../store/global.store';
import {
  ApiListResponse,
  BILL_GROUP_INVOICE_FREQUENCY_MAP,
  BillGroupFormSchema,
  CustomFieldEntityEnum,
  DEFAULT_PAGER,
  FilterType,
  FilterTypeOperator,
  GetListApiFilter,
  IBillGroupReq,
  IBillGroupReqUI,
  IBillGroupResp,
  IContactRespSchema,
  IDunnings,
  IPaymentMethodResp,
  NET_TERMS_DISPLAY,
  PaymentMethodStatusEnum,
  PaymentMethodTypeEnum,
  QuoteSettingsDefaultAddressSourceEnum,
} from '../../../../../types';
import {
  FirstInvoiceCreationStrategyEnum,
  InvoiceCreationEnum,
} from '../../../../../types/InvoiceSettingsTypes';
import { ILegalEntityResponseSchema } from '../../../../../types/legalEntityTypes';
import { buildFilterParamsRequestObject } from '../../../../../utils';
import {
  getAddress,
  getAddressFromContact,
} from '../../../../../utils/address';
import { arrayToObject, nullifyEmptyStrings } from '../../../../../utils/misc';
import { SectionHeader } from '../../components/SectionHeader';
import {
  convertBillGroupReqUIToBillGroupReq,
  convertBillGroupRespToReqUI,
} from '../../utils';
import { BillGroupDetailHeader } from './BillGroupDetailHeader';

interface BillGroupDetailsTabProps {
  billGroup: IBillGroupResp;
  setInvoiceDelay: (val: number) => void;
  isReadOnly?: boolean;
}

export const BillGroupDetailsTab = ({
  billGroup,
  setInvoiceDelay,
  isReadOnly,
}: BillGroupDetailsTabProps) => {
  const { canDo } = useACL();
  const { activeNetTerms, defaultNetTerm } = useNetTerms(billGroup.netTerms);

  const {
    allowInvoiceDelayInPast,
    enableAccountBasedAddresses,
    showSettingFirstInvoiceCheckbox,
  } = useFlags();

  const convertedBillGroup = convertBillGroupRespToReqUI(
    billGroup,
    defaultNetTerm,
  );

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IBillGroupReqUI>({
    resolver: zodResolver(BillGroupFormSchema),
    mode: 'onChange',
    defaultValues: convertedBillGroup,
    values: convertedBillGroup,
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const { data: legalEntities } = useGetListData<
    ILegalEntityResponseSchema,
    ILegalEntitiesSelector
  >(
    'legalEntities',
    {
      config: DEFAULT_PAGER,
    },
    {
      select: selectLegalEntities,
    },
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const handleResetSearchParams = () => {
    if (searchParams.has('editMode')) {
      searchParams.delete('editMode');
      setSearchParams(searchParams);
    }
  };
  const hasEditModeSearchParams = searchParams.has('editMode');
  const [isEditMode, setIsEditMode] = useState<boolean>(
    hasEditModeSearchParams,
  );

  useEffect(() => {
    if (hasEditModeSearchParams) {
      setIsEditMode(true);
    }
  }, [hasEditModeSearchParams]);

  const canOnlyUpdatePONumber =
    !canDo([['billing', 'update']]) && canDo([['sales', 'update']]);

  const isLocked = canOnlyUpdatePONumber || !!billGroup.locked;

  const isReadOnlyProps = {
    isDisabled: isReadOnly,
    disabled: isReadOnly,
    isReadOnly: isReadOnly,
    variant: isReadOnly ? 'readonly' : 'primary',
  };

  const isReadOnlyOrLockedProps = {
    isDisabled: isLocked || isReadOnly,
    disabled: isLocked || isReadOnly,
    isReadOnly: isLocked || isReadOnly,
    variant: isLocked || isReadOnly ? 'readonly' : 'primary',
  };

  const {
    name,
    status,
    legalEntityId,
    contactId,
    addressData,
    shippingContactId,
    invoicingFrequency,
    invoicingFrequencyInMonths,
    invoiceDelay,
    invoiceCreation,
    billDay,
    defaultPaymentGatewayId,
    paymentMethodId,
    purchaseOrderNumber,
    registrationNumber,
    vatNumber,
    dunningProcessId,
    strCcEmails,
    currency,
    invoiceTemplateId,
    netTerms,
    customId,
    customFields,
  } = watch();

  const isAnyCustomFieldsExists = Object.keys(customFields).length > 0;

  const selectedLegalEntity = legalEntities?.legalEntitiesById?.[legalEntityId];
  const invoicingFrequencyDisplay =
    invoicingFrequency &&
    invoicingFrequencyInMonths &&
    BILL_GROUP_INVOICE_FREQUENCY_MAP[invoicingFrequency](
      invoicingFrequencyInMonths,
    );

  const { hasCrmConfigured } = useRecoilValue(appGlobalDataState);

  const isAddressSourceIsContact =
    addressData.addressSource === QuoteSettingsDefaultAddressSourceEnum.CONTACT;

  const contactIdCustomValue = useCustomSelectValue<IContactRespSchema>({
    value: contactId,
    setValue: (val) => setValue('contactId', val, { shouldDirty: true }),
    getOneById: doGetContactById,
  });

  const billingAddressTextForAddressSourceContact =
    contactIdCustomValue.internalValue
      ? getAddressFromContact(contactIdCustomValue.internalValue, {
          addressFormat: selectedLegalEntity?.addressFormat,
        }).fullAddress
      : 'Address populates from Billing Contact';

  const shippingContactIdCustomValue = useCustomSelectValue<IContactRespSchema>(
    {
      value: shippingContactId,
      setValue: (val) =>
        setValue('shippingContactId', val, { shouldDirty: true }),
      getOneById: doGetContactById,
    },
  );
  const shippingAddressTextForAddressSourceContact =
    shippingContactIdCustomValue.internalValue
      ? getAddressFromContact(shippingContactIdCustomValue.internalValue, {
          addressFormat: selectedLegalEntity?.addressFormat,
        }).fullAddress
      : 'Address populates from Shipping Contact';

  const { activeGateways, currentPaymentGateway, gatewaysById } =
    useCurrentPaymentGateways({
      refetchOnWindowFocus: false, // If not, the modal flicks whenever window has focus
    });

  const {
    isOpen: isPaymentMethodModalOpen,
    onOpen: onOpenPaymentMethodModal,
    onClose: closePaymentMethodModal,
  } = useDisclosure();

  const onClosePaymentMethodModal = (newPaymentMethod?: IPaymentMethodResp) => {
    closePaymentMethodModal();
    if (newPaymentMethod) {
      setValue('paymentMethodId', newPaymentMethod?.id, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  const filters: FilterType[] = [
    {
      key: 'status',
      operator: FilterTypeOperator.EQUAL,
      value: PaymentMethodStatusEnum.ACTIVE,
    },
    {
      key: 'paymentMethodType',
      operator: FilterTypeOperator.NOT_EQUAL,
      value: PaymentMethodTypeEnum.ACH_CREDIT,
    },
  ];

  const paymentMethodsFilterParams: GetListApiFilter =
    buildFilterParamsRequestObject(filters);

  const { data: paymentMethods } =
    useGetPaymentMethodsByAccountWithGroupedItems({
      accountId: billGroup.accountId!,
      filters: paymentMethodsFilterParams,
      filterFn: ({ paymentMethodType }) =>
        !ACH_OR_BANK_TRANSFER.has(paymentMethodType),
    });

  const { data: dunningData } = useGetDunningProcesses<
    ApiListResponse<IDunnings> & { contentById: Record<string, IDunnings> }
  >({
    config: DEFAULT_PAGER,
    options: {
      select: (data) => {
        return {
          ...data,
          contentById: arrayToObject(data.content, 'id'),
        };
      },
    },
  });

  const { data: dunningProcess } = useGetById<IDunnings>(
    'dunnings',
    dunningProcessId!,
    { enabled: !!dunningProcessId, refetchOnWindowFocus: false },
  );

  const dunningStep = (dunningProcess?.dunningSteps || []).find(
    ({ id }) => id && id === billGroup.dunningStepId,
  );

  const {
    mutateAsync: doUpdateBillGroup,
    isPending: isUpdateBillGroupLoading,
  } = useUpdateEntity<IBillGroupResp, IBillGroupReq>('billGroups', {
    endpointArgs: { accountId: billGroup.accountId },
    onSuccess: () => {
      if (isEditMode) {
        setIsEditMode(false);
      }
    },
    onError: (error) => {
      handleApiErrorToast(error);
    },
  });

  const onSubmit = async (data: IBillGroupReqUI) => {
    const parsed = BillGroupFormSchema.safeParse(data);
    if (!parsed.success) {
      Sentry.captureException(parsed.error.issues, {
        tags: {
          type: 'BILL_GROUP_FORM',
        },
      });
    }

    const payload = parsed.success
      ? convertBillGroupReqUIToBillGroupReq(parsed.data as IBillGroupReqUI)
      : convertBillGroupReqUIToBillGroupReq(data);
    const finalPayloadData = nullifyEmptyStrings(payload);
    doUpdateBillGroup({ id: billGroup.id, payload: finalPayloadData });
    handleResetSearchParams();
  };

  return (
    <MBox>
      <BillGroupDetailHeader billGroup={billGroup} dunningStep={dunningStep} />
      <SectionHeader
        label="Details"
        isEditMode={isEditMode}
        isLoading={isUpdateBillGroupLoading}
        canEdit={canDo([['billing', 'create']])}
        onEdit={() => {
          setIsEditMode((prevState) => !prevState);
          if (isEditMode) {
            reset();
            handleResetSearchParams();
          }
        }}
        onSave={() => {
          handleSubmit(onSubmit)();
        }}
      />

      <MSimpleGrid columns={4} gap={4} mb="6">
        <MFormField error={errors.name} label="Bill Group Name" isRequired>
          <MLockedTextOrContent isLocked={!isEditMode} text={name}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <MInput
                  placeholder="Bill Group #1"
                  maxLength={60}
                  {...field}
                  {...isReadOnlyOrLockedProps}
                  isDisabled={
                    isReadOnlyOrLockedProps.disabled || canOnlyUpdatePONumber
                  }
                />
              )}
            />
          </MLockedTextOrContent>
        </MFormField>
        <MFormField error={errors.status} label="Status">
          <MLockedTextOrContent
            isLocked={!isEditMode}
            text={BILL_GROUP_STATUS_DISPLAY[status]}
          >
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <MCustomSelect
                  placeholder={STATUS_PLACEHOLDER}
                  items={BILL_GROUPS.BILL_GROUP_STATUS_OPTIONS}
                  {...field}
                  {...isReadOnlyOrLockedProps}
                  isDisabled={
                    isReadOnlyOrLockedProps.disabled || canOnlyUpdatePONumber
                  }
                  inputProps={{
                    variant: isReadOnlyOrLockedProps.variant,
                    isReadOnly: isReadOnlyOrLockedProps.isReadOnly,
                    isDisabled: isReadOnlyOrLockedProps.isDisabled,
                  }}
                />
              )}
            />
          </MLockedTextOrContent>
        </MFormField>
        <MFormField
          error={errors.legalEntityId}
          label="Legal Entity"
          isRequired
        >
          <MLockedTextOrContent
            isLocked={!isEditMode}
            text={legalEntities?.legalEntitiesById?.[legalEntityId]?.name}
          >
            <Controller
              name="legalEntityId"
              control={control}
              render={({ field }) => (
                <MCustomSelect
                  itemTitle="name"
                  itemValue="id"
                  items={legalEntities?.legalEntities || []}
                  {...field}
                  {...isReadOnlyOrLockedProps}
                  isDisabled={
                    isReadOnlyOrLockedProps.disabled || canOnlyUpdatePONumber
                  }
                  inputProps={{
                    variant: isReadOnlyOrLockedProps.variant,
                    isReadOnly: isReadOnlyOrLockedProps.isReadOnly,
                    isDisabled: isReadOnlyOrLockedProps.isDisabled,
                  }}
                />
              )}
            />
          </MLockedTextOrContent>
        </MFormField>
        <MFormField error={errors.currency} label="Currency" isRequired>
          <MLockedTextOrContent isLocked={!isEditMode} text={currency}>
            <Controller
              name="currency"
              control={control}
              render={({ field }) => (
                <MCurrencySelect
                  {...field}
                  {...isReadOnlyOrLockedProps}
                  isDisabled={
                    isReadOnlyOrLockedProps.disabled || canOnlyUpdatePONumber
                  }
                  inputProps={{
                    variant: isReadOnlyOrLockedProps.variant,
                    isReadOnly: isReadOnlyOrLockedProps.isReadOnly,
                    isDisabled: isReadOnlyOrLockedProps.isDisabled,
                  }}
                />
              )}
            />
          </MLockedTextOrContent>
        </MFormField>
        <MFormField error={errors.vatNumber} label="VAT Number">
          <MLockedTextOrContent isLocked={!isEditMode} text={vatNumber}>
            <Controller
              name="vatNumber"
              defaultValue=""
              control={control}
              render={({ field: { value, ...rest } }) => (
                <MInput
                  placeholder="VAT Number"
                  value={value || ''}
                  {...rest}
                  {...isReadOnlyProps}
                  isDisabled={isReadOnlyProps.disabled || canOnlyUpdatePONumber}
                />
              )}
            />
          </MLockedTextOrContent>
        </MFormField>
        <MFormField
          error={errors.registrationNumber}
          label="Registration Number"
        >
          <MLockedTextOrContent
            isLocked={!isEditMode}
            text={registrationNumber}
          >
            <Controller
              name="registrationNumber"
              defaultValue=""
              control={control}
              render={({ field: { value, ...rest } }) => (
                <MInput
                  placeholder="Registration Number"
                  value={value || ''}
                  {...rest}
                  {...isReadOnlyProps}
                  isDisabled={isReadOnlyProps.disabled || canOnlyUpdatePONumber}
                />
              )}
            />
          </MLockedTextOrContent>
        </MFormField>

        <MFormField error={errors.purchaseOrderNumber} label="PO Number">
          <MLockedTextOrContent
            isLocked={!isEditMode}
            text={purchaseOrderNumber}
          >
            <Controller
              name="purchaseOrderNumber"
              defaultValue=""
              control={control}
              render={({ field: { value, ...rest } }) => (
                <MInput
                  placeholder="PO Number"
                  value={value || ''}
                  {...rest}
                  {...isReadOnlyProps}
                  isDisabled={isReadOnlyProps.disabled || canOnlyUpdatePONumber}
                />
              )}
            />
          </MLockedTextOrContent>
        </MFormField>
        <MFormField error={errors.customId} label="Custom ID">
          <MLockedTextOrContent isLocked={!isEditMode} text={customId}>
            <Controller
              name="customId"
              control={control}
              render={({ field: { value, ...rest } }) => (
                <MInput
                  value={value || ''}
                  placeholder="Custom ID"
                  maxLength={60}
                  {...rest}
                  {...isReadOnlyOrLockedProps}
                  isDisabled={
                    isReadOnlyOrLockedProps.disabled || canOnlyUpdatePONumber
                  }
                />
              )}
            />
          </MLockedTextOrContent>
        </MFormField>
      </MSimpleGrid>

      <MDivider />

      <SectionHeader label="Contacts" />
      <MText mb="4">
        Changes to Contacts and Addresses impact future created Invoices.
      </MText>
      <MBox mb={6}>
        {enableAccountBasedAddresses && (
          <MFormField
            error={errors.addressData?.addressSource}
            label="Addresses Sourced From"
            tooltip="Set source of Shipping and Billing Addresses for any future Invoices"
            isHorizontal
            labelProps={{ mb: 0 }}
            mb={4}
          >
            <Controller
              control={control}
              name="addressData.addressSource"
              render={({ field: { value, onChange, ...rest } }) => (
                <MRadioGroup
                  onChange={onChange}
                  value={value}
                  {...rest}
                  isDisabled={isReadOnlyProps.disabled || canOnlyUpdatePONumber}
                >
                  <MStack spacing={4} direction="row" color="tGray.darkPurple">
                    <MRadio
                      value={QuoteSettingsDefaultAddressSourceEnum.ACCOUNT}
                      isDisabled={
                        isReadOnlyProps.disabled ||
                        !isEditMode ||
                        !hasCrmConfigured ||
                        canOnlyUpdatePONumber
                      }
                      onChange={() => {
                        setValue('addressData.billingAddressId', null);
                        setValue('addressData.shippingAddressId', null);
                      }}
                    >
                      <MTooltip
                        label="CRM must be connected to use Account Addresses"
                        placement="bottom-start"
                        shouldWrapChildren
                        isDisabled={!isEditMode || hasCrmConfigured}
                      >
                        <MText
                          display="inline-flex"
                          alignItems="center"
                          color="currentColor"
                        >
                          Account Addresses
                        </MText>
                      </MTooltip>
                    </MRadio>
                    <MRadio
                      value={QuoteSettingsDefaultAddressSourceEnum.CONTACT}
                      onChange={() => {
                        setValue('addressData.billingAddressId', null);
                        setValue('addressData.shippingAddressId', null);
                      }}
                      isDisabled={
                        !isEditMode ||
                        isReadOnlyProps.disabled ||
                        canOnlyUpdatePONumber
                      }
                    >
                      Contacts
                    </MRadio>
                  </MStack>
                </MRadioGroup>
              )}
            />
          </MFormField>
        )}
        <MSimpleGrid columns={4} gap={4}>
          <MFormField
            error={errors.contactId}
            label="Billing Contact"
            isRequired
          >
            <MLockedTextOrContent
              isLocked={!isEditMode}
              text={contactIdCustomValue?.internalValue?.fullName}
            >
              <Controller
                name="contactId"
                control={control}
                render={({ field: { value, onChange, ...rest } }) => (
                  <ContactSelect
                    showAddContacts={canDo([['account_contacts', 'create']])}
                    accountId={billGroup.accountId}
                    loading={contactIdCustomValue.isLoading}
                    value={contactIdCustomValue.internalValue}
                    returnItem
                    onChange={(val) => {
                      contactIdCustomValue.onInternalValueChange(val as any);
                    }}
                    clearable={false}
                    {...rest}
                    isReadOnly={isReadOnlyProps.isReadOnly}
                    isDisabled={
                      isReadOnlyProps.disabled || canOnlyUpdatePONumber
                    }
                    inputProps={{
                      variant: isReadOnlyProps.variant,
                      isReadOnly: isReadOnlyProps.isReadOnly,
                      isDisabled: isReadOnlyProps.isDisabled,
                    }}
                  />
                )}
              />
            </MLockedTextOrContent>
          </MFormField>

          <MFormField
            error={errors.addressData?.billingAddressId}
            label="Billing Address"
            isRequired
          >
            <MLockedTextOrContent
              isLocked={!isEditMode || isAddressSourceIsContact}
              text={
                isAddressSourceIsContact
                  ? billingAddressTextForAddressSourceContact
                  : getAddress(billGroup.billingAddress).fullAddress
              }
            >
              <Controller
                name="addressData.billingAddressId"
                control={control}
                render={({ field }) => {
                  return (
                    <AccountAddressSelect
                      addressFormat={selectedLegalEntity?.addressFormat}
                      accountId={billGroup.accountId}
                      {...field}
                      {...isReadOnlyProps}
                      isDisabled={
                        isReadOnlyProps.disabled || canOnlyUpdatePONumber
                      }
                      inputProps={{
                        variant: isReadOnlyProps.variant,
                        isReadOnly: isReadOnlyProps.isReadOnly,
                        isDisabled: isReadOnlyProps.isDisabled,
                      }}
                    />
                  );
                }}
              />
            </MLockedTextOrContent>
          </MFormField>
          <MFormField
            error={errors.shippingContactId}
            label="Shipping Contact"
            isRequired
          >
            <MLockedTextOrContent
              isLocked={!isEditMode}
              text={shippingContactIdCustomValue.internalValue?.fullName}
            >
              <Controller
                name="shippingContactId"
                control={control}
                render={({ field: { value, onChange, ...rest } }) => (
                  <ContactSelect
                    showAddContacts={canDo([['account_contacts', 'create']])}
                    accountId={billGroup.accountId}
                    loading={shippingContactIdCustomValue.isLoading}
                    value={shippingContactIdCustomValue.internalValue}
                    returnItem
                    onChange={(val) => {
                      shippingContactIdCustomValue.onInternalValueChange(
                        val as any,
                      );
                    }}
                    clearable={false}
                    {...rest}
                    {...isReadOnlyProps}
                    isDisabled={
                      isReadOnlyProps.disabled || canOnlyUpdatePONumber
                    }
                    inputProps={{
                      variant: isReadOnlyProps.variant,
                      isReadOnly: isReadOnlyProps.isReadOnly,
                      isDisabled: isReadOnlyProps.isDisabled,
                    }}
                  />
                )}
              />
            </MLockedTextOrContent>
          </MFormField>
          <MFormField
            error={errors.addressData?.shippingAddressId}
            label="Shipping Address"
          >
            <MLockedTextOrContent
              isLocked={!isEditMode || isAddressSourceIsContact}
              text={
                isAddressSourceIsContact
                  ? shippingAddressTextForAddressSourceContact
                  : getAddress(billGroup.shippingAddress).fullAddress
              }
            >
              <Controller
                name="addressData.shippingAddressId"
                control={control}
                render={({ field }) => {
                  return (
                    <AccountAddressSelect
                      addressFormat={selectedLegalEntity?.addressFormat}
                      accountId={billGroup.accountId}
                      {...field}
                      {...isReadOnlyProps}
                      isDisabled={
                        isReadOnlyProps.disabled || canOnlyUpdatePONumber
                      }
                      inputProps={{
                        variant: isReadOnlyProps.variant,
                        isReadOnly: isReadOnlyProps.isReadOnly,
                        isDisabled: isReadOnlyProps.isDisabled,
                      }}
                    />
                  );
                }}
              />
            </MLockedTextOrContent>
          </MFormField>
        </MSimpleGrid>

        <MFormField
          error={errors.strCcEmails}
          label="CC Emails"
          tooltip="Invoices will be sent to these emails. Use commas to add multiple emails."
          data-testid="email-text-area"
          mt={4}
        >
          <MLockedTextOrContent isLocked={!isEditMode} text={strCcEmails}>
            <Controller
              name="strCcEmails"
              control={control}
              render={({ field: { value, ...rest } }) => (
                <MTextarea
                  value={value || ''}
                  placeholder="Use commas to add multiple emails"
                  spellCheck={false}
                  rows={1}
                  {...rest}
                  {...isReadOnlyProps}
                  disabled={isReadOnlyProps.disabled || canOnlyUpdatePONumber}
                />
              )}
            />
          </MLockedTextOrContent>
        </MFormField>
      </MBox>
      <MDivider />

      <SectionHeader label="Invoice Settings" />
      <MSimpleGrid columns={4} gap={4} mb={6}>
        <MFormField label="Next Invoice Date">
          <MLockedTextOrContent
            isLocked
            text={toDateShort(billGroup.nextInvoiceDate)}
          />
        </MFormField>
        <MFormField error={errors.invoiceTemplateId} label="Invoice Template">
          <MLockedTextOrContent
            isLocked={!isEditMode}
            text={invoiceTemplateId || 'Default'}
          >
            <Controller
              name="invoiceTemplateId"
              control={control}
              render={({ field: { value, ...rest } }) => (
                <MCustomSelect
                  placeholder="Select"
                  items={['Default', 'Template 1', 'Template 2']}
                  clearable
                  value={'Default'}
                  {...rest}
                  {...isReadOnlyOrLockedProps}
                  isDisabled
                  inputProps={{
                    variant: isReadOnlyOrLockedProps.variant,
                    isReadOnly: isReadOnlyOrLockedProps.isReadOnly,
                    isDisabled: isReadOnlyOrLockedProps.isDisabled,
                  }}
                />
              )}
            />
          </MLockedTextOrContent>
        </MFormField>
        <MFormField label="Invoice Frequency">
          <MLockedTextOrContent
            isLocked
            text={
              invoicingFrequencyDisplay ? invoicingFrequencyDisplay.label : ''
            }
          />
        </MFormField>
        <MBox />
        <MBox>
          <MFormField error={errors.invoiceCreation} label="Create Invoices">
            <MLockedTextOrContent
              isLocked={!isEditMode}
              text={SETTING_INVOICE_CREATION_DISPLAY[invoiceCreation]}
            >
              <Controller
                name="invoiceCreation"
                control={control}
                render={({ field }) => (
                  <MCustomSelect
                    items={SETTING_INVOICE_CREATION_OPTIONS}
                    {...field}
                    {...isReadOnlyProps}
                    isDisabled={
                      isReadOnlyProps.disabled || canOnlyUpdatePONumber
                    }
                    inputProps={{
                      variant: isReadOnlyProps.variant,
                      isReadOnly: isReadOnlyProps.isReadOnly,
                      isDisabled: isReadOnlyProps.isDisabled,
                    }}
                  />
                )}
              />
            </MLockedTextOrContent>
          </MFormField>
          {showSettingFirstInvoiceCheckbox && (
            <MFormField
              error={errors?.firstInvoiceCreationStrategy}
              onClick={(ev) => ev.stopPropagation()}
              mt="2"
            >
              <Controller
                name="firstInvoiceCreationStrategy"
                control={control}
                render={({ field: { onChange, value, ...rest } }) => (
                  <MCheckbox
                    isChecked={
                      value ===
                      FirstInvoiceCreationStrategyEnum.GENERATE_ON_QUOTE_PROCESSED
                    }
                    {...rest}
                    {...isReadOnlyProps}
                    onChange={(ev) => {
                      if (ev.target.checked) {
                        onChange(
                          FirstInvoiceCreationStrategyEnum.GENERATE_ON_QUOTE_PROCESSED,
                        );
                      } else {
                        onChange(
                          FirstInvoiceCreationStrategyEnum.FOLLOW_EXISTING_SETTINGS,
                        );
                      }
                    }}
                    isDisabled={
                      !isEditMode ||
                      isReadOnlyProps.disabled ||
                      canOnlyUpdatePONumber
                    }
                  >
                    Automatically create the first invoice of Contracts upon
                    quote processing.
                  </MCheckbox>
                )}
              />
            </MFormField>
          )}
        </MBox>
        {invoiceCreation !== InvoiceCreationEnum.MANUAL && (
          <MFormField
            error={errors.invoiceDelay}
            label="Offset from Billing Schedule"
          >
            <MLockedTextOrContent
              isLocked={!isEditMode}
              text={
                allowInvoiceDelayInPast
                  ? SETTING_INVOICE_DELAY_DISPLAY[invoiceDelay]
                  : SETTING_INVOICE_DELAY_DISPLAY_FUTURE[invoiceDelay]
              }
            >
              <Controller
                name="invoiceDelay"
                control={control}
                render={({ field: { value, onChange, ...rest } }) => (
                  <MCustomSelect
                    items={
                      allowInvoiceDelayInPast
                        ? SETTING_INVOICE_DELAY_OPTIONS_PAST_AND_FUTURE
                        : SETTING_INVOICE_DELAY_OPTIONS_IN_FUTURE
                    }
                    value={String(value)}
                    onChange={(ev) => {
                      const convertedVal = Number(ev);
                      onChange(convertedVal);
                      setInvoiceDelay(convertedVal);
                    }}
                    {...rest}
                    {...isReadOnlyProps}
                    isDisabled={
                      isReadOnlyProps.disabled || canOnlyUpdatePONumber
                    }
                    inputProps={{
                      variant: isReadOnlyProps.variant,
                      isReadOnly: isReadOnlyProps.isReadOnly,
                      isDisabled: isReadOnlyProps.isDisabled,
                    }}
                  />
                )}
              />
            </MLockedTextOrContent>
          </MFormField>
        )}

        <MFormField
          error={errors.autoEmailInvoice}
          label="Email Customer Invoices"
        >
          <Controller
            name="autoEmailInvoice"
            control={control}
            render={({ field: { value, onChange, ...rest } }) => {
              return (
                <MRadioGroup
                  value={typeof value === 'undefined' ? 'true' : String(value)}
                  onChange={(val: string) => {
                    onChange(val === 'true');
                  }}
                  minH={8}
                  display="flex"
                  alignItems="center"
                  {...rest}
                  {...isReadOnlyProps}
                  isDisabled={
                    !isEditMode ||
                    isReadOnlyProps.disabled ||
                    canOnlyUpdatePONumber
                  }
                  variant={
                    !isEditMode || isReadOnlyProps.isReadOnly
                      ? 'readonly'
                      : 'primary'
                  }
                >
                  <MStack direction="row" columnGap={5}>
                    <MRadio value="true">
                      Automatically
                      {!!value && (
                        <MTooltip
                          shouldWrapChildren
                          label={
                            'MonetizeNow will email your customers automatically when invoices are created.'
                          }
                          placement="bottom-end"
                        >
                          <MIcon
                            as={MdOutlineError}
                            w={3}
                            h={3}
                            ml={1}
                            color={'tOrange.tangerine'}
                          />
                        </MTooltip>
                      )}
                    </MRadio>
                    <MRadio value="false">Manually</MRadio>
                    );
                  </MStack>
                </MRadioGroup>
              );
            }}
          />
        </MFormField>
        {/* <MFormField label="Invoice Memo">
        <MLockedTextOrContent isLocked={!isEditMode} text={name}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <MInput
                placeholder=""
                maxLength={60}
                isDisabled={canOnlyUpdatePONumber}
                {...field}
              />
            )}
          />
        </MLockedTextOrContent>
      </MFormField> */}
      </MSimpleGrid>

      <MDivider />

      <SectionHeader label="Payment Settings" />
      <MBox mb={6}>
        <MFormField error={errors.collectInvoiceBalanceAutomatically} mb={4}>
          <Controller
            name="collectInvoiceBalanceAutomatically"
            defaultValue={false}
            control={control}
            render={({ field: { value, ...rest } }) => (
              <MCheckbox
                pt={2}
                pb={1}
                isChecked={value}
                {...rest}
                {...isReadOnlyProps}
                isDisabled={
                  !isEditMode ||
                  isReadOnlyProps.disabled ||
                  canOnlyUpdatePONumber
                }
              >
                Collect Invoice Automatically
              </MCheckbox>
            )}
          />
        </MFormField>
        <MSimpleGrid columns={4} gap={4}>
          <MFormField error={errors.billDay} label="Bill Cycle Day" isRequired>
            <MLockedTextOrContent
              isLocked={!isEditMode || billGroup.locked}
              text={billDay}
            >
              <Controller
                name="billDay"
                defaultValue={Number(formatDate(new Date(), 'd'))}
                control={control}
                render={({ field }) => (
                  <MCustomSelect
                    placeholder="Select"
                    items={[...Array(31).keys()].map((k) => ({
                      title: (k + 1).toString(),
                      value: k + 1,
                    }))}
                    {...field}
                    {...isReadOnlyProps}
                    isDisabled={
                      isReadOnlyProps.disabled || canOnlyUpdatePONumber
                    }
                    inputProps={{
                      variant: isReadOnlyProps.variant,
                      isReadOnly: isReadOnlyProps.isReadOnly,
                      isDisabled: isReadOnlyProps.isDisabled,
                    }}
                  />
                )}
              />
            </MLockedTextOrContent>
          </MFormField>
          <MFormField
            error={errors.defaultPaymentGatewayId}
            label="Payment Gateway"
          >
            <MLockedTextOrContent
              isLocked={!isEditMode}
              text={
                (defaultPaymentGatewayId &&
                  gatewaysById[defaultPaymentGatewayId]?.description) ||
                ''
              }
            >
              <Controller
                name="defaultPaymentGatewayId"
                control={control}
                render={({ field }) => (
                  <MCustomSelect
                    placeholder="Select"
                    items={activeGateways.map(({ id, description }: any) => ({
                      title: description,
                      value: id,
                    }))}
                    {...field}
                    {...isReadOnlyOrLockedProps}
                    isDisabled={
                      isReadOnlyOrLockedProps.disabled || canOnlyUpdatePONumber
                    }
                    inputProps={{
                      variant: isReadOnlyOrLockedProps.variant,
                      isReadOnly: isReadOnlyOrLockedProps.isReadOnly,
                      isDisabled: isReadOnlyOrLockedProps.isDisabled,
                    }}
                  />
                )}
              />
            </MLockedTextOrContent>
          </MFormField>

          <MFormField error={errors.paymentMethodId} label="Payment Method">
            <MLockedTextOrContent
              isLocked={!isEditMode}
              text={
                (paymentMethodId &&
                  paymentMethods?.paymentMethodsById[paymentMethodId]
                    ?.paymentMethodName) ||
                ''
              }
            >
              <Controller
                name="paymentMethodId"
                control={control}
                render={({ field }) => (
                  <MCustomSelect
                    placeholder="Select"
                    itemValue="id"
                    itemTitle="paymentMethodName"
                    items={
                      defaultPaymentGatewayId
                        ? paymentMethods?.paymentMethodsByGatewayId?.[
                            defaultPaymentGatewayId
                          ] || []
                        : []
                    }
                    prependItems={
                      (defaultPaymentGatewayId &&
                        currentPaymentGateway && [
                          {
                            item: PAYMENT_METHODS.NEW_PAYMENT_METHOD,
                            isAction: true,
                            hasDivider: true,
                            onClick: ({ onClose }) => {
                              onClose && onClose();
                              onOpenPaymentMethodModal();
                            },
                          },
                        ]) ||
                      []
                    }
                    renderItemContent={PaymentMethodItemRenderer}
                    clearable
                    {...field}
                    {...isReadOnlyProps}
                    isDisabled={
                      isReadOnlyProps.disabled || canOnlyUpdatePONumber
                    }
                    inputProps={{
                      variant: isReadOnlyProps.variant,
                      isReadOnly: isReadOnlyProps.isReadOnly,
                      isDisabled: isReadOnlyProps.isDisabled,
                    }}
                  />
                )}
              />
            </MLockedTextOrContent>
          </MFormField>
          <MFormField error={errors.netTerms} label="Net Terms" isRequired>
            <MLockedTextOrContent
              isLocked={!isEditMode}
              text={NET_TERMS_DISPLAY[netTerms]}
            >
              <Controller
                name="netTerms"
                control={control}
                render={({ field }) => (
                  <MCustomSelect
                    placeholder="Select Net Terms"
                    items={activeNetTerms}
                    itemTitle="name"
                    itemValue="value"
                    {...field}
                    {...isReadOnlyOrLockedProps}
                    isDisabled={
                      isReadOnlyOrLockedProps.disabled || canOnlyUpdatePONumber
                    }
                  />
                )}
              />
            </MLockedTextOrContent>
          </MFormField>
        </MSimpleGrid>
      </MBox>

      <MDivider />
      <SectionHeader label="Dunning" />
      <MSimpleGrid columns={4} gap={4} mb={6}>
        <MFormField error={errors.dunningProcessId} label="Dunning Process">
          <MLockedTextOrContent
            isLocked={!isEditMode}
            text={
              dunningProcessId &&
              dunningData?.contentById[dunningProcessId]?.name
            }
          >
            <Controller
              name="dunningProcessId"
              control={control}
              render={({ field }) => (
                <MCustomSelect
                  placeholder="Select"
                  items={(dunningData?.content || []).map((x: IDunnings) => ({
                    title: x.name,
                    value: x.id,
                  }))}
                  clearable
                  onClear={() => {
                    setValue('dunningProcessId', null, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                  {...field}
                  {...isReadOnlyProps}
                  isDisabled={isReadOnlyProps.disabled || canOnlyUpdatePONumber}
                  inputProps={{
                    variant: isReadOnlyProps.variant,
                    isReadOnly: isReadOnlyProps.isReadOnly,
                    isDisabled: isReadOnlyProps.isDisabled,
                  }}
                />
              )}
            />
          </MLockedTextOrContent>
        </MFormField>
        <MFormField label="Status">
          <MLockedTextOrContent
            isLocked
            text={billGroup.dunningStepId ? 'In Dunning' : 'Not In Dunning'}
          />
        </MFormField>
        <MFormField label="Current Dunning Step">
          <MLockedTextOrContent isLocked text={dunningStep?.stepName || ''} />
        </MFormField>
      </MSimpleGrid>

      {isAnyCustomFieldsExists && (
        <>
          <MDivider />
          <SectionHeader label="Custom Fields" />

          <Controller
            name="customFields"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CustomFieldDataForm
                entity={CustomFieldEntityEnum.BILL_GROUP}
                value={value}
                setValue={(val) => {
                  onChange(val);
                }}
                fieldColSpan={6}
                mode="page"
                {...isReadOnlyProps}
                isReadOnly={!isEditMode || isReadOnlyProps.isReadOnly}
                variant={
                  !isEditMode || isReadOnlyProps.isReadOnly
                    ? 'readonly'
                    : 'primary'
                }
                isDisabled={isReadOnlyProps.disabled || canOnlyUpdatePONumber}
              />
            )}
          />
        </>
      )}

      <AddPaymentMethodForm
        isOpen={isPaymentMethodModalOpen}
        accountId={billGroup.accountId}
        onClose={(newPaymentMethod) =>
          onClosePaymentMethodModal(newPaymentMethod)
        }
      />
    </MBox>
  );
};
