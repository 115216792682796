import { useGetById } from '../../../../api/queryUtils';
import { MVStack } from '../../../../components/Monetize';
import { IBillGroupResp } from '../../../../types';
import { useQuoteContext } from '../quoteContext';
import { ContractIsCancelledBanner } from './ContractIsCancelledBanner';
import { ContractWillBeCancelledBanner } from './ContractWillBeCancelledBanner';
import { EarlyRenewalBanner } from './EarlyRenewalBanner';
import { InactiveRateInfoBanner } from './InactiveRateInfoBanner';
import { InactiveRateWarningBanner } from './InactiveRateWarningBanner';
import { RenewalNotAvailableBanner } from './RenewalNotAvailableBanner';
import { UsageProductBanner } from './UsageProductsBanner';

export const QuoteBanners = () => {
  const { contract, quoteData, quoteStateData } = useQuoteContext();
  const { data: billGroup } = useGetById<IBillGroupResp>(
    'billGroups',
    quoteData?.quote?.billGroupId ?? '',
    {
      enabled: !!quoteData?.quote?.billGroupId,
    },
  );

  const { quote } = quoteData;

  if (!quote) {
    return null;
  }

  return (
    <MVStack spacing="2" w="full" my="2">
      {contract && (
        <ContractWillBeCancelledBanner contract={contract} quote={quote} />
      )}
      {contract && (
        <ContractIsCancelledBanner quote={quote} contract={contract} />
      )}
      <InactiveRateInfoBanner quote={quote} quoteStateData={quoteStateData} />
      <InactiveRateWarningBanner
        quote={quote}
        quoteStateData={quoteStateData}
      />
      <RenewalNotAvailableBanner quote={quote} />
      {billGroup && <UsageProductBanner quote={quote} billGroup={billGroup} />}
      <EarlyRenewalBanner quote={quote} />
    </MVStack>
  );
};
