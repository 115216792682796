import { zodResolver } from '@hookform/resolvers/zod';
import { startOfDay } from 'date-fns/startOfDay';
import { Controller, useForm } from 'react-hook-form';
import { useCreateEntity } from '../../../../api/queryUtils';
import { CustomFieldDataForm } from '../../../../components/CustomFields/CustomFieldDataForm';
import {
  MBox,
  MButton,
  MCenterModal,
  MCustomNumberInput,
  MCustomSelect,
  MFormField,
  MGrid,
  MGridItem,
  MInput,
  MStack,
  MTextarea,
} from '../../../../components/Monetize';
import { DatePicker } from '../../../../components/Monetize/DatePicker/DatePicker';
import { BillGroupDropdown } from '../../../../components/Monetize/Dropdowns/BillGroupDropdown';
import {
  CREDIT_TYPE_OPTIONS,
  INIT_NEW_CREDIT,
} from '../../../../constants/credits';
import {
  BillGroupStatusEnum,
  CustomFieldEntityEnum,
  IAccountRespSchema,
  IGetCreditSchema,
  IUpsertCreditSchema,
  UpsertCreditSchema,
} from '../../../../types';

interface CreditFormCreateModalProps {
  isOpen: boolean;
  account: IAccountRespSchema;
  billGroupId?: string;
  onClose: () => void;
  isCustomFiledEditable?: boolean;
}

export const CreditFormCreateModal = ({
  isOpen,
  onClose,
  account,
  billGroupId,
  isCustomFiledEditable,
}: CreditFormCreateModalProps) => {
  const { id: accountId } = account;
  const { defaultCurrency } = account;

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors, isDirty, isSubmitting },
    watch,
  } = useForm<IUpsertCreditSchema>({
    resolver: zodResolver(UpsertCreditSchema),
    defaultValues: {
      ...INIT_NEW_CREDIT,
      currency: defaultCurrency,
      billGroupId,
    },
    mode: 'onChange',
  });

  const isValid = Object.keys(errors).length === 0;
  const watchBillGroupId = watch('billGroupId');

  const { isPending: isSaving, mutate: doCreateCredit } = useCreateEntity<
    IGetCreditSchema,
    IUpsertCreditSchema
  >('accountCredits', {
    onSuccess: () => {
      onClose();
    },
    endpointArgs: {
      accountId: accountId,
      billGroupId: watchBillGroupId,
    },
    meta: { showErrorToast: true },
  });

  const onSubmit = async (payload: IUpsertCreditSchema) => {
    if (accountId) {
      doCreateCredit(payload);
    }
  };

  // const isReadOnlyProps = {
  //   isDisabled: isSaving,
  //   disabled: isSaving,
  //   variant: isReadOnly ? 'readonly' : 'primary',
  // };

  // const isAmountReadOnlyProps = {
  //   isDisabled: isSaving,
  //   variant: isReadOnly ? 'readonly' : 'primary',
  // };

  return (
    <MCenterModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={'New Credit'}
      size="lg"
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton onClick={onClose} variant="cancel" minW="auto">
            Cancel
          </MButton>
          <MButton
            data-testid="credit-form-submit"
            variant="primary"
            isLoading={isSaving}
            onClick={handleSubmit(onSubmit)}
            isDisabled={
              (!isDirty && !isCustomFiledEditable) ||
              !isValid ||
              isSubmitting ||
              isSaving
            }
            minW="auto"
          >
            Save
          </MButton>
        </MStack>
      )}
      modalHeaderProps={{ display: 'flex', alignItems: 'center' }}
    >
      <MBox>
        <form
          id="credit-form"
          data-testid="credit-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
            <MGridItem colSpan={12}>
              <MFormField error={errors?.type} label="Type" isReadOnly>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      items={CREDIT_TYPE_OPTIONS}
                      {...field}
                      isDisabled
                      isReadOnly
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12}>
              <MFormField
                error={errors?.billGroupId}
                label="Bill Group"
                isRequired
              >
                <Controller
                  name="billGroupId"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <BillGroupDropdown
                      accountId={accountId}
                      statusFilter={[BillGroupStatusEnum.ACTIVE]}
                      field={field}
                      customSelectProps={{
                        isReadOnly: !!billGroupId,
                        isDisabled: isSaving || !!billGroupId,
                      }}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12}>
              <MFormField error={errors?.name} label="Name" isRequired>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <MInput {...field} isDisabled={isSaving} isRequired />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12}>
              <MFormField error={errors?.amount} label="Amount" isRequired>
                <Controller
                  name="amount"
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <MCustomNumberInput
                      inputMode="decimal"
                      precision={2}
                      onChange={(valueAsString) => onChange(valueAsString)}
                      {...rest}
                      isDisabled={isSaving}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12}>
              <MFormField error={errors?.reason} label="Reason" isRequired>
                <Controller
                  name="reason"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <MTextarea
                      placeholder="Reason for credit..."
                      {...field}
                      isDisabled={isSaving}
                      isRequired
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12}>
              <MFormField error={errors?.expirationDate} label="Expiration">
                <Controller
                  name="expirationDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      minDate={startOfDay(new Date())}
                      {...field}
                      isDisabled={isSaving}
                      clearable
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12}>
              <CustomFieldDataForm
                entity={CustomFieldEntityEnum.CREDIT}
                value={getValues('customFields') ?? {}}
                setValue={(val) => {
                  setValue('customFields', val);
                }}
                fieldColSpan={6}
                mode="modal"
              />
            </MGridItem>
          </MGrid>
        </form>
      </MBox>
    </MCenterModal>
  );
};
