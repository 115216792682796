import { formatInteger } from '@monetize/utils/core';
import { useFormContext } from 'react-hook-form';
import { MdSettings } from 'react-icons/md';
import { Link } from 'react-router-dom';
import {
  MBox,
  MButton,
  MCustomIconButton,
  MFlex,
  MLink,
  MPageLoader,
  MStatusIDBox,
  MText,
} from '../../../../components/Monetize';
import { QuoteBodyAccordion } from '../../../../components/Quotes/QuoteBodyAccordion';
import { QuoteCollaborationPopoverV2 } from '../../../../components/Quotes/QuoteCollaboration/QuoteCollaborationPopoverV2';
import { ROUTES } from '../../../../constants';
import {
  CollaborationAccessEnum,
  IQuoteRequestSchema,
} from '../../../../types';
import { useQuoteContext } from '../../Quote/quoteContext';
import { QuoteBasicContacts } from './QuoteBasicContacts';
import { QuoteBasicContract } from './QuoteBasicContract';
import { QuoteBasicDetails } from './QuoteBasicDetails';

interface QuoteBasicProps {
  onOpenContactDrawer: () => void;
}
export const QuoteBasic = ({ onOpenContactDrawer }: QuoteBasicProps) => {
  const { quoteData, handleSubmitButtonWithoutDirtyCheck } = useQuoteContext();

  const { quote } = quoteData;

  const { setValue } = useFormContext<IQuoteRequestSchema>();

  if (!quote) {
    return <MPageLoader />;
  }

  return (
    <QuoteBodyAccordion title="Quote Information">
      <MFlex flexDir={['column', 'column', 'column', 'row']}>
        <MBox flex="1">
          <MFlex
            borderBottomWidth="1px"
            borderColor="tGray.support"
            alignItems="center"
            justifyContent="space-between"
          >
            <MFlex my="2" alignItems="center">
              <MText mr={2} fontWeight="600" fontSize="md">
                Contract
              </MText>
              <MBox>
                <MLink
                  fontSize="sm"
                  isTruncated
                  maxWidth="200px"
                  to={ROUTES.getQuoteContractEditRoute(
                    quote.id,
                    quote.contractId!,
                  )}
                  as={Link}
                  replace
                >
                  {quote.contractId}
                </MLink>
              </MBox>
            </MFlex>
          </MFlex>
          <QuoteBasicContract />
        </MBox>
        <MBox mx="6" display={['hidden', 'hidden', 'visible']}>
          <MBox w="1px" background="tGray.support" height="100%"></MBox>
        </MBox>
        <MBox flex="1">
          <MFlex
            borderBottomWidth="1px"
            borderColor="tGray.support"
            alignItems="center"
            justifyContent="space-between"
          >
            <MText my="2" fontWeight="600" fontSize="md">
              Contacts
            </MText>

            <MCustomIconButton
              btnSize={4}
              minW={6}
              minH={6}
              variant="icon"
              icon={MdSettings}
              iconColor="tIndigo.base"
              onClick={() => {
                onOpenContactDrawer();
              }}
            />
          </MFlex>
          <QuoteBasicContacts />
        </MBox>
        <MBox mx="6" display={['hidden', 'hidden', 'visible']}>
          <MBox w="1px" background="tGray.support" height="100%"></MBox>
        </MBox>
        <MBox flex="1">
          <MFlex
            borderBottomWidth="1px"
            borderColor="tGray.support"
            alignItems="center"
            justifyContent="space-between"
          >
            <MFlex my="2" alignItems="center">
              <MText mr={2} fontWeight="600" fontSize="md">
                Details
              </MText>
              <MStatusIDBox
                id={quote?.id!}
                displayIcon={false}
                idCopyProps={{ fontSize: 'sm' }}
              />
            </MFlex>

            <MBox mr="-1">
              <QuoteCollaborationPopoverV2
                quoteId={quote.id}
                quoteExpirationDate={quote.expirationDate}
                quoteStatus={quote.status}
                collaborationAccess={quote.collaborationAccess}
                accessLevel={quote.accessLevel}
                onCollaborationAccessChange={(
                  collaborationAccess: CollaborationAccessEnum,
                ) => {
                  setValue('collaborationAccess', collaborationAccess, {
                    shouldDirty: true,
                  });
                  handleSubmitButtonWithoutDirtyCheck();
                }}
                renderTrigger={({ handleOpen, icon, engagementCount }) => (
                  <MButton
                    variant="tertiary"
                    minW="auto"
                    aria-label="Share Quote"
                    onClick={handleOpen}
                    mr={-2}
                  >
                    Share
                    {!!engagementCount && (
                      <MFlex
                        ml={1}
                        justifyContent="center"
                        alignItems="center"
                        height={4}
                        px={1}
                        borderRadius={10}
                        bgColor="tIndigo.base"
                      >
                        <MText
                          fontWeight="bold"
                          color="tWhite.base"
                          mb={-0.5}
                          fontSize="xs"
                        >
                          {formatInteger(engagementCount)}
                        </MText>
                      </MFlex>
                    )}
                  </MButton>
                )}
              />
            </MBox>
          </MFlex>
          <QuoteBasicDetails />
        </MBox>
      </MFlex>
    </QuoteBodyAccordion>
  );
};
